import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../Component/styledComponents/styledComponents';
import Etherium from '../../../Component/Images/etherium.png';
import { useNavigate } from 'react-router-dom';

const Card = styled.div`
background-color: #282828;
border-radius:10px;
padding:40px 90px;
`;
const Heading = styled.div`
font-size:24px;
color:#fff;
font-weight:bold;
margin-bottom:20px;
`;
const Text = styled.div`
color:#9b9b9b ;
font-size:18px;
`;
const IconSection = styled.div`
margin-top:40px;
display:flex;
align-items: center;
width:100%;
justify-content: space-between;
`;
const IconWrapper = styled.div`
text-align:center;
`;
const Icon = styled.img`

`;
const Subtitle = styled.div`
color:#9b9b9b ;
margin-top:5px;
`;
function EthereumDevelopment(props) {
  const navigate = useNavigate();
    return (
        <div>
         <Card>
        <Heading>
        Ethereum development 
        </Heading>
        <Text>
        We adopt agile methodologies to build top-notch Ethereum-powered applications and smart contracts with immutable attributes to ensure high resilience and business continuity. 
        </Text>
        <IconSection>
            <Icon src={Etherium} alt="Ethereum development" />
        </IconSection>
        <Button
        border="#9b9b9b"
        color="#fff"
        top="12px"
        right="30px"
        left="30px"
        bottom="12px"
        radius="30px"
        mtop="40px"
        onClick={() => navigate('/ethereum')}
        >
          EXPLORE
        </Button>
      </Card>
        </div>
    );
}

export default EthereumDevelopment;