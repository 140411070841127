import React from 'react';
import styled from 'styled-components';
import {Container} from '../../Component/styledComponents/styledComponents';
import BgImage from '../../Component/Images/bg.jpg';
import Image1 from '../../Component/Images/Image1.png';
import Image2 from '../../Component/Images/Image2.png';
import Image3 from '../../Component/Images/Image3.png';
import WrappercurveImage from '../../Component/Images/4-curve.png';
import LineImage from '../../Component/Images/line.png';

const Root = styled.div`
background-image:url(${BgImage});
padding:40px 0px 100px 0;
display: flex;
align-items: center;
justify-content: center;
position: relative;
`;

const Wrapper = styled.div`
width:70%;
margin: 0 auto;
position: relative;
z-index:2;
`;
const Div = styled.div`
color:#8da393;
text-align: center;
margin-bottom:20px;
font-size: 50px;
display: flex;
align-items:baseline;
position: relative;
justify-content: center;
@media(max-width:767px){
  font-size: 40px;
}
@media(max-width:608px){
  font-size:30px;
}
@media(max-width:460px){
  font-size:24px;
}
@media(max-width:399px){
font-size: 20px;
}
`;

const LargeTextWrapper = styled.div`
color:#8da393;
text-align: center;
margin-bottom:20px;
display: flex;
align-items:baseline;
justify-content: center;
font-size: 50px;
font-size: 85px;
@media(max-width:851px){
  font-size: 65px;
}
@media(max-width:680px){
  font-size:55px;
}
@media(max-width:553px){
  font-size:45px;
}
@media(max-width:467px){
  font-size:35px;
}
@media(max-width:367px){
  font-size:30px;
}
`;
const Image = styled.img`
width:220px;
${(p) => p.left && `margin-left:${p.left}`};
${(p) => p.right && `margin-right:${p.right}`};
@media(max-width:800px){
  width:140px;
}
@media(max-width:608px){
  width:100px;
}
@media(max-width:399px){
  width:80px;
}
`;
const Span = styled.span`
font-weight:300;
${(p) => p.bold && `font-weight:${p.bold}` };

`;
const Line = styled.div`
  position:absolute;
  width:50%;
  right:0;
  top:0;
  height: 10px;
  background-color:#018c67;

`;

const BgImageWrapper = styled.img`
position: absolute;
top:110px;
z-index:1;
width:180px;
right:22%;
@media(max-width:767px){
  display: none;
}
`;
const ImageLine = styled.div`
 width:80px;
 height: 1px;
 background-color:#8da393;
 @media(max-width:460px){
  width:50px;
}
`;
const LineImageBottom = styled.img`
position: absolute;
bottom: 0px;
max-width: 90%;
`;

const LineHeight = styled.div`
width:2px;
height:140px;
background-color:#018c67;
position: relative;
left:-13px;
bottom:40px;
min-width:2px;
@media(max-width:800px){
  bottom:20px;
  height:100px;
}
@media(max-width:484px){
  bottom:10px;
}
`;
const BlockchainImageSection = () => {
    return (
        <Root>
            <Line/>
            <Container>
               <Wrapper>
                    <Div>
                      <Span bold="bold">Blockchain</Span><Image src={Image1} left="10px" right="10px"/><LineHeight/><Span>Development</Span>
                    </Div>
                    <LargeTextWrapper><Span>for</Span> <Image src={Image3} right="10px" left="10px"/> <Span bold="bold">extraordinary</Span></LargeTextWrapper>
                    <LargeTextWrapper><ImageLine/> <Image src={Image2} right="10px"/>  <Span bold="bold">People</Span></LargeTextWrapper>
               </Wrapper>
               <BgImageWrapper src={WrappercurveImage} alt="Blockchain Development" />
               <LineImageBottom src={LineImage}/>
            </Container>
        </Root>
    );
};

export default BlockchainImageSection;