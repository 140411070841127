import React from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

const LinkWrapper = styled.div`
width:100%;
background-image: linear-gradient(to right, #00a97c,#36c9f9);
margin-top:20px;
padding: 20px;
display:flex;
align-items: center;
border-radius:5px;
box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
justify-content:space-between;
border:1px solid #00a97c;
`;
const TextWrapper = styled.div`
display:flex;
align-items: center;
`;

const IconWrapper = styled.div`
font-size:20px;
`;

const Image = styled.img`
width:45px;
margin-right:10px;
@media(max-width:767px){
  width:32px;
}
`;
const LinkCard = ({image,text,path}) => {
    const navigation = useNavigate();
    return (
        <LinkWrapper onClick={()=> navigation(path)}>
        <TextWrapper>
        <Image src={image} /> {text}
        </TextWrapper>
        <IconWrapper>
            <i className="fa-solid fa-angle-right"></i>
        </IconWrapper>
        </LinkWrapper>
    );
};

export default LinkCard;