import React from "react";
import styled from "styled-components";
import { Button } from "../../Component/styledComponents/styledComponents";
import { useNavigate } from "react-router-dom";
const Root = styled.div`
  background-color: #fff;
  /* display:flex; */
  min-height: 10rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  /* ${(p) =>
    p.right &&
    `
 justify-content:center;
`}; */
  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`;

const H5 = styled.h5`
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 30px;
  }
`;
function Coustumerstories() {
  const navigate = useNavigate();
  return (
    <Root>
      <Div right="right">
        <H5>Let’s make the impossible possible together.</H5>
      </Div>
      <Div>
        <Button
          color="#fff"
          bgcolor="#000"
          top="12px"
          bottom="12px"
          left="30px"
          right="30px"
          radius="30px"
          fontSize="18px"
          onClick={() => {navigate('/contact_us')}}
        >
          Start A Project
        </Button>
      </Div>
    </Root>
  );
}
export default Coustumerstories;
