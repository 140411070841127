import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Container } from '../../Component/styledComponents/styledComponents';
import axios from "axios";
import SucessPopup from '../../Component/SucessPopup';
import FormImage from '../../Component/Images/form-1-img.png';

const Root = styled.div`
background-color:#282828;
padding-bottom:80px;
padding-top:80px;
@media(max-width:767px){
  padding:30px 0;
}
`;
const Input = styled.input`
border-radius:31px;
height:3rem;
width:100%;
border:1px solid #808080;
background:none;
color:#fff;
padding-left:1.5rem;
outline:none;
${(p) => p.left && `
  padding-left:${p.left};
`};
${(p) => p.right && `
 margin-right:20px;
`};
 @media(max-width:767px){
  margin-bottom: 20px;
  ${(p) => p.right && `
 margin-right:0px;
`};
}   
`;
const ImageWrapper = styled.div`
      margin-left: 36rem;
      margin-top: -9rem;
`;



const Form = styled.form`
width:100%;
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
`;

const FormWrapper = styled.div`
width:100%;
display:flex;
justify-content: space-between;
position: relative;
align-items: center;
@media(min-width: 767px){
  margin-bottom:20px;
}
@media(max-width:767px){
  flex-direction:column;
}
`;
const Div = styled.div`
width:60%;
@media(max-width:767px){
  width:100%;
}
`;
const ImageSection = styled.div`
width:40%;
position:relative;
display: flex;
align-items: center;
justify-content: center;
@media(max-width:767px){
  display: none;
}
`;

const ButtonWrapper = styled.div`

`;
const Heading = styled.div`
font-size:40px;
font-weight:900;
color:#fff;
margin-bottom:30px;
@media(max-width:767px){
  font-size:30px;
}
`;
const ErrorMessage = styled.div`
color:red;
padding:5px 0 10px 0;
`;

const Image = styled.img`
position: absolute;
width:100%;
`;

const DropDown = styled.div`
position: absolute;
max-height:400px;
overflow-y:scroll;
top:60px;
right:20px;
background-color:#fff;
z-index: 2;
box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
width:300px;
overflow-x:hidden;
::-webkit-scrollbar{
  width:4px;
  height:4px;
}
::-webkit-scrollbar-thumb{
border-radius:4px;
width:4px;
background-color:#808080;
}
`;
const Item = styled.div`
display: flex;
padding: 15px 10px;
align-items: center;
color:#808080;
font-size:14px;
:hover{
  box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
}
`;

const FlagImage = styled.img`
width:30px;
`;

const Code = styled.div`
/* margin-left:10px;
width:50px; */
`;

const Name = styled.div`
 margin-left:10px;
`;

const SelectInput = styled.div`
border-radius:31px;
height:3rem;
width:100%;
border:1px solid #808080;
background:none;
color:#808080;
padding-left:1.5rem;
display: flex;
align-items: center;
position: relative;
font-size:14px;
${(p) => p.right && `
 margin-right:20px;
`};
 @media(max-width:767px){
  margin-bottom: 20px;
  ${(p) => p.right && `
 margin-right:0px;
`}};
`;
const IconWrapper = styled.div`
position: absolute;
right: 10px;
`;
const InputWrapper = styled.div`
width:100%;
margin-right:20px;
position: relative;
`;

const PlaceHolderText = styled.div`
position: absolute;
top:15px;
left:10px;
color:#808080;
display: flex;
`;

const Line = styled.div`
width:1px;
height:20px;
background-color:#808080;
margin-left:5px;
`;
function MessageDropSection(props) {
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [message,setMessage] = useState('India');
  const [error,setError] = useState(false);
  const [showPopup,setShowPopup] = useState(false);
  const [responseMessage,setResponseMessage] = useState('');
  const [sucessResponse,setSucessResponse] = useState();
  const [showDropDown,setShowDropDown] = useState(false);
  const [CountryData,setCountryData] = useState();
  const [flag,setFlag] =useState('https://s3.amazonaws.com/rld-flags/in.svg');
  const [countryCode,setcountryCode] = useState('+91');
  const [showCountryCode,setshowCountryCode] = useState(false);

  useEffect(() =>{
      const createBackup = async() => {
      const data = await axios.get('http://159.89.169.43:2000/countries');
      const response = await data.data.data;
      setCountryData(response);
      return response;
      }
      createBackup();
  },[]);

  const SubmitHandler = (e) =>{
    e.preventDefault();
    if(name === '' || email === '' || message === '' || phone === ''){
       setError(true);
       return;
    }
    axios({
      method: 'post',
      url: 'http://159.89.169.43:5000/contactUs',
      data: {
        name: name,
        email: email,
        phone:phone,
        message:`country Code :${countryCode}, Country Name:${message}`,
      }
    })
    .then(function(response){
      setShowPopup(true);
      setSucessResponse(response.data.success);
      console.log(response.data);
      setResponseMessage(response.data.message);
    });
    setShowPopup(true);
    setSucessResponse();
    setResponseMessage();
    setError(false);
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
  }
    return (
        <Root>
        {showPopup && <SucessPopup 
        showPopup={showPopup} 
        setShowPopup={setShowPopup} 
        responseMessage={responseMessage}
        sucessResponse={sucessResponse}
       />}
       <Container>
             <Div>
               <Heading>
               We’d love to hear from you.
               </Heading>
                 <Form onSubmit={SubmitHandler}>
                   <FormWrapper>
                     <Input
                       type="text"
                       placeholder="Name"
                       right="20px"
                       value={name}
                       onChange={(e) => setName(e.target.value)}
                       required
                     />
                     <Input
                       type="email"
                       placeholder="E-mail"
                       right="20px"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       required
                     />
                   </FormWrapper>
                   <FormWrapper>
                    <InputWrapper>
                     <Input
                       left="90px"
                       type="number"
                       placeholder="Phone"
                       value={phone}
                       onChange={(e) => setPhone(e.target.value)}
                       min="10"
                       right="20px"
                       required
                      />
                      <PlaceHolderText onClick={() => setshowCountryCode(!showCountryCode)}>
                        <Name>{countryCode}<i class="fa-solid fa-angle-down"></i></Name><Line></Line>
                      </PlaceHolderText>
                      {showCountryCode && <DropDown>
                        {CountryData?.map((data,index) =>{
                          return(
                            <Item key={index} onClick={() => {setMessage(data.name);
                                setshowCountryCode(false);
                                setFlag(data.flag);
                                setcountryCode(data.callingCodes);
                                console.log(message);
                              }}>
                          {data.callingCodes}
                            </Item>
                          )
                        })}
                      </DropDown>}
                      </InputWrapper>
                     <SelectInput
                       right="20px"
                       onClick={() => setShowDropDown(!showDropDown)}
                     ><FlagImage src={flag}/><Name>{message}</Name>(<Code>{countryCode}</Code>) <IconWrapper> <i class="fa-solid fa-angle-down"></i></IconWrapper></SelectInput>
                     {showDropDown && <DropDown>
                          {CountryData?.map((data,index) => {
                            return(
                              <Item key={index} onClick={() => {setMessage(data.name);
                                setShowDropDown(false);
                                setFlag(data.flag);
                                setcountryCode(data.callingCodes);
                                console.log(message);
                              }}>
                                 <FlagImage src={data.flag}/><Name>{data.name}</Name>(<Code>{data.callingCodes}</Code> )
                              </Item>
                            )
                          })}
                      </DropDown>}
                   </FormWrapper>
                   {error && <ErrorMessage>All Field are required.</ErrorMessage>}
                   <ButtonWrapper>
                   <Button style={{
                     backgroundColor: "#00a97c",
                     color: "white", fontSize: "1.3rem",
                     borderRadius: "30px", width: "9rem",
                     height: "3rem",
                     textTransform: "capitalize",
                   }} type="submit">
                     Submit
                   </Button>
                   </ButtonWrapper>
                 </Form>
 
             </Div>
             <ImageSection >
              <Image src={FormImage} alt="NFT Marketplace Development" />
             </ImageSection>
       </Container>
     </Root>
    );
}

export default MessageDropSection;