import React from 'react';
import styled from 'styled-components';
import sucessImage from './Images/sucessImage.png';
import FailedImage from './Images/exclamation.png';

const Root = styled.div`
  position:fixed;
  width:400px;
  padding:80px 20px;
  background-color: #fff;
  border-radius:10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media(max-width:513px){
    width:90%;
  }
`;
const OverLay = styled.div`
 position:fixed;
 width:100%;
 height:100vh;
 top:0;
 bottom:0;
 background-color:rgba(0,0,0,0.8);
 left:0px;
 z-index:100;
 display: flex;
 align-items: center;
 justify-content:center;
 margin:0 auto;
`;

const SucessImage = styled.div`
font-size:100px;
& .fa-circle-check{
  color:#3ab54a;
}
`;

const CrossIconWrapper = styled.div`
 width:100%;
 display: flex;
 justify-content: right;

 & .fa-xmark{
  margin-top: -60px;
  cursor: pointer;
  font-size:18px;
 }
`;

const Message = styled.div`
text-align:center;
color:rgba(0,0,0,0.6);
`;

const Image = styled.img`
width:100px;
`;
function SucessPopup({showPopup,setShowPopup,responseMessage,sucessResponse}) {
  return (
    <OverLay onClick={() =>setShowPopup(false)}>
      <Root onClick={(e) => e.stopPropagation()}>
        <CrossIconWrapper>
          <i class="fa-solid fa-xmark" onClick={() => setShowPopup(false)}></i>
        </CrossIconWrapper>
        <SucessImage>
          {sucessResponse ? <i class="fa-solid fa-circle-check"></i> : <Image src={FailedImage} alt={FailedImage}/>}
        </SucessImage>
        <Message>
            {responseMessage || <>Request failed with status code 400</>}
        </Message>
      </Root>
    </OverLay>
  );
}

export default SucessPopup;