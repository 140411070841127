import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import BrandLogo from "../Component/Images/BrandLogo.png";
import { Container } from "../Component/styledComponents/styledComponents";
import styled from "styled-components";

const Root = styled.div`
  background-color: #fff;
  padding-top: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const Div = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: column;
  width: 33.33%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const InfoWrapper = styled.div`
  width: 30%;
  min-width: 30%;
  padding-right: 40px;
  @media (max-width: 888px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const Image = styled.img`
  width: 80px;
  margin-bottom: 10px;
`;

const Text = styled.div`
  color: #464646;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 20px;
  word-break: break-word;
  font-size: 14px;
  @media (max-width: 767px) {
    line-height: 1.5;
  }
`;
const A = styled.a`
  margin-right: 12px;
  font-size: 30px;
  color: rgb(104, 115, 125);
  :hover {
    color: #06615a;
  }
`;

const IconWrapper = styled.div`
  display: none;
  font-size: 18px;
  @media (max-width: 888px) {
    margin-bottom: 20px;
  }
  @media (min-width: 767px) {
    display: flex;
  }
`;

const Link = styled.div`
  color: ${(props) => (props.color ? props.color : "#464646")};
  margin-top: 16px;
  font-size: 14px;
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  :hover {
    text-decoration: ${(props) =>
      props.textDecoration ? props.textDecoration : "underline"};
    color: ${(props) => (props.color ? props.color : "#06615a")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  }
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const Heading = styled.div`
  color: #03363d;
  font-weight: bold;
  letter-spacing: 1.5px;
  font-size: 15px;
  margin-bottom: 10px;
  text-transform: uppercase;
  @media (max-width: 767px) {
    margin-bottom: 25px;
    cursor: pointer;
  }
`;

const FooterLink = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  @media (max-width: 888px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const BottomText = styled.div`
  text-align: center;
  padding-bottom: 10px;
  color: #464646;
  font-size: 14px;
`;

const Line = styled.div`
  background-color: #f1f7f5;
  width: 100%;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const LinkDesktopView = styled.div`
  display: none;
  @media (min-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const LinkMobileView = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const EventIconWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkWrapper = styled.div`
  display: flex;
  width: 70%;
  min-width: 70%;
  justify-content: space-between;
  @media (max-width: 888px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const IconWrappperMobileView = styled.div`
  font-size: 18px;
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
`;

const FooterBottomLink = styled.span`
  cursor: pointer;
  :hover {
    color: #03363d;
    text-decoration: underline;
  }
`;
function Footer() {
  const [showcompanyList, setShowCompanyList] = useState(false);
  const [showServiceList, setShowServiceList] = useState(false);
  const [showcontactList, setShowContactList] = useState(false);
  const navigation = useNavigate();

  return (
    <>
      <Root>
        <Container>
          <Wrapper>
            <FooterLink>
              <InfoWrapper>
                <Image src={BrandLogo} />
                <Text>
                  We equip businesses, enterprises, and start-ups with our
                  comprehensive range of development services and help them
                  establish next-level transparency and automation.
                </Text>
                <IconWrapper>
                  <A
                    href="https://www.facebook.com/metaspace.metaverse"
                    target="_blank"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </A>
                  <A
                    href="https://www.instagram.com/metaspace_metaverse/"
                    target="_blank"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </A>
                  <A href="https://twitter.com/metaspace_verse" target="_blank">
                    <i className="fa-brands fa-twitter"></i>
                  </A>
                  <A
                    href="https://www.linkedin.com/company/metaspace-metaverse/"
                    target="_blank"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </A>
                  {/* <A
                    href="https://in.pinterest.com/bastionex_Infotech/"
                    target="_blank"
                  >
                    <i className="fa-brands fa-pinterest"></i>
                  </A> */}
                </IconWrapper>
              </InfoWrapper>
              <LinkWrapper>
                <Div align="center" width="23.33%">
                  <HeadingWrapper
                    onClick={() => setShowCompanyList(!showcompanyList)}
                  >
                    <Heading>Company</Heading>
                    {!showcompanyList ? (
                      <EventIconWrapper>
                        <i className="fa-solid fa-plus"></i>
                      </EventIconWrapper>
                    ) : (
                      <EventIconWrapper>
                        <i className="fa-solid fa-minus"></i>
                      </EventIconWrapper>
                    )}
                  </HeadingWrapper>
                  <LinkDesktopView>
                    <Link
                      onClick={() => {
                        navigation("/aboutus");
                      }}
                    >
                      About Us
                    </Link>
                    <Link onClick={() => navigation("/contact_us")}>
                      Contact
                    </Link>
                  </LinkDesktopView>
                  {showcompanyList && (
                    <LinkMobileView>
                      <Link
                        onClick={() => {
                          navigation("/aboutus");
                        }}
                      >
                        About Us
                      </Link>
                      <Link onClick={() => navigation("/contact_us")}>
                        Contact
                      </Link>
                    </LinkMobileView>
                  )}
                </Div>
                <Div align="center" width="23.33%">
                  <HeadingWrapper>
                    <Heading
                      onClick={() => setShowServiceList(!showServiceList)}
                    >
                      Services
                    </Heading>
                    {!showServiceList ? (
                      <EventIconWrapper>
                        <i className="fa-solid fa-plus"></i>
                      </EventIconWrapper>
                    ) : (
                      <EventIconWrapper>
                        <i className="fa-solid fa-minus"></i>
                      </EventIconWrapper>
                    )}
                  </HeadingWrapper>
                  <LinkDesktopView>
                    <Link
                      onClick={() => {
                        navigation("/metaverse_development");
                      }}
                    >
                      Metaverse Developrment
                    </Link>
                    <Link
                      onClick={() => {
                        navigation("/blockchain_development");
                      }}
                    >
                      Blockchain Developrment
                    </Link>
                    <Link
                      onClick={() => {
                        navigation("/game_development");
                      }}
                    >
                      Game Developrment
                    </Link>
                    <Link
                      onClick={() => {
                        navigation("/mobile_app_development");
                      }}
                    >
                      Mobile App Developrment
                    </Link>
                    <Link
                      onClick={() => {
                        navigation("/web_development");
                      }}
                    >
                      Web Development
                    </Link>
                    <Link
                      onClick={() => {
                        navigation("/crypto_currency");
                      }}
                    >
                      Crypto Currency Development
                    </Link>
                    <Link
                      onClick={() => {
                        navigation("/nft_marketplace");
                      }}
                    >
                      Nft marketPlace Development
                    </Link>
                  </LinkDesktopView>
                  {showServiceList && (
                    <LinkMobileView>
                      <Link
                        onClick={() => {
                          navigation("/blockchain_development");
                        }}
                      >
                        Blockchain Development
                      </Link>
                      <Link
                        onClick={() => {
                          navigation("/game_development");
                        }}
                      >
                        Game Developrment
                      </Link>
                      <Link
                        onClick={() => {
                          navigation("/metaverse_development");
                        }}
                      >
                        Metaverse Developrment
                      </Link>
                      <Link
                        onClick={() => {
                          navigation("/mobile_app_development");
                        }}
                      >
                        Mobile App Developrment
                      </Link>
                      <Link
                        onClick={() => {
                          navigation("/web_development");
                        }}
                      >
                        Web Development
                      </Link>
                      <Link
                        onClick={() => {
                          navigation("/crypto_currency");
                        }}
                      >
                        Crypto Currency Development
                      </Link>
                      <Link
                        onClick={() => {
                          navigation("/nft_marketplace");
                        }}
                      >
                        Nft MarketPlace Development
                      </Link>
                    </LinkMobileView>
                  )}
                </Div>
                <Div align="center" width="23.33%">
                  <HeadingWrapper
                    onClick={() => {
                      setShowContactList(!showcontactList);
                    }}
                  >
                    <Heading>Contact US </Heading>
                    {!showcontactList ? (
                      <EventIconWrapper>
                        <i className="fa-solid fa-plus"></i>
                      </EventIconWrapper>
                    ) : (
                      <EventIconWrapper>
                        <i className="fa-solid fa-minus"></i>
                      </EventIconWrapper>
                    )}
                  </HeadingWrapper>
                  {showcontactList && (
                    <LinkMobileView>
                      {/* <Link>
                        <span style={{ fontWeight: "bold" }}>Phone:</span> +91
                        9811716213
                      </Link> */}
                      <Link>
                        <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                        info@metaspacelab.io
                      </Link>
                      <Link>
                        <span style={{ fontWeight: "bold" }}>Address: </span>{" "}
                        Metaspace Labs <br /> Information Technology L.L.C{" "}
                        <br />
                        UAE- Dubai - Al meydan road KML building - office 121
                      </Link>
                      <IconWrappperMobileView>
                        <A
                          href="#"
                          // target="_blank"
                        >
                          <i className="fa-brands fa-facebook"></i>
                        </A>
                        <A
                          href="#"
                          // target="_blank"
                        >
                          <i className="fa-brands fa-twitter"></i>
                        </A>
                        <A
                          href="#"
                          // target="_blank"
                        >
                          <i className="fa-brands fa-instagram"></i>
                        </A>
                        <A
                          href="#"
                          // target="_blank"
                        >
                          <i className="fa-brands fa-linkedIn"></i>
                        </A>
                        <A
                          href="#"
                          // target="_blank"
                        >
                          <i className="fa-brands fa-pinterest"></i>
                        </A>
                      </IconWrappperMobileView>
                    </LinkMobileView>
                  )}
                  <LinkDesktopView>
                    {/* <Link
                      cursor="auto"
                      textDecoration="none"
                      color="#464646"
                      fontWeight="400"
                    >
                      <span style={{ fontWeight: "bold" }}>Phone:</span> +91
                      9811716213
                    </Link> */}
                    <Link
                      cursor="auto"
                      textDecoration="none"
                      color="#464646"
                      fontWeight="400"
                    >
                      <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                      info@metaspacelab.io
                    </Link>
                    <Link
                      cursor="auto"
                      textDecoration="none"
                      color="#464646"
                      fontWeight="400"
                    >
                      <span style={{ fontWeight: "bold" }}>Address: </span>{" "}
                      Metaspace Labs <br /> Information Technology L.L.C <br />
                      UAE- Dubai - Al meydan road KML building - office 121
                    </Link>
                  </LinkDesktopView>
                </Div>
              </LinkWrapper>
            </FooterLink>
            <Line></Line>
            <BottomText>
              {" "}
              ©{new Date().getFullYear()}
              <span style={{ color: "#03363d", fontWeight: "bold" }}>
                {" "}
                metaspacelab
              </span>{" "}
              All Rights Reserved |
              <FooterBottomLink
                onClick={() => {
                  navigation("/termsandcondition");
                }}
              >
                Terms & Condition
              </FooterBottomLink>{" "}
              |
              <FooterBottomLink
                onClick={() => {
                  navigation("/privacyandpolicy");
                }}
              >
                Privacy Policy
              </FooterBottomLink>
            </BottomText>
          </Wrapper>
        </Container>
      </Root>
    </>
  );
}
export default Footer;
