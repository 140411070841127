import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';

const Root = styled.div`
padding:80px 0;
background-color:#fff;
border-bottom:1px solid #f1f7f5;
@media(max-width:767px){
    padding:30px 0;
}
`;
const Card = styled.div`
background-color: #0a0a0a;
width:50%;
${(p) => p.image && `
  background-image: linear-gradient(to top, #02ac7f, #025656);
`};
color:#fff;
padding:40px;
@media(max-width:767px){
    width:100%;
}
`;
const Heading = styled.div`
color:#fff;
font-size:30px;
font-weight:bold;
margin-bottom:20px;
/* @media(max-width:552px){
    font-size: 20px;
} */
`;
const IconWrapper = styled.div`
display: inline-flex;
align-items: center;
text-transform: capitalize;
position: relative;
z-index: 1;
background: transparent;
cursor:pointer;
padding: 18px 21px;
border-radius: 30px;
transition: .3s linear;
/* color: #018c67; */
${(p) => p.color ? `color:#000`:`color:#018c67`}
line-height: 1;
 ::after{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 51px;
  height: 51px;
  ${(p) => p.color ? `
    background-color:#fff;
    color:#000 !important;
  ` : `
  border:1px solid  #018c67;
  `};
  z-index: -1;
  border-radius: 30px;
  transition: .3s linear;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media(max-width:767px){
  width: 150px !important;
  } 
}
@media(max-width:767px){
  width: 150px !important;
}
 :hover::after{
  color: #fff;
  width: 150px;
}
`;

const Text = styled.div`
display: none;
transition: all 2s ease;
  ${IconWrapper}:hover & {
display: flex;
margin-left:5px;
  }
  @media(max-width:767px){
    display: flex;
    margin-left:5px;
}
`;

function CardSection(props) {
    const navigate = useNavigate();
    return (
        <Root>
        <Container>
            <Card color="#0a0a0a">
                <Heading>Want to become a part of the NFT revolution? 
                </Heading>
                <IconWrapper onClick={()=>{navigate('/contact_us')}}>
                <i class="fa-solid fa-arrow-right-long"></i>
                <Text>Contact Us</Text>
                </IconWrapper>
            </Card>
            <Card image="image">
                <Heading>
                Roll into the NFT <br/>world with us
                </Heading>
    
                <IconWrapper color="#fff" onClick={()=>{navigate('/contact_us')}}>
                   <i class="fa-solid fa-arrow-right-long"></i>
                   <Text>Get Started</Text>
                </IconWrapper>
            </Card>
        </Container>
    </Root>
    );
}

export default CardSection;