import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { Button } from "../../Component/styledComponents/styledComponents";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
background-color:#fff;
padding:80px 20px;
display: flex;
align-items: center;
justify-content:center;
flex-direction: column;
@media(max-width:767px){
    padding:30px 20px;
}
`;
const H3 = styled.h3`
margin: 0;
width:60%;
padding-bottom:1rem;
text-align: center;
font-size:60px;
font-weight:900;
@media(max-width:1000px){
    font-size: 40px;
    width:90%;
}
@media(max-width:767px){
    font-size: 40px;
    width:100%;
}
@media(max-width:510px){
    font-size: 30px;
}
@media(max-width:380px){
    font-size: 24px;
}
`;
function ClassServices(){
const navigate = useNavigate();
    return(
        <Root>

           <H3>Thrive on the top-notch services required to prosper your business.</H3>
            <Button 
              bgcolor='#000' 
              mtop="30px" 
              color="#fff" 
              top="15px" 
              bottom="15px" 
              left="30px"
              right="30px"
              radius="30px"
              fontSize="18px"
              onClick={() => {navigate('/contact_us')}}
              > Start A Project</Button>
        </Root>
    )
}
export default ClassServices;