import React from 'react';
import styled from 'styled-components';
import BgImage from '../../Component/Images/choose-bg.jpg';
import { Container } from '../../Component/styledComponents/styledComponents';
import ChainIcon1 from '../../Component/Images/icon1.png';
import ChainIcon2 from '../../Component/Images/icon2.png';
import ChainIcon3 from '../../Component/Images/icon3.png';
import ChainIcon4 from '../../Component/Images/icon4.png';
import ChainIcon5 from  '../../Component/Images/icon5.png';

const Root = styled.div`
background-image:url(${BgImage});
background-size:cover;
background-position:center;
padding:80px 0;
display: flex;
align-items: center;
justify-content: center;
color:#fff;
`;
const Text = styled.div`
font-size: 12px;
margin-bottom:20px;
`;
const H4 = styled.h4`
margin: 0;
font-size:52px;
font-weight: 400;
@media(max-width:767px){
    font-size:35px;
}
`;
const IconWrapper = styled.div`
display: flex;
justify-content: space-between;
margin-top:60px;
flex-wrap:wrap;
@media(max-width:767px){
    align-items: center;
    justify-content:center;
}
`;
const Icon = styled.img`
@media(max-width:767px){
    min-width:200px;
}
`;
const Wrapper = styled.div`
text-align: center;
width:100%;
`;
function ChainSection(props) {
    return (
        <Root>
            <Container>
                <Wrapper>
                <Text>Top Blockchains are at your disposal</Text>
                <H4>
                Choose your blockchain <br/>
                for Metaverse development
                </H4>
                <IconWrapper>
                    <Icon src={ChainIcon1} alt="Tron" />
                    <Icon src={ChainIcon2} alt="Ethereum" />
                    <Icon src={ChainIcon3} alt="Binance" />
                    <Icon src={ChainIcon4} alt="Solana" />
                    <Icon src={ChainIcon5} alt="Corda" />
                </IconWrapper>
                </Wrapper>
            </Container>
        </Root>
    );
}

export default ChainSection;