import React from 'react';
import ConnectMessage from '../../Component/ConnectMessage';
import LetsWorkTogether from '../Blockchian/LetsWorkTogether';
import GameServices from '../Game/GameServices';
import GameWeServe from '../Game/GameWeServe';
import Coustumerstories from '../Home/Coustumerstories';
import ChainSection from './ChainSection';
import MetaverseFeatureSection from './MetaverseFeatureSection';
import MetaverseHeroSection from './MetaverseHeroSection';
import MetaverseServices from './MetaverseServices';
import MetaverseUseCases from './MetaverseUseCases';
import SollutionSection from './SollutionSection';
import ProjectSection from '../OurWork/ProjectSection/ProjectSection';

function Metaverse(props) {
    return (
        <div>
                <MetaverseHeroSection/>
                <MetaverseUseCases/>
                <MetaverseServices/>
                {/* <SollutionSection/> */}
                {/* <MetaverseFeatureSection/> */}
                <ProjectSection/>
                <ChainSection/>
                <LetsWorkTogether />
                <ConnectMessage />
        </div>
    );
}

export default Metaverse;