import React from 'react';
import styled from 'styled-components';

const GridCardWrapper = styled.div`
  border: 1px solid #666666;
  height: 10.63rem;
  border: 1px solid #666666;
  background-color: #000;
  @media(max-width:400px){
   margin-top:10px;
  }
`;
const Image = styled.img`
  /* visibility: hidden; */
  opacity: 0.1;
  width: 100%;
  object-fit: cover;
  height: 168px;
  position: absolute;
  top:0;
  left:0;
  ${GridCardWrapper}:hover & {
    opacity: 1;
    /* visibility: visible; */
  }
  @media(max-width:767px){
    opacity: 1;
    /* visibility: visible; */
  }
`;

const InnerWrapper = styled.div`
  text-align: center;
  padding-top: 4.3rem;
  position: relative;
`;

const GridTextWrapper = styled.text`
  color: white;
  position: relative;
  width:100%;
  @media(max-width:400px){
     font-size:20px;
     font-weight:bold;
  }
`;
const ImageWrapper = styled.div``;
function GridCard({image,text}) {
    return (
        <GridCardWrapper>
            <InnerWrapper>
              <ImageWrapper>
                <Image src={image} alt="gridbackgroundimg" />
              </ImageWrapper>
              <GridTextWrapper>{text}</GridTextWrapper>
            </InnerWrapper>
          </GridCardWrapper>
    );
}

export default GridCard;