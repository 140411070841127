import React from "react";
import Header from "./Header";
import GameWeServe from "./GameWeServe";
import GameServices from "./GameServices";
import BikeRacing from "./BikeRacing";
import FeaturedGame from "./FeaturedGame"
import LetsWorkTogether from "../Blockchian/LetsWorkTogether";
import Technologies from "./Technologies";
import ConnectMessage from "../../Component/ConnectMessage";


const Game = () => {
  return (
    <>
      <Header />
      <GameServices />
      {/* <BikeRacing /> */}
      <FeaturedGame />
      <GameWeServe />
      <Technologies />
      <LetsWorkTogether />
      <ConnectMessage />

    </>
  );
};

export default Game;

