import React from 'react';
import styled, { keyframes } from 'styled-components';
import MainBgImage from '../../Component/Images/mobile-scroll-withbg.jpg';
import ScrollImage from '../../Component/Images/scroll-images.png';
import TopImage from '../../Component/Images/scroll-icon1.png';
import BottomImage from '../../Component/Images/scroll-icons2.png';

const Root = styled.div`
background-image:url(${MainBgImage});
padding:60px 0;
display:flex;
align-items: center;
justify-content: center;
flex-direction:column;
height: 640px;
background-position:center;
position: relative;
`;
const animatedBackground = keyframes`
from { background-position: 0 0; }
to { background-position: 100% 0; }
`;
const ScrollImageSection = styled.div`
background-image:url(${ScrollImage});
height: 400px;
width:100%;
background-position: 0px 0px;
background-repeat: repeat-x;
animation: ${animatedBackground} 15s linear infinite;
`;
const TopImageSection = styled.div`
background-image:url(${TopImage});
position: absolute;
width:100%;
height: 200px;
top:20px;
left:0;
background-position: 0px 0px;
background-repeat: repeat-x;
animation: ${animatedBackground} 40s linear infinite;
animation-timing-function:linear;
`;
const BottomImagesection = styled.div`
background-image:url(${BottomImage});
position: absolute;
width:100%;
height: 200px;
bottom:40px;
left:0;
background-position: 0px 0px;
background-repeat: repeat-x;
animation: ${animatedBackground} 40s linear infinite;
animation-timing-function:linear;
`;
function AppImageSection(props) {
    return (
        <Root>
            <TopImageSection></TopImageSection>
             <ScrollImageSection>

             </ScrollImageSection>
             <BottomImagesection></BottomImagesection>
        </Root>
    );
}

export default AppImageSection;