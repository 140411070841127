import styled from 'styled-components';
import Logo1 from '../../Component/Images/logo1-bastionex.png';
import Logo2 from '../../Component/Images/logo2-shereal.png';
// import Logo3 from '../../Component/Images/logo3-BastionEx.png';
import Logo4 from '../../Component/Images/logo4-meta.png';
import Logo5 from '../../Component/Images/logo5-collexion.png';
import CurveImage from '../../Component/Images/curve.png';

const Image = styled.img`
margin-right:20px;
@media(max-width:840px){
  max-width:90px;
  margin-top: 20px;

}
@media(max-width:640px){
  max-width:60px;
  margin-top: 20px;
}
`;

const Root = styled.div`
width:100%;
background-color:#cfeee6;
display:flex;
align-items:center;
justify-content: center;
position: relative;
`;
const CardWrapper = styled.div`
padding:30px 70px;
min-height:170px !important;
box-shadow: 0px 0px 15px 1px rgb(0 0 0 / 23%);
border-radius:6px;
width:900px;
margin-top: -80px;
display: flex;
align-items:center;
flex-direction:column;
background-color: #fff;
position:relative;
z-index:10;
@media(max-width:1024px){
  width:100%;
  margin: -40px 30px 0 30px;
  padding:30px 50px;
}
/* @media(max-width:960px){
  width:100%;
  margin:-20px 30px 0 30px;
  padding:30px 50px;
} */
@media(max-width:767px){
  width:100%;
  margin:-80px 30px 0 30px;
  padding:30px 20px;
}
`;
const ImageWrraper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width: 100%;
/* margin-top: 20px; */
margin: -30px 0px -25px 0px;
flex-wrap:wrap;
@media(max-width:767px){
  justify-content:center;
  margin: 1px;
}
`;
const Text = styled.div`
font-size:18px;
font-weight:500;
color:#686868;
text-align: center;
`;
const BgImage = styled.img`
position: absolute;
top:-55px;
left:0;
z-index:1;
width: 100%;
@media(max-width:767px){
   top:-50px;
}
@media(max-width:581px){
   top:-30px;
}

`;
function BelivingCompanies() {
  return (
    <Root>
      <BgImage src={CurveImage}/>
      <CardWrapper>
        <Text>World's top-class companies believing us!</Text>
         <ImageWrraper>
          <Image src={Logo1} alt="Bastionex Infotech Project" />
          <Image src={Logo2} alt="Bastionex Infotech Project" />
          {/* <Image src={Logo3}/> */}
          {/* <Image src={Logo4} alt="Bastionex Infotech Project" /> */}
          <Image src={Logo5} alt="Bastionex Infotech Project" />
          </ImageWrraper>
      </CardWrapper >
    </Root>
  );
}
export default BelivingCompanies;
