import React from "react";
import unreal from "../../Component/Images/unreal.png";
import CryEngine from "../../Component/Images/CryEngine.png";
import Maya from "../../Component/Images/maya.png";
import Photoshop from "../../Component/Images/photoshop.png";
import Unity from "../../Component/Images/unity.png"
import { ContainerWrapper, 
         EyeImage, 
         Image, 
         ImageContainer, 
         ImageWrapper, 
         Root, 
         TextimageWrapper, 
         TextWrapper, 
         TextWrappersmall, 
         UL } from "../../Component/styledComponents/TechnologySectionStyleComponents";

function Technologies() {
  return (
    <Root >
      <ContainerWrapper>
        <TextWrapper> TECHNOLOGY </TextWrapper>
        <TextWrappersmall>Stack We work On</TextWrappersmall>
      </ContainerWrapper>

      <UL>
        <ImageWrapper>
        <ImageContainer>
          <Image src={unreal} alt="unreal" /> 
          </ImageContainer>
          <TextimageWrapper> Unreal </TextimageWrapper>
        </ImageWrapper>

        <ImageWrapper>
          <ImageContainer>
          <EyeImage src={CryEngine} alt="CryEngine" /> 
          </ImageContainer>
          <TextimageWrapper> CryEngine</TextimageWrapper>
        </ImageWrapper>

        <ImageWrapper>
          <ImageContainer>
          <Image src={Maya} alt="Maya" />
          </ImageContainer>
          <TextimageWrapper>maya</TextimageWrapper>
        </ImageWrapper>
        <ImageWrapper>
          <ImageContainer>
            <Image src={Photoshop} alt="Photoshop" /> 
          </ImageContainer>
          <TextimageWrapper> Photoshop </TextimageWrapper>
        </ImageWrapper>

        <ImageWrapper>
           <ImageContainer>
          <Image src={Unity} alt="Unity" /> 
          </ImageContainer>
          <TextimageWrapper> Unity </TextimageWrapper>
        </ImageWrapper>

      </UL>
    </Root>
  );
}

export default Technologies;
