import React from 'react';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';
import BgImage from '../../Component/Images/circle.jpg';
import ArrowIcon from '../../Component/ArrowIcon';
import TrangleImage from '../../Component/Images/cardImage.png';
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
background-color: #0a0a0a;
padding:80px 0;
color:#fff;
position: relative;
display:flex;
flex-direction: column;
align-items: center;
@media(max-width:767px){
    padding:30px 0;
}
`;
const LeftWrapper = styled.div`
width:50%;
@media(max-width:767px){
    width:100%;
}
`;
const RightWrapper = styled.div`
width:50%;
position: relative;
height: 100%;
display: flex;
align-items: baseline;
justify-content: right;
@media(max-width:767px){
    display: none;
}
`;
const Heading = styled.h3`
 margin: 0;
 background-image: linear-gradient(to top, #00dea3, #037474), linear-gradient(to bottom, #fff, #fff);
 -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  font-weight: 900;
  margin-bottom:40px;
  @media(max-width:1221px){
    font-size: 50px;
  }
  @media(max-width:552px){
    font-size: 30px;
  }
`;

const ListSection = styled.div`
 padding-left:30px;
`;
const List = styled.div`
display: flex;
font-size:20px;
margin-bottom:20px;
align-items: center;
:hover{
    color:#00a97c;
}
@media(max-width:552px){
    font-size: 16px;
  }
`;
const Icon = styled.div`
margin-right:20px;
`;

const Trangle = styled.img`
position: absolute;
top:40%;
width:150px;
z-index: 2;
right:15%;
@media(max-width:767px){
    display: none;
}
`;
const ButtonContainer = styled.div`
 display: flex;
 width:100%;
 align-items: center;
 justify-content: center;
 margin-top:50px;
 position: relative;
 z-index: 2;
 @media(max-width:767px){
    margin-top:20px;
}
@media(max-width:500px){
    margin-top:10px;
}
`;

const Button = styled.button`
background:none;
border:1px solid #00a97b;
padding: 15px 30px;
border-radius:30px;
color:#fff;
font-size:16px;
cursor: pointer;
`;
const Image = styled.img`
position: absolute;
right: 100px;
bottom:0;
width:500px;
@media(max-width:1300px){
    right:0px;
}
@media(max-width:857px){
    width:400px;
}
@media(max-width:767px){
    display: none;
}
`;

function SollutionSection(props) {
    const navigate = useNavigate();
    return (
        <Root>
            <Container>
                <LeftWrapper>
                    <Heading>Our Ethereum
                        Blockchain Solutions</Heading>
                        <ListSection>
                           <List><Icon><ArrowIcon width="30px"/></Icon> Ethereum Consulting </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Ethereum Network Porting </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Ethereum Defi Development </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Asset Management </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Capital Market </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Payment & Digital Currencies </List>
                        </ListSection>
                </LeftWrapper>
                <RightWrapper>

                </RightWrapper>
            </Container>
            <ButtonContainer>
                <Button onClick={()=>{navigate('/contact_us')}}>Start A Project</Button>
            </ButtonContainer>
            <Image src={BgImage} alt="Ethereum Development"/>
            <Trangle src={TrangleImage} alt="Ethereum Development"/>
        </Root>
    );
}

export default SollutionSection;