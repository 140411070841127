import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Button } from "../../Component/styledComponents/styledComponents";
import OwlCarousel from "react-owl-carousel";
import nftBanner from "../../Component/Images/ban2.png";
import blockchainnftbanner from "../../Component/Images/ban3.png";
import BgImage from "../../Component/Images/back.jpg";
import EthereumIcon from "../../Component/Images/etherium-icon.png";
import BallIcon from "../../Component/Images/ball3.png";
import EthereumImage from "../../Component/Images/etherium-traiangle.png";
import HandImage from "../../Component/Images/etherium-hand.png";
import BoxImage from "../../Component/Images/box.png";
import { useNavigate } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Root = styled.div`
  width: 100%;
  background-image: url(${BgImage});
  position: relative;
`;
const Div = styled.div`
  width: 40%;
  padding-left: 100px;
  @media (max-width: 1120px) {
    padding-left: 20px;
  }
  @media (max-width: 900px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const H4 = styled.h4`
  margin: 0;
  font-size: 70px;
  font-weight: 900;
  display: flex;
  @media (max-width: 450px) {
    font-size: 50px;
  }
`;
const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  padding-right: 80px;
  @media (max-width: 450px) {
    padding-right: 20px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 3px;
  background-color: #fff;
  margin-left: 5px;
`;
const SubHeading = styled.div`
  font-size: 28px;
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  font-weight: bold;
`;
const GiphyGIF = styled.img`
  width: 60%;
  max-width: 60%;
  object-fit: fit-content;
  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
  }
`;

const NFTsImage = styled.img`
  width: 70%;
  max-width: 70%;
  object-fit: fit-content;
  margin-bottom: -30px;
  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
  }
  /* @media(max-width:767px){
  width:300px;
  max-width:300px;
} */
`;
const ImageWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 900px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  width: 92%;
  height: 570px;
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 40px 19px;
    height: 100%;
  }
`;
const animate = keyframes`
0%{
  transform: translateY(0);
 }
 50%{
  transform: translateY(-10px);
 }
 100%{
  transform: translateY(0);
 }
`;

const Rotate = keyframes`
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100%{
    transform: rotate(-5deg);
  }
 
`;
const RotateIcon = keyframes`
 from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

`;
// const Carousel = styled(OwlCarousel)`
// width:100%;
// display:flex;
// position:relative;
// margin: 0;
// padding: 0;
// cursor:pointer;
// `;
const Icon = styled.img`
  width: 45px;
  height: 78px;
  max-width: 45px;
  max-height: 78px;
  margin-left: 10px;
`;
const BallIconWrapper = styled.img`
  width: 70px;
  height: 70px;
  max-width: 70px;
  max-height: 70px;
  position: absolute;
  right: 0px;
  z-index: 100;
  top: -200px;
  animation: ${RotateIcon} infinite 20s linear;
  @media (max-width: 960px) {
  }
`;

const EtherIconImage = styled.img`
  position: absolute;
  top: -250px;
  width: 100%;
  max-width: 311px;
  animation-timing-function: ease-in;
  animation: ${animate} 7s infinite linear;
  z-index: 2;
  @media (max-width: 960px) {
    max-width: 260px;
    top: -230px;
  }
`;

const OuterImage = styled.img`
  position: absolute;
  top: -280px;
  max-width: 546px;
  width: 100%;
  animation: ${Rotate} infinite 20s linear;
  z-index: 5;
  @media (max-width: 960px) {
    max-width: 500px;
    top: -260px;
  }
`;

const HandImageWrapper = styled.img`
  position: absolute;
  top: -100px;
  max-width: 366px;
  width: 100%;
  z-index: 1;
  @media (max-width: 960px) {
    max-width: 300px;
  }
`;
function Services() {
  const navigate = useNavigate();
 
  return (
    <Root>
      <OwlCarousel
        className="owl-theme"
        loop={true}
        nav={true}
        items={1}
        dots={false}
        navText={[
          '<span class="arro prev">‹</span>',
          '<span class="arro next">›</span>',
        ]}
      >
        <CarouselWrapper>
          <Div>
            <H4>Non-fungible </H4>
            <SubHeading>tokens Developments</SubHeading>
            <Text>
              Unlock the doors of the blockchain world through these futuristic
              keys called NFTs by leveraging our world-class NFT development
              services with built-in security and consistent support.
            </Text>
            <Button
              mtop="30px"
              left="50px"
              right="50px"
              top="15px"
              bottom="15px"
              radius="50px"
              color="#fff"
              border="#fff"
              fontSize="20px"
              onClick={() => {
                navigate("/nfts");
              }}
            >
              View projects
            </Button>
          </Div>
          <ImageWrapper>
            <NFTsImage src={nftBanner} alt="Nft Development" />
          </ImageWrapper>
        </CarouselWrapper>

        <CarouselWrapper>
          <Div>
            <H4>
              Ethereum <Icon src={EthereumIcon} />
            </H4>
            <SubHeading>Development</SubHeading>
            <Text>
              We adopt agile methodologies to build top-notch ethereum-powered
              applications and smart contracts with immutable attributes to
              ensure high resilience and business continuity.
            </Text>
            <Button
              mtop="30px"
              left="50px"
              right="50px"
              top="15px"
              bottom="15px"
              radius="50px"
              color="#fff"
              border="#fff"
              fontSize="20px"
              onClick={() => {
                navigate("/ethereum");
              }}
            >
              View projects
            </Button>
          </Div>
          <ImageWrapper>
            <EtherIconImage src={EthereumImage} />
            <HandImageWrapper src={HandImage} />
            <OuterImage src={BoxImage} alt="Ethereum Development" />
            <BallIconWrapper src={BallIcon} />
          </ImageWrapper>
        </CarouselWrapper>
        
        <CarouselWrapper>
          <Div>
            <H4>Blockchain</H4>
            <SubHeading>& NFT games </SubHeading>
            <Text>
              We provide a fully managed blockchain service that helps develop
              incentives interactive gaming ecosystem by enabling new
              functionalities and a trusted environment.
            </Text>
            <Button
              mtop="30px"
              left="50px"
              right="50px"
              top="15px"
              bottom="15px"
              radius="50px"
              color="#fff"
              border="#fff"
              fontSize="20px"
              onClick={() => {
                navigate("/blockchain_development");
              }}
            >
              View projects
            </Button>
          </Div>
          <ImageWrapper>
            <GiphyGIF src={blockchainnftbanner} alt="Blockchain Development" />
          </ImageWrapper>
        </CarouselWrapper>
      </OwlCarousel>
 
    </Root>
  );
}
export default Services;
