import React from 'react';
import styled from 'styled-components';
import CardSection from './CardSection';
import InfoSection from './InfoSection';
import MessageDropSection from './MessageDropSection';
import NftHeroSection from './NftHeroSection';
import SollutionSection from './SollutionSection';
import WhyInfoSection from './WhyInfoSection';
import ConnectMessage from '../../Component/ConnectMessage';
import LetsWorkTogether from '../Blockchian/LetsWorkTogether';

const Root = styled.div`

`;
function Nft(props) {
    return (
        <Root>
           <NftHeroSection/>
           <InfoSection/>
            <WhyInfoSection/>
            {/* <MessageDropSection/> */}
            <CardSection/>
            <SollutionSection/>
            <LetsWorkTogether/>
           <ConnectMessage/> 
        </Root>
    );
}

export default Nft;