import React from "react";
import styled from 'styled-components';
import { Button } from "../../../Component/styledComponents/styledComponents";
import Python from "../../../Component/Images/python.png";
import PostGresql from "../../../Component/Images/postgresql.png";
import Docker from "../../../Component/Images/docker.png";
import Reactjs from "../../../Component/Images/react.png";
import Node from "../../../Component/Images/node.png";
import { useNavigate } from "react-router-dom";

const Card = styled.div`
background-color: #282828;
border-radius:10px;
padding:60px 90px;
@media(max-width:1100px){
  padding:40px 30px 40px 60px;
}
`;
const Heading = styled.div`
font-size:34px;
color:#fff;
font-weight:bold;
margin-bottom:20px;
`;
const Text = styled.div`
color:#9b9b9b;
font-size:18px;
`;

const IconSection = styled.div`
margin-top:40px;
display:flex;
align-items: center;
width:100%;
justify-content: space-between;
`;
const IconWrapper = styled.div`
text-align:center;
`;
const Icon = styled.img`
@media(max-width:1100px){
  width:40px;
}
`;
const Subtitle = styled.div`
color:#9b9b9b ;
margin-top:5px;
`;
const MetaverseDevelopment = () => {
   const navigation = useNavigate();
  return (
    <div>
    <Card>
        <Heading>
        Metaverse Development 
        </Heading>
        <Text>
        We assist businesses in launching their future-ready metaverse with our tailored solutions that are proven to deliver tangible results.
        </Text>
        <IconSection>
             <IconWrapper>
                <Icon src={Node}/>
                <Subtitle>Node Js</Subtitle>
             </IconWrapper>

             <IconWrapper>
                <Icon src={Reactjs}/>
                <Subtitle>Reactjs</Subtitle>
             </IconWrapper>

             <IconWrapper>
                <Icon src={PostGresql}/>
                <Subtitle>PostGresql</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={Docker}/>
                <Subtitle>Docker</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={Python}/>
                <Subtitle>Python</Subtitle>
             </IconWrapper>
        </IconSection>
        
        <Button
        border="#9b9b9b"
        color="#fff"
        top="12px"
        right="30px"
        left="30px"
        bottom="12px"
        radius="30px"
        mtop="30px"
        onClick={() => {navigation('/metaverse_development')}}
        >
          EXPLORE
        </Button>
      </Card>
    </div>
  );
};

export default MetaverseDevelopment;
