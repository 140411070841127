import React from "react";
import styled, { keyframes } from "styled-components";
import TopBgImage from "../../Component/Images/wave-top.png";
import MiddleBgImage from "../../Component/Images/wave-mid.png";
import BottomBgImage from "../../Component/Images/wave-bot.png";

const Root = styled.div`
  background-color: #010833;
  height: 670px;
  padding-top: 190px;
  text-align: center;
  @media (max-width: 767px) {
    height: 530px;
  }
`;
const MoveWave = keyframes`
	0% {
		transform: translateX(0) translateZ(0) scaleY(1)
	}

	50% {
		transform: translateX(-25%) translateZ(0) scaleY(0.55)
	}

	100% {
		transform: translateX(-50%) translateZ(0) scaleY(1)
	}
`;
const BgImageWrapper = styled.div`
  position: relative;
  top: 300px !important;
  animation: ${MoveWave} 3s;
  -webkit-animation: ${MoveWave} 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  @media (max-width: 767px) {
    top: 200px !important;
  }
  @media (max-width: 492px) {
    top: 230px !important;
  }
`;
const BgTopImage = styled.div`
  background-image: url(${TopBgImage});
  position: absolute;
  left: 0;
  top: -40px;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
  background-size: 50% 100px;
  animation: ${MoveWave} 3s;
  -webkit-animation: ${MoveWave} 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
`;
const BgMiddleImage = styled.div`
  background-image: url(${MiddleBgImage});
  position: absolute;
  left: 0;
  top: -40px;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
  background-size: 50% 120px;
  animation: ${MoveWave} 10s linear infinite;
`;
const BgBottomImage = styled.div`
  background-image: url(${BottomBgImage});
  background-size: 50% 100px;
  position: absolute;
  left: 0;
  width: 200%;
  top: -40px;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
  animation: ${MoveWave} 15s linear infinite;
`;
const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 150px;
  bottom: -1px;
  ${(p) =>
    p.topimage &&
    `
    	z-index: 15;
	    opacity: 0.5;
    `};
  ${(p) =>
    p.midimage &&
    `
      	z-index: 10;
	   opacity: 0.75;
    `};
  ${(p) =>
    p.botomimage &&
    `
        z-index: 5;
    `};
`;
const H2 = styled.h2`
margin: 0;
  font-size: 90px;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  background: linear-gradient(to right, #a5e292 10%, #00a97c 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: "Times New Roman", Times, serif;
  @media (max-width: 767px) {
    font-size: 70px;
  }
  @media (max-width: 492px) {
    font-size: 50px;
  }
`;
const H3 = styled.h3`
  margin: 0;
  font-size: 90px;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  background: linear-gradient(to right, #c7d182 20%, #00a97c 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: "Times New Roman", Times, serif;
  @media (max-width: 767px) {
    font-size: 70px;
  }
  @media (max-width: 492px) {
    font-size: 50px;
  }
`;
const Text = styled.div`
  color: #fff;
  margin-top: 10px;
  text-align: center;
  font-family: serif;
`;

function OurWorkHeroSection(props) {
  return (
    <Root>
        <H2>Sharp Skills</H2>
      <H3>Brilliant Minds</H3>
      <Text>CONVERTING YOUR IDEAS INTO REAL APPLICATION SMOOTHTY</Text>
      <BgImageWrapper>
        <ImageWrapper topimage="topimage">
          <BgTopImage waveTop="waveTop"></BgTopImage>
        </ImageWrapper>
        <ImageWrapper midimage="midimage">
          <BgMiddleImage bgMiddle="bgMiddle"></BgMiddleImage>
        </ImageWrapper>
        <ImageWrapper botomimage="botomimage">
          <BgBottomImage waveBottom="waveBottom"></BgBottomImage>
        </ImageWrapper>
      </BgImageWrapper>
    </Root>
  );
}

export default OurWorkHeroSection;
