import React from "react";
import arrow from "../../Component/Images/round-text-arrow.png";
import triangleicon from "../../Component/Images/tri-ball.png";
import circle from "../../Component/Images/circleicon.png";
import Rightimage from "../../Component/Images/game-development-banner.gif";
import styled from "styled-components";
import { Container } from "../../Component/styledComponents/styledComponents";
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
  background-color: #010833;
  background-image:url(${Rightimage});
  background-position:right;
  background-repeat:no-repeat;
  height: 39.5rem;
  width: 100%;
  display: flex;
  justify-content:space-between;
  @media(max-width:767px){
    flex-direction:column;
    height: 100%;
    padding-top:70px;
    padding-bottom: 50px;
    background-image:none;
  }
`;

const TextWrpper = styled.div`
  width:50%;
  max-width:50%;
  @media(max-width:767px){
    width:100%;
    max-width:100%;
  }
`;
const HeadingWrapper = styled.div`
  padding: 8rem 0rem 0rem 0rem;
  @media(max-width:1120px){
    padding: 8rem 0rem 0rem 0px;
  }
  @media(max-width:767px){
    padding: 40px 0rem 0rem 0px;
  }
`;
const BoldTextWrapper = styled.text`
  width: 256px;
  height: 73px;
  margin: 181px 240px 15px 0px;
  font-family: Roboto;
  font-size: 95px;
  font-weight: 900;
  line-height: 1.1;
  text-align: left;
  color: #fff;

  @media(max-width:1120px){
    width: 100%;
    margin: 0;
  }
  @media(max-width:520px){
    font-size: 60px;
  }
  @media(max-width:400px){
    font-size: 40px;
  }
`;
const SmallTextWrapper = styled.text`
  width: 301px;
  height: 48px;
  margin: 0rem 0rem 0rem 6rem;
  font-family: Roboto;
  font-size: 51px;
  font-weight: 900;
  line-height: 1.1;
  text-align: left;
  color: #fff;
  @media(max-width:1120px){
    width: 100%;
    margin: 0rem 0rem 0rem 20px;
  }
  @media(max-width:767px){
    font-size: 40px;
  }
`;
const ParaWrapper = styled.div`
  width: 100%;
  margin: 0rem 0rem 3rem 0rem;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.63;
  text-align: left;
  color: #fff;
  @media(max-width:1120px){
    padding: 0 20px;
    margin: 0rem 0rem 1rem 0rem;
    width:100%;
  }

`;

const Line = styled.div`
  width: 11rem;
  height: 0.5rem;
  background-color: #018c67;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(141deg);
  margin: -4.4rem 0rem 6rem 10rem;
  @media(max-width:1300px){
    /* margin: -4.4rem 0rem 6rem 16rem; */
  }
  @media(max-width:767px){
    display: none;
  }

`;

const IconWrapper = styled.img`
  cursor: pointer;
  margin: 0rem 0rem 0rem 0rem;
  @media(max-width:1120px){
    margin: 0rem 0rem 0rem 0px;
  }
`;

const CircleIcon = styled.img`
  padding: 0rem 0rem 1rem 7rem;
`;

const TriangleWrapper = styled.img`
  padding: 0rem 0rem 0rem 0rem;
  @media(max-width:1120px){

  }
  @media(max-width:767px){
    padding: 0rem 0rem -100px 0rem;
    margin-top:-20px
  }
`;

const RightWrapper = styled.div`
background-image:url(${Rightimage});
background-size:cover;
background-position:center;
width:50%;
min-width:50% !important;
@media(max-width:767px){
display: none;
}
`;

const H1 = styled.h1`
  margin: 0;
`;

function Header() {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
      <TextWrpper>
        <HeadingWrapper>
        <H1>
          <BoldTextWrapper>Game</BoldTextWrapper>
          <br />
          <CircleIcon src={circle} alt="circleicon" />
          <SmallTextWrapper>Development</SmallTextWrapper>
        </H1>
        </HeadingWrapper>
        <Line></Line>
        <TriangleWrapper src={triangleicon} alt="triangleicon" />
        <ParaWrapper>
          We have expertise in crafting, designing, developing and deploying of
          higher definition game from scratch that suits the specific industry
          standards and is interactive and rich-in visual elements.
        </ParaWrapper>
        <IconWrapper src={arrow} alt="iconimage" onClick={() => navigate('/contact_us')}/>
      </TextWrpper>
      </Container>
      {/* <RightWrapper>
      </RightWrapper> */}
    </Root>
  );
}

export default Header;