import React from 'react';
import styled from 'styled-components';
import BannerImage from '../../Component/Images/banner-img.jpg';
import {Container } from '../../Component/styledComponents/styledComponents';
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
background-color:#052546;
background-image:url(${BannerImage});
background-size:contain;
background-position:right;
background-repeat:no-repeat;
padding:170px 0 100px 0;
@media(max-width:767px){
    padding:120px 0 50px 0;
    background-image:none;
}
`;
const LeftSection = styled.div`
width:50%;
@media(max-width:848px){
    width:60%;
}
@media(max-width:767px){
width:100%;
}
`;
const RightSection = styled.div`
width:50%;
@media(max-width:848px){
    width:40%;
}
@media(max-width:767px){
display: none;
}
`;
const Heading = styled.h2`
margin: 0;
font-size:84px;
font-weight:bold;
color:#fff;
@media(max-width:1170px){
    font-size:60px;
}
@media(max-width:457px){
font-size:50px;
}
@media(max-width:382px){
font-size:40px;
}
`;
const Button = styled.button`
margin-top:20px;
background-image: linear-gradient(to right, #00dea3,#037474);
border:none;
padding:15px 30px;
color:#fff;
border-radius:30px;
font-weight:500;
cursor: pointer;
transition: all 2s ease;
:hover{
    background:#fff;
    color:#000;
}
`;
function NftHeroSection(props) {
    const navigate = useNavigate();
    return (
        <Root>
            <Container>
                <LeftSection>
                    <Heading>Build your own
                        inclusive world
                        of NFTs!</Heading>
                        <Button onClick={() => navigate('/contact_us')}>
                        START A PROJECT
                        </Button>
                </LeftSection>
                <RightSection>

                </RightSection>
            </Container>
        </Root>
    );
}

export default NftHeroSection;