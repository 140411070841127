import React from 'react';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../../Component/ArrowIcon';
import ServiceImage from '../../Component/Images/services-1.png';

const Root = styled.div`
background-color: #0a0a0a;
padding:60px 0;
color:#fff;
position: relative;
@media(max-width:767px){
    padding:30px 0;
}
`;
const LeftWrapper = styled.div`
width:50%;
@media(max-width:767px){
    width:100%;
}
`;
const RightWrapper = styled.div`
width:50%;
position: relative;
height: 100%;
display: flex;
align-items: baseline;
justify-content: right;
@media(max-width:767px){
    display: none;
}
`;
const Heading = styled.div`
 background-image: linear-gradient(to top, #00dea3, #037474), linear-gradient(to bottom, #fff, #fff);
 -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  font-weight: 900;
  margin-bottom:40px;
  @media(max-width:1221px){
    font-size: 50px;
  }
  @media(max-width:1004px){
    font-size: 40px;
  }
  @media(max-width:552px){
    font-size: 30px;
  }
`;

const ListSection = styled.div`
 padding-left:30px;
 @media(max-width:500px){
    padding-left:15px;
 }
`;
const List = styled.div`
display: flex;
font-size:20px;
margin-bottom:20px;
align-items: center;
:hover{
    color:#00a97c;
}
@media(max-width:552px){
    font-size: 16px;
    margin-bottom:15px;
}
`;
const Icon = styled.div`
margin-right:20px;
`;
const Image = styled.img`
position: absolute;
right: 100px;
bottom:0;
width:500px;
@media(max-width:1300px){
    right:0px;
}
@media(max-width:857px){
    width:400px;
}
@media(max-width:767px){
    display: none;
}
`;


const ButtonContainer = styled.div`
 display: flex;
 width:100%;
 align-items: center;
 justify-content: center;
 margin-top:50px;
 position: relative;
 z-index: 2;
 @media(max-width:767px){
    margin-top:20px;
 }
 @media(max-width:500px){
    margin-top:10px;
 }
`;

const Button = styled.button`
background:none;
border:1px solid #00a97b;
padding: 15px 30px;
border-radius:30px;
color:#fff;
font-size:16px;
cursor: pointer;
`;
function CryptosollutionSection(props) {
    const navigate = useNavigate();
    return (
        <Root>
           <Root>
            <Container>
                <LeftWrapper>
                    <Heading>Our Cryptocurrency Exchange Solutions</Heading>
                        <ListSection>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Metaverse Development </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Ethereum Network Porting  </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Ethereum Defi Development  </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Asset Management  </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Capital Market </List>
                           <List><Icon><ArrowIcon width="30px"/></Icon>Payment & Digital currencies  </List>
                        </ListSection>
                </LeftWrapper>
                <RightWrapper>
                </RightWrapper>
            </Container>
            <ButtonContainer>
                <Button onClick={()=>{navigate('/contact_us')}}>Start A Project</Button>
            </ButtonContainer>
         <Image src={ServiceImage}/>
        </Root> 
        </Root>
    );
}

export default CryptosollutionSection;