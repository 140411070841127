import React from "react";
import styled from "styled-components";
import { Button } from "../../../Component/styledComponents/styledComponents";
import UnityLogo from '../../../Component/Images/unity.png';
import Unreal from "../../../Component/Images/unreal.png";
import CryEngine from "../../../Component/Images/CryEngine.png";
import Maya from "../../../Component/Images/maya.png";
import Photoshop from "../../../Component/Images/photoshop.png";
import { useNavigate } from "react-router-dom";

const Card = styled.div`
background-color: #282828;
border-radius:10px;
padding:60px 90px;
@media(max-width:1100px){
  padding:40px 30px 40px 60px;
}
`;
const Heading = styled.div`
font-size:34px;
color:#fff;
font-weight:bold;
margin-bottom:20px;
`;
const Text = styled.div`
color:#9b9b9b;
font-size:18px;
`;

const IconSection = styled.div`
margin-top:40px;
display:flex;
align-items: center;
width:100%;
justify-content: space-between;
`;
const IconWrapper = styled.div`
text-align:center;
`;
const Icon = styled.img`
@media(max-width:1100px){
  width:40px;
}
`;
const Subtitle = styled.div`
color:#9b9b9b ;
margin-top:5px;
`;
const GameDevelopment = () => {
  const navigation = useNavigate();
  return (
    <div>
      <Card>
        <Heading>
        Game Development 
        </Heading>
        <Text>
        We explicitly focus on developing highly  responsive games with attractive animations using different cutting-edge technologies.
        </Text>
        <IconSection>
             <IconWrapper>
                <Icon src={UnityLogo}/>
                <Subtitle>Unity</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={Unreal}/>
                <Subtitle>Unreal</Subtitle>
             </IconWrapper>

             <IconWrapper>
                <Icon src={CryEngine}/>
                <Subtitle>CryEngine</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={Maya}/>
                <Subtitle>Maya</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={Photoshop}/>
                <Subtitle>Photoshop</Subtitle>
             </IconWrapper>
        </IconSection>
        <Button
        border="#9b9b9b"
        color="#fff"
        top="12px"
        right="30px"
        left="30px"
        bottom="12px"
        radius="30px"
        mtop="30px"
        onClick={() => {navigation('/game_development')}}
        >
          EXPLORE
        </Button>
      </Card>
    </div>
  );
};

export default GameDevelopment;
