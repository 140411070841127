import React from 'react';
import styled from 'styled-components';
import Python from "../../Component/Images/python.png";
import postgresql from "../../Component/Images/postgresql.png";
import docker from "../../Component/Images/docker.png";
import react from "../../Component/Images/react.png";
import node from "../../Component/Images/node.png";
import { ContainerWrapper, 
    Image, 
    ImageContainer, 
    ImageWrapper, 
    Root, 
    TextimageWrapper, 
    TextWrapper, 
    TextWrappersmall, 
    UL } from '../../Component/styledComponents/TechnologySectionStyleComponents';

function TechnologySection(props) {
    return (
        <Root>
        <ContainerWrapper>
           <TextWrapper> TECHNOLOGY </TextWrapper>
           <TextWrappersmall>Stack We work On</TextWrappersmall>
        </ContainerWrapper>
        <UL>
           <ImageWrapper>
              <ImageContainer>
                 <Image src={docker} />
              </ImageContainer>
              <TextimageWrapper> docker </TextimageWrapper>
           </ImageWrapper>
           <ImageWrapper>
              <ImageContainer>
                 <Image src={Python} />
              </ImageContainer>
              <TextimageWrapper> Python </TextimageWrapper>
           </ImageWrapper>
           <ImageWrapper>
              <ImageContainer>
                 <Image src={postgresql} />
              </ImageContainer>
              <TextimageWrapper>postgresql</TextimageWrapper>
           </ImageWrapper>
           <ImageWrapper>
              <ImageContainer>
                 <Image src={react} />
              </ImageContainer>
              <TextimageWrapper>React js</TextimageWrapper>
           </ImageWrapper>
           <ImageWrapper>
              <ImageContainer>
                 <Image src={node} />
              </ImageContainer>
              <TextimageWrapper>Node js</TextimageWrapper>
           </ImageWrapper>
        </UL>
     </Root>
    );
}

export default TechnologySection;