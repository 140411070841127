import React from 'react';
import BelivingCompanies from './BelivingCompanies';
import BussinessService from './BussinessService';
import ClassServices from './ClassServics';
import Clients from './Clients';
import Coustumerstories from './Coustumerstories';
import HeroSection from './HeroSection';
import Services from './Services';
import MetaverseSection from './MetaverseSection';
import ConnectMessage from '../../Component/ConnectMessage';

const Home = () =>{
    return(
        <>
           <HeroSection/>
           <BelivingCompanies />
           <BussinessService />
           <Services/> 
           <ClassServices />
           <MetaverseSection />
           <Clients />
           <Coustumerstories />
           <ConnectMessage /> 
        </>
    );
};

export default Home;