import { Typography } from "@mui/material";
import { Box, Container, height } from "@mui/system";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import styled from "styled-components";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AvatarImage1 from "../../Component/Images/avtar1.jpg";
import AvatarImage2 from "../../Component/Images/avtar2.jpg";
import AvatarImage3 from "../../Component/Images/avtar3.jpg";
import AvatarImage4 from "../../Component/Images/avtar4.jpg";
import CompanyLogo from "../../Component/Images/companylogo.png";
import CompanyLogo1 from "../../Component/Images/companylogo1.png";
import CompanyLogo2 from "../../Component/Images/companylogo2.png";
import CompanyLogo3 from "../../Component/Images/companylogo3.png";
import QuoteIcon from "../../Component/Images/quote-icon.png";

const QuoteImage = styled.img`
  width: 40px !important;
  position: absolute;
  left: -20px;
  top: 20px;
`;
const Card = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 30px 20px;
  min-height: 450px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
`;
const Root = styled.div`
  background-color: #f8f8f8;
  padding: 30px 0 80px 0;
  @media (max-width: 767px) {
    padding: 10px 0 30px 0;
  }
`;
const Image = styled.img`
  max-width: 100px !important;
  margin-top: 20px;
  max-height: 70px;
  object-fit: fit-content;
`;

const Div = styled.div`
  color: rgb(128, 128, 128);
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  text-align: left;
  margin-top: 10px;
`;
const Carousel = styled(OwlCarousel)`
  width: 100%;
  display: flex;
  /* position:relative; */
  /* padding: 0 20px; */

  & .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: flex;
  }
  & .owl-prev {
    position: absolute;
    top: 40%;
    left: -22px !important;
    font-size: 40px !important;
    z-index: 10px;
    @media (max-width: 767px) {
      left: 0 !important;
    }
  }
  & .owl-next {
    position: absolute;
    top: 40%;
    right: -22px !important;
    font-size: 40px !important;
    z-index: 10px;
    @media (max-width: 767px) {
      right: 0 !important;
    }
  }
  @media (max-width: 767px) {
    padding: 0px 0px;
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Info = styled.div`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-align: left;
  margin-left: 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const Avatar = styled.img`
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  background-color: #f8f8f8;
  border-radius: 50%;
`;
function Clients() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    navText: [
      `<div class="arrow">
       <i class="fa-solid fa-chevron-left"></i>
       </div>`,
      `
    <div class="arrow">
    <i class="fa-solid fa-chevron-right"></i>
    </div>`,
    ],
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      500: {
        items: 2,
      },
      900: {
        items: 3,
      },
    },
  };
  return (
    <Root>
      <Container>
        <Box sx={{ height: "100%", textAlign: "center" }}>
          <Box sx={{ margin: "2rem 0rem 2rem 0rem" }}>
            <Typography sx={{ color: "#323232", fontWeight: "500" }}>
              OUR HAPPY CLIENTS
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                fontFamily: "Roboto , sans-serif",
              }}
            >
              {/* Customer Stories */}
              There are employees, who are working or have worked on it and no
              clients feedback is mentioned
            </Typography>
          </Box>

          <Carousel {...options}>
            <Card>
              <QuoteImage src={QuoteIcon} />
              <InfoWrapper>
                <Avatar
                  src={AvatarImage1}
                  // alt="Bastionex Infotech Testimonials"
                  alt="metaspacelab Testimonials"
                />
                <Info>
                  ROBERT
                  <Div>Chief Executive Officer at Phoenix</Div>
                </Info>
              </InfoWrapper>
              <Div>
                While working at metaspacelab, I have been amazed by the numerous
                opportunities company has offered me to flourish technically,
                creatively and personally. When I joined, the work culture was
                irresistible, and I promptly felt the pride and enthusiasm of my
                colleagues for the work they had been doing together and their
                blissful clients!
              </Div>

              <Image src={CompanyLogo} />
            </Card>
            <Card>
              <QuoteImage src={QuoteIcon} />
              <InfoWrapper>
                <Avatar
                  src={AvatarImage2}
                  // alt="Bastionex Infotech Testimonials"
                  alt="metaspacelab Testimonials"
                />
                <Info>
                  MICHAEL
                  <Div>Chief Technology Officer at Power</Div>
                </Info>
              </InfoWrapper>
              <Div>
                metaspacelab is one of the best companies to work with. I am glad I
                got to experience it. Companies allow you to wear multiple hats
                and empower you with the freedom to make decisions with an
                amalgamation of young and old workforce to team up with. I have
                spent two surplus years with the organization and have much to
                be thankful for.
              </Div>
              <Image src={CompanyLogo1} />
            </Card>
            <Card>
              <QuoteImage src={QuoteIcon} />
              <InfoWrapper>
                <ImageWrapper>
                  <Avatar
                    src={AvatarImage3}
                    // alt="Bastionex Infotech Testimonials"
                    alt="metaspacelab Testimonials"
                  />
                </ImageWrapper>
                <Info>
                  Matthew Wellschlager
                  <Div>VP of Marketing at THM</Div>
                </Info>
              </InfoWrapper>
              <Div>
                metaspacelab is one of my favourite companies I have worked with.
                The company provides the best of both worlds by challenging
                employees with meaningful work in an exciting industry while
                also exhibiting similar characteristics found among the most
                mature high-performing companies in the world.
              </Div>
              <Image src={CompanyLogo2} />
            </Card>
            <Card>
              <QuoteImage src={QuoteIcon} />
              <InfoWrapper>
                <ImageWrapper>
                  <Avatar
                    src={AvatarImage4}
                    // alt="Bastionex Infotech Testimonials"
                    alt="metaspacelab Testimonials"
                  />
                </ImageWrapper>
                <Info>
                  JAMES
                  <Div>Chief Executive Officer at Valour</Div>
                </Info>
              </InfoWrapper>
              <Div>
                I started as an Intern at metaspacelab during my last semester of
                college and immediately felt welcomed throughout the entire work
                span. I found myself working with a fantastic company in my
                first full-time opportunity, doing something I enjoy, and
                progressing my career personally and professionally.
              </Div>
              <Image src={CompanyLogo3} />
            </Card>

          </Carousel>
        </Box>
      </Container>
    </Root>
  );
}
export default Clients;
