import React from "react";
import ArtificialBanner from "../../Component/Images/banner_artificial_intelligence1.gif";
import styled from "styled-components";
import {
  Button,
  Container,
} from "../../Component/styledComponents/styledComponents";

const Root = styled.div`
  height: auto;
  background-image: url(${ArtificialBanner});
  width: 100%;
  //header height added
  padding-top: 70px;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: #110044;
`;

const Div = styled.div`
  padding: 140px 0px 100px;

  @media (max-width: 1196px) {
    margin: -5rem 0rem 0rem 6rem;
  }
  @media (max-width: 914px) {
    margin: -10rem 0rem 0rem 5rem;
  }
  @media (max-width: 790px) {
    margin: -10rem 0rem 0rem 5rem;
  }
`;

const HeadingWrapper = styled.div``;

const LargeText = styled.h1`
  margin: 0;
  font-size: 60px;
  color: #fff;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 51px;
    color: #fff;
    font-weight: 600;
    line-height: 52.31px;
    padding-top: 10rem;
  }
  @media (max-width: 900px) {
    font-size: 51px;
    color: #fff;
    font-weight: 600;
    line-height: 50.31px;
    padding: 14rem 0rem 0rem 0rem;
  }
  @media (max-width: 790px) {
    font-size: 50px;
    color: #fff;
    font-weight: 600;
    line-height: 49.31px;
    padding: 13rem 0rem 1rem 0rem;
    margin: 0rem 0rem 0rem -2rem;
  }
  @media (max-width: 425px) {
    font-size: 27px;
    color: #fff;
    font-weight: 600;
    line-height: 28.31px;
    margin: -1.5rem 0rem 0rem -5rem;
  }
`;
const ParagraphWrapper = styled.div`
  @media (max-width: 425px) {
    width: 17rem;
    font-size: 13px;
    margin: 0rem 0rem 0rem -4rem;
  }
`;

const Para = styled.div`
  color: white;
  padding-top: 1rem;
  width: 21rem;
  font-size: 18px;
  line-height: 21px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  @media (max-width: 1024px) {
    padding-top: 1.2rem;
    width: 21rem;
    font-size: 16px;
    line-height: 19px;
  }
  @media (max-width: 790px) {
    width: 20rem;
    font-size: 15px;
    margin: -1.4rem 0rem 0rem -2rem;
  }
  @media (max-width: 425px) {
    width: 14rem;
    font-size: 13px;
    margin: -1.4rem 0rem 0rem -1rem;
  }
`;

const ButtonWrapper = styled.div`
  @media (max-width: 790px) {
    margin: 1rem 0rem 2rem -2rem;
  }
  @media (max-width: 425px) {
    margin: 1rem 0rem 2rem -5rem;
  }
`;

const HeaderSection = () => {
  return (
    <Root>
      <Container>
        <Div>
          <HeadingWrapper>
              <LargeText>
                Website Software <br /> Development
              </LargeText>
       
          </HeadingWrapper>
          <ParagraphWrapper>
            <Para>We build great web solutions that amplify your brand</Para>
          </ParagraphWrapper>
          <ButtonWrapper>
            <Button
              color="white"
              border="#FFFFFF"
              radius="2rem"
              mtop="1.3rem"
              mbottom="4rem"
              width="9rem"
              height="3rem"
              fontSize="1rem"
            >
              View portfolio
            </Button>
          </ButtonWrapper>
        </Div>
      </Container>
    </Root>
  );
};

export default HeaderSection;
