import React from "react";
import styled, { keyframes } from "styled-components";
import Banner from "../../Component/Images/about-sec2.jpg";
import Banner2 from "../../Component/Images/about-img2.jpg";
import Banner3 from "../../Component/Images/about-img3.jpg";

const Root = styled.div`

`;
const animate = keyframes`
0%{
  background-image: url(${Banner});
 }
 20%{
  background-image: url(${Banner});
 }
 30%{
  background-image: url(${Banner2});
 }
 50%{
  background-image: url(${Banner2});
 }
 60%{
  background-image: url(${Banner3});
 }
 80%{
  background-image: url(${Banner3});
 }
 100%{
  background-image: url(${Banner});
 }
`;
const Imagewrapper = styled.div`
  position: relative;
  width: 100%;
  height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  animation: ${animate} 15s linear infinite;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size:100px;
  font-weight:bold;
  color:#fff;
  @media(max-width:796px){
    font-size:60px;
    height: 500px;
  }
  @media(max-width:537px){
    font-size:40px;
    height: 400px;
  }
`;
const Textanimate1 =  keyframes`
  0% {
      content: "You think";
  }
  20% {
      content: "You think";
  }

  30% {
      content: "Embrace strategic";
  }
  50% {
      content: "Embrace strategic";
  }

  60% {
      content: "Conquer evolving";
  }
  80% {
      content: "Conquer evolving";
  }
  100%{
     content: "You think";
  }
`;
const Textanimate2 =  keyframes`
  0% {
      content: "We build!";
  }

  20% {
      content: "We build!";
  }

  30% {
      content: "digital journey!";
  }
  50% {
      content: "digital journey!";
  }

  60%{
      content: "tech-world!";
  }
  80%{
      content: "tech-world!";
  }
  100%{
    content: "We build!";
  }
 
`;
const Text = styled.div`
display: flex;
align-items: center;
justify-content: center;
width:100%;
::after{
          content: "You think'\a' We build!";
          animation: ${Textanimate1} infinite 15s;
        }

`;
const Div = styled.div`
display: flex;
align-items: center;
justify-content: center;
::before{
  content: "You think";
  animation: ${Textanimate2} infinite 15s;
}
`;
function Aboutsec2() {
  return (
    <Root>
      <Imagewrapper>
        <Text>
        </Text>
        <Div></Div>
      </Imagewrapper>
    </Root>
  );
}

export default Aboutsec2;
