import React from 'react';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';
import BgImage from '../../Component/Images/banner-imgcrypto.jpg';

const Root = styled.div`
background-color:#f3f3f3;
`;
const LeftSection = styled.div`
width:50%;
padding:170px 0 100px 0;
@media(max-width:767px){
    width:100%;
    padding:100px 0 30px 0;
}
`;
const RightSection = styled.div`
width:50%;
background-image:url(${BgImage});
padding-bottom:100px;
background-repeat:no-repeat;
background-size:contain ;
background-position:right;
padding-top:170px;
margin-top:90px;
@media(max-width:767px){
    display: none;
}
`;
const Heading = styled.div`
font-size:75px;
font-weight: bold;
color:#353535;
@media(max-width:767px){
font-size:65px;
}
@media(max-width:535px){
font-size:45px;
}
`;
const Para = styled.div`
margin-top:20px;
color:#333333;
`;
const Button = styled.button`
margin-top:20px;
background-image: linear-gradient(to right, #00dea3,#037474);
border:none;
padding:15px 30px;
color:#fff;
border-radius:30px;
font-weight:500;
cursor: pointer;
`;
function ExchangeCryptoHeroSection(props) {
    return (
        <Root>
            <Container>
                <LeftSection>
                    <Heading>Crypto Currency
                        Exchange</Heading>
                    <Para>The forefront leader of the reliable Ethereum development services to make operations more viable.  </Para>
                    <Button>START A PROJECT</Button>
                </LeftSection>
                <RightSection>

                </RightSection>
            </Container>
        </Root>
    );
}

export default ExchangeCryptoHeroSection;