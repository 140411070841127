import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
 fill:#fff;
 transform: rotate(220deg);
 & :hover{
    fill:#00a97c;
 }
`;
function ArrowIcon({color,width}) {
    return (
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={width}>
        <g id="_18-Arrow_Down" data-name="18-Arrow Down">
        <path d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30Z" fill={color}/>
        <path d="M17,23.59V5H15V23.59L9.71,18.29,8.29,19.71l7,7a1,1,0,0,0,1.41,0l7-7-1.41-1.41Z" fill={color}/></g>
        </SVG>
    );
}

export default ArrowIcon;