import React from 'react';
import styled from 'styled-components';
import { Container} from '../../Component/styledComponents/styledComponents';
import Cardbg1 from '../../Component/Images/ser1.jpg';
import Cardbg2 from '../../Component/Images/ser2.jpg';
import Cardbg3 from '../../Component/Images/ser3.jpg';
import Cardbg4 from '../../Component/Images/ser4.jpg';
import Cardbg5 from '../../Component/Images/ser5.jpg';
import Cardbg6 from '../../Component/Images/ser6.jpg';
import MetaverseImage from '../../Component/Images/ser5a.png';
import ArrowIcon from '../../Component/Images/round-text-arrow.png';
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
padding:60px 0;
position:relative;

`;
const Wrapper = styled.div`
display: flex;
align-items: center;
flex-direction:column;
justify-content:center;
position: relative;
width:100%;
`;
const Line = styled.div`
position: absolute;
right:0;
top:0;
height:15px;
width:50%;
background-image:linear-gradient(to right, #a5e292 , #00a97c);
`;

const Text = styled.div`
color:#131313;
font-size:18px;
font-weight: bold;
margin-bottom:5px;
`;

const Heading = styled.h2`
margin: 0;
font-size:50px;
color:#131313;
font-weight: 900;
margin-bottom:15px;
@media(max-width:767px){
    font-size:30px;
}
`;

const Para = styled.div`
color:#0f0f0f;
width:70%;
text-align:center;
margin-bottom:20px;
@media(max-width:767px){
    width:100%;
}
`;

const ServicesSection = styled.div`
width:100%;
position: relative;
`;
const Div = styled.div`
width:100%;
display: flex;
justify-content: space-between;
align-items: center;
@media(max-width:648px){
    flex-direction: column;
}
`;
const Card = styled.div`
${(p) => p.bgImage && `
   background-image:url(${p.bgImage});
`};
background-repeat:no-repeat;
background-size:cover;
background-position:100% 100%;
width:100%;
${(p) => p.height ? `height: ${p.height};margin-top:20px` : `height: 233px;`};
position: relative;
`;
const SmallCard = styled.div`
${(p) => p.bgImage && `
   background-image:url(${p.bgImage});
`};
background-repeat:no-repeat;
background-size:cover;
background-position:100% 100%;
width:calc(50% - 10px);
height: 233px;
position: relative;
@media(max-width:648px){
    width:100%;
    ${(p)=> p.bottom && `
      margin-bottom:${p.bottom};
    `};
}
`;
const LeftCardWrapper = styled.div`
width:calc(60% - 10px);
/* height: 100%; */
@media(max-width:648px){
    width:100%;
    margin-bottom:20px;
}
`;

const RightCardWrapper = styled.div`
width:calc(40% - 10px);
@media(max-width:648px){
    width:100%;
}
`;
const CardWrapper = styled.div`
display: flex;
justify-content:space-between;
margin-top: 20px;
@media(max-width:648px){
    flex-direction: column;
}
`;

const CardHeading = styled.div`
font-size:35px;
color: #fff;
font-weight:bold;
@media(max-width:767px){
    font-size:22px;
}
`;
const TextWrapper = styled.div`
position: absolute;
left:10px;
bottom: 10px;
`;
const CardText = styled.div`
color:#fff;
font-size: 20px;
@media(max-width:767px){
    font-size:14px;
}
`;

const Image = styled.img`
position: absolute;
bottom:100px;
right:0;
width:400px;
@media(max-width:767px){
    width:200px;
    bottom: 200px;
}
`;

const IconWrapper = styled.div`
width:40px;
height:40px;
border:1px solid #00a97c;
border-radius:50%;
display: flex;
align-items: center;
justify-content: center;
`;

const IconSection = styled.div`
display: flex;
align-items: right;
justify-content: right;
width:100%;
margin-top:30px;
`;

const Icon = styled.img`

`;
function MetaverseServices(props) {
    const navigate = useNavigate();
    return (
        <Root>
            <Container>
                <Line></Line>
                <Wrapper>
                    <Text>Industry Wide</Text>
                    <Heading>Metaverse Services </Heading>
                    <Para>A comprehensive set of cost-effective development services that pave the way for increased business transparency, security, automation, and efficiency.
                    </Para>
                    <ServicesSection>
                        <Div>
                            <LeftCardWrapper>
                                <Card bgImage={Cardbg1}>
                                    <TextWrapper>
                                        <TextWrapper>
                                            <CardHeading>Decentralized</CardHeading>
                                            <CardText>Platforms</CardText>
                                        </TextWrapper>
                                    </TextWrapper>
                                </Card>
                            </LeftCardWrapper>
                            <RightCardWrapper>
                                <Card bgImage={Cardbg2}>
                                    <TextWrapper>
                                        <TextWrapper>
                                            <CardHeading>Metaverse</CardHeading>
                                            <CardText>Applications</CardText>
                                        </TextWrapper>
                                    </TextWrapper>
                                </Card>
                            </RightCardWrapper>
                        </Div>
                        <Div>
                            <LeftCardWrapper>
                                <CardWrapper>
                                    <SmallCard bgImage={Cardbg3} bottom="20px">
                                        <TextWrapper>
                                            <CardHeading>3D Spaces</CardHeading>
                                            {/* <CardText>Industry Wide</CardText> */}
                                        </TextWrapper>
                                    </SmallCard>
                                    <SmallCard bgImage={Cardbg4}>
                                        <TextWrapper>
                                            <CardHeading>Virtual</CardHeading>
                                            <CardText>Events</CardText>
                                        </TextWrapper>
                                    </SmallCard>
                                </CardWrapper>
                                <CardWrapper>
                                    <Card bgImage={Cardbg6}>
                                        <TextWrapper>
                                            <CardHeading>Social Media</CardHeading>
                                            <CardText>Platforms</CardText>
                                        </TextWrapper>
                                    </Card>
                                </CardWrapper>
                            </LeftCardWrapper>
                            <RightCardWrapper>
                                <Card bgImage={Cardbg5} height="486px">
                                    <TextWrapper>
                                        <CardHeading>Gaming</CardHeading>
                                        <CardText>Industry Wide</CardText>
                                    </TextWrapper>
                                </Card>
                            </RightCardWrapper>
                        </Div>
                    </ServicesSection>
                    <IconSection>
                          <IconWrapper onClick={() =>{navigate('/contact_us')}}>
                              <Icon src={ArrowIcon}/>
                          </IconWrapper>
                    </IconSection>
                    <Image src={MetaverseImage} alt="Metaverse Game"/>
                </Wrapper>
            </Container>
        </Root>
    );
}

export default MetaverseServices;