import React from "react";
import ConnectMessage from "../../Component/ConnectMessage";
import BlockchainHeroSection from "./BlockchainHeroSection";
import BlockchainImageSection from "./BlockchainImageSection";
import BlockchainInfo from "./BlockChainInfo/BlockchainInfo";
import FeatureSection from "./FeatureSection";
import LetsWorkTogether from "./LetsWorkTogether";
import TechnologySection from "./TechnologySection";

const Blockchain = () => {
    return (
        <>
            <BlockchainHeroSection />
            <BlockchainImageSection />
            <BlockchainInfo />
            <FeatureSection />
            <TechnologySection />
            <LetsWorkTogether />
            <ConnectMessage />
        </>
    );
};

export default Blockchain;