import React from "react";
import styled from "styled-components";
import Affordablepricing from "../../Component/Images/Affordablepricingicon.png";
import experiencedteam from "../../Component/Images/Experiencesteam.png";
import ontimedelivery from "../../Component/Images/ontime.png";
import bestsupport from "../../Component/Images/bestsuppport.png";
import Slidericon from "../../Component/Images/sildericon.png";
import { Container } from "../../Component/styledComponents/styledComponents";

const Root = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  background-color: #fff;
  padding: 50px 0;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const TextWrapper = styled.h2`
  margin: 0;
  font-size: 4.375rem;
  line-height: 5rem;
  text-transform: capitalize;
  text-transform: capitalize;
  font-weight: 700;
  @media(max-width:1200px){
    font-size: 3rem;
  }
  /* @media(max-width:880px){
    width:60%;
  } */
`;

const TextBold1 = styled.text``;
const LeftWrapper = styled.div`
  width: 50%;
  background-color: #fff;
  align-items: center;
  display: flex;
  @media(max-width:880px){
    width:40%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightWrapper = styled.div`
  width: 50%;
  @media(max-width:880px){
    width:60%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ParaWrapper = styled.p`
  /* height: 91px; */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 157.69%;
  text-transform: capitalize;

`;

const Image = styled.img``;

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: black;

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const GridItem1 = styled.div`
  height: 15rem;
  background-color: #e5f4f0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
`;
const GridItem2 = styled.div`
  height: 15rem;
  width: 100%;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
`;
const GridItem3 = styled.div`
  height: 15rem;
  width: 100%;
  background-color: white;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
`;
const GridItem4 = styled.div`
  height: 15rem;
  width: 100%;
  background-color: #e5f4f0;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
`;

const InnerWrapper = styled.div`
  padding: 2.5rem 0rem 0rem 3rem;
`;

const TextWrapper2 = styled.text`
  color: #0b0b0b;
  font-weight: 400;
  font-size: 22px;
  line-height: 23px;
`;

const IconWrapper = styled.div`
  /* background-color: #fff; */
  background-color:${(p) => p.color ? '#e5f4f0' :'#fffff'};
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image2 = styled.img`
`;


function BrandsTengible(props) {
  return (
    <Root>
      <Container>
        <LeftWrapper>
          <div>
            <TextWrapper>
                We make Brands <br /> Tengible
             {" "}
            </TextWrapper>
            <ParaWrapper>
              Create And Control your Web identity With An Enviable Website
            </ParaWrapper>
          </div>
        </LeftWrapper>
        <RightWrapper>
          {/* <Line> </Line> */}
          <GridItem>
            <GridItem1>
              <InnerWrapper>
                <Image src={Affordablepricing} alt="Affordable Pricing" /> <br />
                <TextWrapper2>
                  {" "}
                  Affordable <br /> Pricing
                </TextWrapper2>
              </InnerWrapper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                  paddingRight: "20px",
                }}
              >
                <IconWrapper color="color">
                  <Image2 src={Slidericon} alt="IconImage" />
                </IconWrapper>
              </div>
            </GridItem1>

            <GridItem2>
              <InnerWrapper>
                <Image src={experiencedteam} alt="Experienced Team" />
                <br />
                <TextWrapper2>
                  {" "}
                  Experienced <br /> Team
                </TextWrapper2>
              </InnerWrapper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                  paddingRight: "20px",
                }}
              >
                <IconWrapper>
                  <Image2 src={Slidericon} alt="IconImage" />
                </IconWrapper>
              </div>
            </GridItem2>
            <GridItem3>
              <InnerWrapper>
                <Image src={ontimedelivery} alt="On Time Delivery" />
                <br />
                <TextWrapper2>
                  On-Time <br /> Delivery
                </TextWrapper2>
              </InnerWrapper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                  paddingRight: "20px",
                }}
              >
                <IconWrapper>
                  <Image2 src={Slidericon} alt="IconImage" />
                </IconWrapper>
              </div>
            </GridItem3>
            <GridItem4>
              <InnerWrapper>
                <Image src={bestsupport} alt="Support" />
                <br />
                <TextWrapper2>
                  The Best <br /> Support
                </TextWrapper2>
              </InnerWrapper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                  paddingRight: "20px",
                }}
              >
                <IconWrapper>
                  <Image2 src={Slidericon} alt="IconImage" />
                </IconWrapper>
              </div>
            </GridItem4>
          </GridItem>
        </RightWrapper>
      </Container>
    </Root>
  );
}

export default BrandsTengible;
