import React, { useState } from "react";
import Metaverse from "../../Component/Images/metaverse-banner-bg1.jpg";
import styled, { keyframes } from "styled-components";
import MetaverseGameImage from '../../Component/Images/metaverse-banner-boy.png';
import ArrowIcon from '../../Component/Images/arrow-right.png';
import { useNavigate } from "react-router-dom";

const animatedBackground = keyframes`
from { background-position: 0 0; }
to { background-position: 99999% 0; }


`;
export const animate = keyframes`
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100%{
    transform: rotate(-5deg);
  }

`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 40rem;
  background-image: url(${Metaverse});
  background-size: cover;
  background-position: 0px 0px;
  background-repeat: repeat-x;
  animation: ${animatedBackground} 99s linear infinite;
  display:flex;
  align-items:center;
  justify-content: right;
  padding:20px;
  position:relative;
  @media(max-width:1440px){
    animation: ${animatedBackground} 4000s linear infinite;
}
  @media(max-width:767px){
    height: 30rem;
    animation: ${animatedBackground} 4000s linear infinite;
}

`;
const DataWrapper = styled.div`
width:1160px;
margin:0 auto;
display:flex;
justify-content: center;
@media(max-width:767px){
  flex-direction: column;
}
`;
const Image = styled.img`
animation-timing-function: ease-in-out;
animation: ${animate} 5s infinite;
width:500px;
position: absolute;
    top: 60px;
@media(max-width:900px){
  width:340px;
  top:100px;
}
`;

const BoldText = styled.div`
font-weight:bolder;
font-size:90px;
@media(max-width:767px){
  font-size:60px;
}
`;

const Text = styled.div`
font-weight:bold;
font-size:40px;
margin-top:-15px;
@media(max-width:767px){
  font-size:30px;
}
`;

const TextWrapper = styled.div`
font-family: 'Satisfy',cursive;
color: #fff;
text-align: right;
position: absolute;
top: 50px;
right: 150px;
@media(max-width:767px){
  top: 20px;
    right: 40px;
}
`;

const Span = styled.span`
  color:#ffd609;
`;

const IconWrapper = styled.div`
font-size: 40px;
cursor: pointer;
`;

const Icon = styled.img`

`;
const MetaverseSection = () => {
  const [section,setSection] = useState('');
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <ImageWrapper>
           <DataWrapper>
               <Image src={MetaverseGameImage} alt="Metaverse Development" />
               <TextWrapper>
               <BoldText>
                    <Span>Meta</Span>verse
               </BoldText>
               <Text>
                  Development
               </Text>
               <IconWrapper onClick={() => navigate('/metaverse_development')}><Icon src={ArrowIcon}/></IconWrapper>
               </TextWrapper>
           </DataWrapper>
      </ImageWrapper>
    </React.Fragment>
  );
};

export default MetaverseSection;
