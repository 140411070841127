import React from 'react';
import ExchangeCryptoHeroSection from './ExchangeCryptoHeroSection';
import styled from 'styled-components';
import InfoSection from './InfoSection';
import WhySection from './WhySection';
import MessageDropSection from '../Nfts/MessageDropSection';
import CardSection from './CardSection';
import CryptosollutionSection from './CryptosollutionSection';
import TechnologySection from './TechnologySection';
import ConnectMessage from '../../Component/ConnectMessage';
import LetsWorkTogether from '../Blockchian/LetsWorkTogether';
const Root = styled.div`

`;
function ExchangeCrypto(props) {
    return (
        <Root>
           <ExchangeCryptoHeroSection/>
           <InfoSection/>
           <WhySection/>
           <MessageDropSection/>
           <CardSection/>
           <CryptosollutionSection/>
           <TechnologySection/>
           <LetsWorkTogether />
           <ConnectMessage />
        </Root>
    );
}

export default ExchangeCrypto;