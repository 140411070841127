import React, { useState } from 'react';
import { Container } from '../../../Component/styledComponents/styledComponents';
import BlockchainDeployment from './BlockchainDeployment';
import EthereumDevelopment from './EthereumDevelopment';
import HyperledgerFabric from './HyperledgerFabric';
import NonFungible from './NonFungible';
import BlockChainIcon from '../../../Component/Images/blockchainicon.png';
import HyperIcon from '../../../Component/Images/hyper.png';
import MobileDevelopmentIcon from '../../../Component/Images/mobile-app-development-icon.png';
import WebDeveloperIcon from '../../../Component/Images/web-development-icon.png';
import { BoldText, ButtonWrapper, Div, HeadingText, Image, Info, InfoWrapper, MobileViewLinkWrapper, Para, Root, Text, Wrapper } from '../../../Component/styledComponents/ServicesStyledComponents';
import LinkCard from '../../../Component/LinkCard';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const H2 = styled.h2`
 margin: 0;
 font-size:50px;
 font-weight:bold;
 margin-bottom:10px;
 text-align:center;
@media(max-width:767px){
  font-size:30px;
}
`;

function BlockchainInfo() {
    const [servic, setService] = useState("Ethereum Development");
    const navigate = useNavigate();
    return (
        <Root>
            <Container>
                <Wrapper>
                    {/* <HeadingText>Blockchain Services We Offer</HeadingText> */}
                    <H2>Blockchain Services We Offer</H2>
                    <Para>
                        Full suite of efficient development services paving the way to business 
                        transparency, security automation and efficiency.
                    </Para>
              
                <InfoWrapper>
                    <Div>
                        <ButtonWrapper
                            selected={servic === 'Ethereum Development'}
                            onClick={() => setService("Ethereum Development")}
                        >
                            <Image src={BlockChainIcon} />
                            <Text>Ethereum <br/> Development </Text>

                        </ButtonWrapper>
                        <ButtonWrapper
                            selected={servic === 'Hyperledger Fabric'}
                            onClick={() => setService("Hyperledger Fabric")}
                        >
                            <Image src={HyperIcon} />
                            <Text> Hyperledger <br /> Fabric</Text>
                        </ButtonWrapper>
                        <ButtonWrapper
                            selected={servic === 'Non Fungible'}
                            onClick={() => setService("Non Fungible")}
                        >
                            <Image src={MobileDevelopmentIcon} />
                            <Text>Non <br />Fungible</Text>

                        </ButtonWrapper>
                        <ButtonWrapper
                            selected={servic === 'Blockchain Deployment'}
                            onClick={() => setService("Blockchain Deployment")}
                        >
                            <Image src={WebDeveloperIcon} />
                            <Text>Blockchain <br /> Deployment</Text>

                        </ButtonWrapper>
                    </Div>
                    <Info>
                        {servic === "Ethereum Development" && <EthereumDevelopment />}
                        {servic === "Hyperledger Fabric" && <HyperledgerFabric />}
                        {servic === "Non Fungible" && <NonFungible />}
                        {servic === "Blockchain Deployment" && <BlockchainDeployment />}
                    </Info>

                </InfoWrapper>
                {
                <MobileViewLinkWrapper>
                  <LinkCard
                   image={BlockChainIcon} 
                   text="Ethereum Development"
                   path="/ethereum"
                   />
                  <LinkCard 
                   image={HyperIcon} 
                   text="Hyperledger Fabric"
                   path="/contact_us"
                   />
                  <LinkCard 
                  image={MobileDevelopmentIcon} 
                  text="Non Fungible"
                  path="/nfts"
                  />
                  <LinkCard 
                  image={WebDeveloperIcon} 
                  text="Blockchain Deployment"
                  path="/contact_us"
                  />
                 
                </MobileViewLinkWrapper>
              }
                </Wrapper>
            </Container>
        </Root>
    );
}

export default BlockchainInfo;