import React from "react";
import styled from "styled-components";
import BannerImage from '../../Component/Images/App-Development-banner1.gif';
import Arrow from '../../Component/Images/green-arrow.png';
import { useNavigate } from 'react-router-dom';
import { Container } from "../../Component/styledComponents/styledComponents";

const Root = styled.div`
  height: 38rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top:70px;
  @media(max-width:767px){
    flex-direction: column;
    height: 100%;
    padding:100px 0 30px 0;
  }
`;
const TextWrapper = styled.h1`
  margin: 0;
  font-size: 3.5rem;
  line-height: 4.5rem;
  text-transform: capitalize;
  font-weight: 700;

  @media(max-width:767px){
    font-size: 3rem;
    line-height: 3.5rem;
  }
`;

const Heading= styled.div`
`;
const LeftWrapper = styled.div`
width:45%;
@media(max-width:767px){
    width: 100%;
  }
`;

const ParaWrapper = styled.p`
  width: 35rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 157.69%;
  @media(max-width:1069px){
    width:100%;
  }
`;

const RightWrapper = styled.div`
width:55%;
text-align: right;
height:100%;
max-height: 100%;
@media(max-width:767px){
   display: none;
}
`;
const Image = styled.img`
width: 100%;
height: 100%;
`;
const ArrowIconWrapper = styled.div`
`;
const ArrowIcon = styled.img`
cursor: pointer;
`;

// const H1 = styled.h1`
//   margin: 0;
//   font-size: 3.5rem;
// `;
function AppDevelopmentHeroSection() {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
      <LeftWrapper>
        
        <TextWrapper>
          Mobile App<br/>
          Development<br/>
          Services 
        </TextWrapper>
       
        <ParaWrapper>
        We at metaspacelab provide services of end-to-end mobile app development.   
        </ParaWrapper>
        <ArrowIconWrapper>
             <ArrowIcon src={Arrow} onClick={() => {navigate('/contact_us')}}/>
        </ArrowIconWrapper>
      </LeftWrapper>
      <RightWrapper>
           <Image src={BannerImage}/>
      </RightWrapper> 
      </Container>
    </Root>
  );
}

export default AppDevelopmentHeroSection;
