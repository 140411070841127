import React from "react";
import styled from "styled-components";
import Image1 from '../../Component/Images/demo-img.jpg';
import Image2 from '../../Component/Images/space-gen.jpg';
import Image3 from '../../Component/Images/art-gen.jpg';
import Image4 from '../../Component/Images/ecommerce-gen.jpg';
import Image5 from '../../Component/Images/event-gen.jpg';
import Image6 from '../../Component/Images/fashion-gen.jpg';
import GridCard from "../../Component/GridCard";
import { GridItem, GridWrapper, Root, TextWrapper, Wrapper } from "../../Component/styledComponents/GridColumnstyledcomponents";

const H4 = styled.h4`
 margin: 0;

`;
function GameWeServe() {
  return (
    <Root>
        <Wrapper>
      <TextWrapper>
        <H4>Genres of Game<br/>
         We Serve</H4>
      </TextWrapper>
      <GridWrapper>
        <GridItem>
          <GridCard image={Image1} text="MOBA"/>
          <GridCard image={Image2} text="Fashion"/>
          <GridCard image={Image3} text="Simulation Breeding"/>
          <GridCard image={Image4} text="Sports"/>
          <GridCard image={Image5} text="Action"/>
          <GridCard image={Image6} text="Racing"/>
        </GridItem>
      </GridWrapper>
      </Wrapper>
      
    </Root>
  );
}

export default GameWeServe;
