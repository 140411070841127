import React from "react";
import Unitybck from "../../Component/Images/UnityBackgrounf.jpg";
import Unityback2 from "../../Component/Images/Unityback2.png";
import flyingimg from "../../Component/Images/flying.jpg";
import mobile from "../../Component/Images/mobilegame.jpg";
import metaverse from "../../Component/Images/metaversenft.jpg";
import character from "../../Component/Images/character.jpg";
import arrow from "../../Component/Images/round-text-arrow.png";
import styled from "styled-components";
import { Container } from "../../Component/styledComponents/styledComponents";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  height: 100%;
  padding-top:30px;
  position: relative;
  padding-bottom:80px;
  @media(max-width:767px){
    padding-bottom:30px;
  }
`;
const Wrapper = styled.div`
 width:100%;
 display: flex;
 flex-direction:column;
 justify-content: center;
`;
const TextWrapper = styled.div`
  text-align: center;
  margin-top: 4rem;
  @media(max-width:767px){
    margin-top:2rem;
  }
`;
const FirsTextWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  color: #131313;
`;
const TextServices = styled.h2`
  margin: 0;
  font-family: Roboto;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: #131313;
  @media(max-width:520px){
    font-size:30px;
  }
`;
const ParaWrapper = styled.div`
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #0f0f0f;
  /* padding: 1rem 0rem 0rem 22rem; */
`;

const Imagecontainer = styled.div`
  margin-top: 4rem;
  @media(max-width:767px){
    margin-top:2rem;
  }
`;
const ImageWrapper1 = styled.div`
  display: flex;
  justify-content: center;
  width:100%;
  @media(max-width:767px){
    flex-direction: column;
  }
`;
const ImageWrapper2 = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width:100%;
  @media(max-width:767px){
    flex-direction: column;
    margin-top: 0rem;
  }
`;
const FirstImg = styled.div`
  margin: 0rem 1.7rem 0rem 0rem;
   box-shadow: -2.6px 1.5px 32px 0 rgba(0, 0, 0, 0.5);
   position: relative;
   /* background-color:#000; */
   width:35%;
   height:13rem;
   background-image:url(${Unitybck});
   background-repeat:no-repeat;
   background-size:100% 100%;
   background-position:center;
   @media(max-width:767px){
    width:100%;
    margin-bottom: 20px;
    background-size:cover;
  }
`;
const SecondImg = styled.div`
  margin: 0rem 1.7rem 0rem 0rem;
   box-shadow: -2.6px 1.5px 32px 0 rgba(0, 0, 0, 0.5);
   position: relative;
   background-color:#000;
   width:40%;
   height:13rem;
   background-image:url(${flyingimg});
   background-repeat:no-repeat;
   background-size:100% 100%;
   background-position:center;
   @media(max-width:767px){
    width:100%;
    margin-bottom: 20px;
    background-size:cover;
  }
`;
const ThirdImg = styled.div`
   box-shadow: -2.6px 1.5px 32px 0 rgba(0, 0, 0, 0.5);
   position: relative;
   background-color:#000;
   width:25%;
   height:13rem;
   background-image:url(${mobile});
   background-repeat:no-repeat;
   background-size:100% 100%;
   background-position:center;
   @media(max-width:767px){
    width:100%;
    margin-bottom: 20px;
    background-size:cover;
  }
`;
const SecFirstImg = styled.div`
  margin: 0rem 1.7rem 0rem 0rem;
   box-shadow: -2.6px 1.5px 32px 0 rgba(0, 0, 0, 0.5);
   position: relative;
   background-color:#000;
   width:60%;
   height:13rem;
   background-image:url(${metaverse});
   background-repeat:no-repeat;
   background-size:cover;
   background-position:top left;
   @media(max-width:767px){
    width:100%;
    margin-bottom: 20px;
    background-size:cover; 
  }
`;
const SecsecImg = styled.div`
   box-shadow: -2.6px 1.5px 32px 0 rgba(0, 0, 0, 0.5);
   position: relative;
   width:40%;
   height:13rem;
   background-image:url(${character});
   background-repeat:no-repeat;
   background-size:100% 100%;
   background-position:center;
   @media(max-width:767px){
    width:100%;
    margin-bottom: 20px;
    background-size:cover;
  }
`;

const UnityTextBold = styled.div`
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
  @media(max-width:960px){
    font-size: 2rem;
  }
`;
const UnityTextsmall = styled.div`
  color: white;
  font-size: 1.08rem;
  font-weight: 400;
`;

const IconWrapper = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  min-width:2.5rem;
  min-height:2.5rem;
  border-radius: 100%;
  border: 1px solid #00a97c;
  /* margin: 1rem 0rem 0rem 71rem; */
`;
const Icon = styled.img`
  margin: 0.6rem 0rem 0rem 0.4rem;
`;
const Line = styled.div`
  width: 50%;
  height: 1rem;
  background-color: #00a97c;
  position: absolute;
  right: 0;
  top:0;
`;
const InfoWrapper = styled.div`
  position: absolute;
  bottom:20px;
  left:20px;
  display: flex;
  flex-direction: column;
  width:100%;
`;

const UnityImage = styled.img`
position: absolute;
top:-20px;
right:0;
`;
const Div = styled.div`
 width:100%;
 display: flex;
 justify-content:right;
 margin-top:30px;
 @media(max-width:767px){
  margin-top:0px;
 }
`;
function GameServices() {
  const navigate = useNavigate();
  return (
    <Root>
      <Line></Line>
      <Container>
      <Wrapper>
        <TextWrapper>
          <FirsTextWrapper>Industry Wide</FirsTextWrapper>
          <TextServices>Game Development Services</TextServices>
          <ParaWrapper>
            Full suite of efficient development services paving the way to
            business transparency, security, automation and efficiency.
          </ParaWrapper>
        </TextWrapper>
        <Imagecontainer>
          <ImageWrapper1>
            <FirstImg>
              <UnityImage src={Unityback2} alt="Unity 3D Game Development"/> 
               <InfoWrapper>
              <UnityTextBold>Unity 3D </UnityTextBold>
              <UnityTextsmall>Game Development</UnityTextsmall>
              </InfoWrapper>
            </FirstImg>
            <SecondImg>
              <InfoWrapper>
              <UnityTextBold>Unreal Engine</UnityTextBold>
              <UnityTextsmall>Game Development</UnityTextsmall>
              </InfoWrapper>
            </SecondImg>
            <ThirdImg>
              <InfoWrapper>
              <UnityTextBold>mobile </UnityTextBold>
              <UnityTextsmall>Game Development</UnityTextsmall>
              </InfoWrapper>
            </ThirdImg>
          </ImageWrapper1>

          <ImageWrapper2>
            <SecFirstImg>
              <InfoWrapper>
              <UnityTextBold>Metaverse NFT </UnityTextBold>
              <UnityTextsmall>Game Development</UnityTextsmall>
              </InfoWrapper>
            </SecFirstImg>
            <SecsecImg>
              <InfoWrapper>
              <UnityTextBold>Character 3D </UnityTextBold>
              <UnityTextsmall>Creation</UnityTextsmall>
              </InfoWrapper>
            </SecsecImg>
          </ImageWrapper2>
        </Imagecontainer>
        <Div>
        <IconWrapper onClick={() => navigate('/contact_us')}>
          <Icon src={arrow} alt="arrow" />
        </IconWrapper>
        </Div>
      </Wrapper>
      </Container>
    </Root>
  );
}

export default GameServices;
