import React from 'react';
import styled from 'styled-components';
import LetsWorkTogether from '../Blockchian/LetsWorkTogether';
import TrandingTechnologies from '../AboutUs/TrandingTechnologies';
import AppDevelopmentHeroSection from './AppDevelopmentHeroSection';
import AwardSection from './AwardSection';
import AppInfoSection from './AppInfoSection';
import AppImageSection from './AppImageSection';
import ConnectMessage from '../../Component/ConnectMessage';
const Root = styled.div`
`;

function AppDevelopment() {
    return (
        <Root>
            <AppDevelopmentHeroSection/> 
          <AwardSection/>
           <AppImageSection/>
            <AppInfoSection/>
            <TrandingTechnologies />
            <LetsWorkTogether />
            <ConnectMessage />
        </Root>
    );
}

export default AppDevelopment;