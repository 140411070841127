import React from 'react';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';
import BgImage1 from '../../Component/Images/service1.jpg';
import BgImage2 from '../../Component/Images/ethereumservice2.jpg';
import BgImage3 from '../../Component/Images/ethereumService3.jpg';
import BgImage4 from '../../Component/Images/ethereumService4.jpg';
import BgImage5 from '../../Component/Images/ethereumService5.jpg';
import BgImage6 from '../../Component/Images/ethereumService6.jpg';
import BgImage7 from '../../Component/Images/ethereumService7.jpg';
import BgImage8 from '../../Component/Images/ethereumService8.jpg';

const Wrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
width:100%;
`;
const Text = styled.div`
color:#131313;
font-size: 18px;
font-weight: bold;
`;
const Root = styled.div`
/* background-color:#fff; */
padding: 80px 0;
@media(max-width:767px){
    padding: 30px 0;
}
`;
const HeadingText = styled.h2`
margin: 0;
font-size: 45px;
font-weight: bold;
color:#000;
margin-bottom:20px;
text-align: center;
@media(max-width:542px){
    font-size: 35px;
}
`;
const Heading = styled.div`
font-size: 45px;
font-weight: bold;
color:#fff;
@media(max-width:961px){
    font-size: 35px;
}
`;
const Para = styled.div`
font-size: 16px;
font-weight: 500;
margin-bottom:50px;
text-align: center;
`;
const SectionWrapper = styled.div`
display: flex;
width:100%;
margin-bottom:20px;
@media(max-width:767px){
    flex-direction: column;
    margin-bottom:0px;
}
`;
const Card = styled.div`
${(p) => p.width && `
width:${p.width};
@media(max-width:767px){
    width:100%;
    min-width:100%;
    margin-bottom:20px;
}
`};
${(p) => p.image &&`
background-image:url(${p.image});
background-repeat:no-repeat;
background-size:cover
`};
${(p) => p.left && `
 margin-left:20px;
 @media(max-width:767px){
    margin-left:0px;
}
`};
${(p) => p.right && `
 margin-right:20px;
 @media(max-width:767px){
    margin-right:0px;
}
`};
padding:80px 30px 30px 30px;
`;
const Div = styled.div`
color:#fff;
`;
function EthereumServices(props) {
    return (
        <Root>
            <Container>
                <Wrapper>
                    <Text>Top-Notch</Text>
                    <HeadingText>Ethereum Development Services</HeadingText>
                    <Para>We help reap the benefits of Ethereum for your business with our scalable
                        Ethereum development services.
                    </Para>
                    <SectionWrapper>
                        <Card width="35%" right="right" image={BgImage2}>
                           <Heading>Smart Contract</Heading>
                           <Div>Development </Div>
                        </Card>
                        <Card width="45%" image={BgImage3} right="right">
                        <Heading>Decentralized</Heading>
                        <Div>Exchange Development </Div>
                        </Card>
                        <Card width="20%" image={BgImage4}>
                        <Heading>DApp</Heading>
                        <Div>Development</Div>
                        </Card>
                    </SectionWrapper>
                    <SectionWrapper>
                        <Card width="60%" image={BgImage5} right="right">
                        <Heading>ICO/STO </Heading>
                        <Div>Development</Div>
                        </Card>
                        <Card width="40%" image={BgImage6}>
                        <Heading>Token Bridge</Heading>
                        <Div>Development</Div>
                        </Card>
                    </SectionWrapper>
                    <SectionWrapper>
                        <Card width="40%" image={BgImage7} right="right">
                        <Heading>Wallets</Heading>
                        <Div></Div>
                        </Card>
                        <Card width="35%" image={BgImage8} right="right">
                        <Heading>NFT Platform </Heading>
                        <Div>Development</Div>
                        </Card>
                        <Card width="30%" image={BgImage1}>
                        <Heading>Governance</Heading>
                        <Div>Token Development </Div>
                        </Card>
                    </SectionWrapper>
                </Wrapper>
            </Container>
        </Root>
    );
}

export default EthereumServices;