import React from 'react';
import ConnectMessage from '../../Component/ConnectMessage';
import LetsWorkTogether from '../Blockchian/LetsWorkTogether';
import OurWorkHeroSection from './OurWorkHeroSection';
import ProjectSection from './ProjectSection/ProjectSection';
import SollutionSection from './SollutionSection';

function OurWork(props) {
    return (
        <div>
            <OurWorkHeroSection/>
            <ProjectSection/>
            <SollutionSection/>
            <LetsWorkTogether/>
            <ConnectMessage/>
        </div>
    );
}

export default OurWork;