import React, {useState } from "react";
import './App.css';
import Navbar from "./Component/Navbar";
import Footer from "./Component/Footer";
import Home from "./Pages/Home/Home";
import { Route, Routes} from "react-router-dom";
import  * as Router from './Routes';
import AboutUs from "./Pages/AboutUs/AboutUs";
import Game from "./Pages/Game/Game";
import Blockchain from "./Pages/Blockchian/Blockchain";
import OurWork from "./Pages/OurWork/OurWork";
import WebDevelopment from "./Pages/WebDevelopment/WebDevelopment";
import AppDevelopment from "./Pages/AppDevelopment/AppDevelopment";
import Metaverse from "./Pages/Metaverse/Metaverse";
import ContactUs from "./Pages/ContactUs/ContactUs";
import TermsAndCondition from "./Pages/TermsAndContion/TermsAndCondition";
import PrivacyAndPolicy from "./Pages/PrivacyAndPolicy/PrivacyAndPolicy";
import Ethereum from "./Pages/Ethereum/Ethereum";
import CryptoCurrency from "./Pages/CryptoCurrency/CryptoCurrency";
import NftMarketPlace from "./Pages/NftMarketPlace/NftMarketPlace";
import Nft from "./Pages/Nfts/Nft";
import ExchangeCrypto from "./Pages/ExchangeCrypto/ExchangeCrypto";
import ScrollToTop from "./Component/ScrollToTop ";


function App() {
  const [showDropDown,setShowDropDown]= useState(false);
  
  return (
      <div onClick={() => setShowDropDown(false)}>  
          <Navbar showDropDown={showDropDown} setShowDropDown={setShowDropDown}/>
          <ScrollToTop />
          <Routes>
            <>
           <Route path={Router.HOME} element={<Home/>}/>
           <Route path={Router.ABOUTUS} element={<AboutUs/>}/>
           <Route path={Router.CONTACTUS} element={<ContactUs/>}/>
           <Route path={Router.GAME} element={<Game/>}/>
           <Route path={Router.BLOCKCHAIN} element={<Blockchain/>}/>
           <Route path={Router.WEBDEVELOPEMENT} element={<WebDevelopment/>}/>
           <Route path={Router.APPDEVELOPMENT} element={<AppDevelopment/>} />
           <Route path={Router.OURWORK} element={<OurWork/>}/>
           <Route path={Router.METAVERSEDEVELOPMENT} element={<Metaverse/>}/>
           <Route path={Router.TERMSANDCONDITIONS} element={<TermsAndCondition/>}/>
           <Route path={Router.PRIVACYANDPOLICY} element={<PrivacyAndPolicy/>}/>
           <Route path={Router.ETHEREUM} element={<Ethereum/>}/>
           <Route path={Router.CRYPTOCURRENCY} element={<CryptoCurrency/>}/>
           <Route path={Router.NFTMARKETPLACE} element={<NftMarketPlace/>} />
           <Route path={Router.NFT} element={<Nft/>}/>
           <Route path={Router.EXCHANGECRYPTO} element={<ExchangeCrypto/>}/>
           <Route path={Router.DEFAULT} element={<Home/>}/>
           </>
          </Routes>
          <Footer/>
      </div>
  );
}

export default App;
