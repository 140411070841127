import React from 'react';
import { Button } from '../../../Component/styledComponents/styledComponents';
import Blockchain from '../../../Component/Images/blockchain.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Card = styled.div`
background-color: #282828;
border-radius:10px;
padding:40px 90px;
`;
const Heading = styled.div`
font-size:24px;
color:#fff;
font-weight:bold;
margin-bottom:20px;
`;
const Text = styled.div`
color:#9b9b9b ;
font-size:18px;
`;
const IconSection = styled.div`
margin-top:40px;
display:flex;
align-items: center;
width:100%;
justify-content: space-between;
`;
const IconWrapper = styled.div`
text-align:center;
`;
const Icon = styled.img`
/* width:32px; */
`;
const Subtitle = styled.div`
color:#9b9b9b ;
margin-top:5px;
`;
function BlockchainDeployment(props) {
  const navigate = useNavigate();
    return (
        <div>
      <Card>
        <Heading>
        Blockchain deployment 
        </Heading>
        <Text>
        We make click-button simple to deploy the recognized framework of the blockchain network by managing the performance of your nodes with advanced analytics and monitoring.
        </Text>
        <IconSection>
             <IconWrapper>
                <Icon src={Blockchain} alt="Blockchain development" />
             </IconWrapper>
        </IconSection>
        <Button
        border="#9b9b9b"
        color="#fff"
        top="12px"
        right="30px"
        left="30px"
        bottom="12px"
        radius="30px"
        mtop="40px"
        onClick={() => navigate('/contact_us')}
        >
          EXPLORE
        </Button>
      </Card>
    </div>
    );
}

export default BlockchainDeployment;