import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  Container,
} from "../../Component/styledComponents/styledComponents";
import axios from "axios";
import SucessPopup from "../../Component/SucessPopup";
import PanelsIconEmail from "../../Component/Images/panels__icon_email.svg";
import PanelsIconSupport from "../../Component/Images/panels__icon_support.svg";
import EmailAlreadyExist from "../../Component/EmailAlreadyExist";

const Root = styled.div`
  display: flex;
  padding: 150px 0 80px 0;
  @media (max-width: 767px) {
    width: 100%;
    padding: 100px 0 30px 0;
  }
`;
const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Text = styled.text`
  font-weight: bold;
`;
const SmallHeading = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  color: #00a97c;
`;

const RightSideWrapper = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  min-width: 100%;
  height: 2.8rem;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  padding: 10px;
`;
const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid #dbdbdb;
  height: 2.8rem;
  padding: 10px;
`;
const Form = styled.form`
  background-color: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: -6.1px 3.5px 18px 0 rgba(0, 0, 0, 0.16);
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

// const ErrorMessage = styled.div`
//   color: red;
//   padding-left: 20px;
//   padding-top: 10px;
// `;

const Heading = styled.h1`
  color: #000000;
  font-size: 55px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: left;
  width: 100%;
  @media (max-width: 925px) {
    font-size: 35px;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
`;
const IconSection = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;
const Icon = styled.img`
  width: 90px;
  margin-bottom: 15px;
  @media (max-width: 500px) {
    width: 60px;
  }
`;

const InputWrapper = styled.div`
  width: calc(50% - 10px);
  margin-bottom: 20px;
  @media (max-width: 925px) {
    width: 100%;
  }
`;
const InputWrapper2 = styled.div`
  /* width: calc(50% - 10px); */
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 925px) {
    width: 100%;
  }
`;
const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 925px) {
    flex-direction: column;
  }
`;
const Label = styled.div`
  margin-bottom: 10px;
  color: #4f4f4f;
  font-size: 14px;
  display: flex;
  justify-content: left;
  width: 100%;
`;

const FormHeadText = styled.div`
  color: #202020;
  font-weight: bold;
  font-size: 18px;
`;
const HeadText = styled.div`
  color: #4f4f4f;
  font-size: 16px;
  margin: 15px 0 30px 0;
  width: 100%;
  text-align: left;
`;

const H1 = styled.h1`
  margin: 0;
  font-size: 55px;
`;
const Select = styled.select`
  width: 100%;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  padding: 14px 10px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
    /* padding: 5px 0; */
    border-radius: 10px;
  }
`;
const Header = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [profession, setProfession] = useState("");
  const [select, setSelect] = useState("");
  // const [error, setError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [sucessResponse, setSucessResponse] = useState();
  const [emailError, setEmailError] = useState();
  const [popup, setPopup] = useState(false);

  const handleSubmit=()=>{
    
    var myHeaders = new Headers();
myHeaders.append("Cookie", "941ef25d4b=9f5e179897728c6e46017ac9db081fd6; JSESSIONID=2F57E46A8EADE1426C877597033B6577; _zcsr_tmp=4b344ce5-46ef-42f7-814b-7299f117180d; crmcsr=4b344ce5-46ef-42f7-814b-7299f117180d");

var formdata = new FormData();
formdata.append("xnQsjsdp", "b25cb8f8ef33e0e98888bcba5fc285e2210c730f5dc60f3f3c6df96a49f13444");
formdata.append("zc_gad", "");
formdata.append("xmIwtLD", "748b2136df38469732eb15207febec809ef8f1a6de5729c2f5a632e592e50c16");
formdata.append("actionType", "TGVhZHM=");
formdata.append("returnURL", "https&#x3a;&#x2f;&#x2f;bastionex.net&#x2f;contact_us");
formdata.append("Last Name", name);
formdata.append("Email", email);
formdata.append("Mobile", phone);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch("https://crm.zoho.in/crm/WebToLeadForm", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
  }
  const SubmitHandler = (e) => {
    // setPopup(true)
    // setShowPopup(true);
    // console.log(name, email, phone, message, companyname, select, profession)
    e.preventDefault(); 
    if (
      name === "" ||
      email === "" ||
      message === "" ||
      phone === "" ||
      companyname === "" ||
      profession === "" ||
      select === "" 
    ) {
     
      return;
    }
    axios({
      method: "post",
      url: "https://api.bastionex.net/postuserdata",
      data: {
        name: name,
        email: email,
        number: phone,
        message: message,
        companyName: companyname,
        designation: profession,
        companySize: select,
      },
    }).then(function (response) {
      console.log(response.data,"hhhhhhhhhh");
      setShowPopup(true);
      setSucessResponse(response.data.success);
      setResponseMessage(response.data.message);
      // handleSubmit();
    })
    .catch((err)=>{
      // console.log(err.response.data.error)
      setResponseMessage();
      setEmailError(err.response.data.error)
      if(err.response.data.error === "Email Already exist"){
        setPopup(true)
      }else{
        setShowPopup(true);
      }
    });
  
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setCompanyname("");
    setProfession("");
    setSelect("");
  };
  return (
    <Root>
      {showPopup && (
        <SucessPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          responseMessage={responseMessage}
          sucessResponse={sucessResponse}
          // popup={popup}
          // emailError={emailError}
        />
      )}
      {popup && (
        <EmailAlreadyExist
          responseMessage={responseMessage}
          sucessResponse={sucessResponse}
          showPopup={popup}
          // emailError={emailError}
          setShowPopup={setPopup}
        />
      )}
      <Container>
        <LeftSideWrapper>
          <SmallHeading>Contact Us for Consultation Call</SmallHeading>

          <Heading>
            <H1>Have a question?</H1>
          </Heading>
          <HeadText>We are here to rescue!</HeadText>

          <IconSection>
            <IconWrapper>
              <Icon src={PanelsIconEmail} alt="Contact metaspacelab" />
              <Text>info@metaspacelab.io</Text>
            </IconWrapper>
            <IconWrapper>
              <Icon src={PanelsIconSupport} alt="Contact metaspacelab" />
              <Text>+971 56 595 4252</Text>
            </IconWrapper>
          </IconSection>
        </LeftSideWrapper>
        <RightSideWrapper>
          <Form onSubmit={SubmitHandler}>
            <FormHeadText>Contact Us for Consultation Call</FormHeadText>
            <HeadText>
              Please fill the form below and someone from metaspacelab will respond
              soon. Thank You!
            </HeadText>
            <FormWrapper>
              <InputWrapper>
                <Label>Full Name*</Label>
                <Input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Email*</Label>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </InputWrapper>
            </FormWrapper>
            <FormWrapper>
              <InputWrapper>
                <Label>Contact Number*</Label>
                <Input
                  type="number"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  min="10"
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Company Name*</Label>
                <Input
                  type="text"
                  placeholder="Company Name"
                  value={companyname}
                  onChange={(e) => {
                    setCompanyname(e.target.value);
                  }}
                  required
                />
              </InputWrapper>
            </FormWrapper>
            <FormWrapper>
              <InputWrapper>
                <Label>Company Size*</Label>
                <Select onChange={(e) =>{
                  setSelect(e.target.value);
                }}
                  value={select}
                  required
                  >
                  <option value="" hidden>
                    Choose an Option
                  </option>
                  <option value="10">0-10 Employees</option>
                  <option value="25">11-25 Employees</option>
                  <option value="50">26-50 Employees</option>
                  <option value="100">51-100 Employees</option>
                  <option value="150">100+ Employees</option>
                </Select>
              </InputWrapper>
              <InputWrapper>
                <Label>Designation*</Label>
                <Input
                  type="text"
                  placeholder="Designation"
                  value={profession}
                  onChange={(e) => {
                    setProfession(e.target.value);
                  }}
                  required
                />
              </InputWrapper>
            </FormWrapper>
            <FormWrapper>
              <InputWrapper2>
                <Label>What are you looking for ?</Label>
                <TextArea
                  type="text"
                  placeholder="Message...."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </InputWrapper2>
            </FormWrapper>
            {/* {error && <ErrorMessage>All Field are required.</ErrorMessage>} */}
            <Button
              height="2.6rem"
              color="white"
              bgcolor="#30B894"
              font-weight="700"
              width="100%"
              type="submit"
            >
              SUBMIT
            </Button>
          </Form>
        </RightSideWrapper>
      </Container>
    </Root>
  );
};

export default Header;
