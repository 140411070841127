import React from 'react';
import Herosection from './Herosection';
import Aboutsec2 from './Aboutsec2';
import OurValues from './OurValues';
import TrandingTechnologies from './TrandingTechnologies';
import ServicesAndProject from './ServicesAndProject';
import HiringSection from './HiringSection';
import ConnectMessage from '../../Component/ConnectMessage';
import InfoSection from './InfoSection';
import Teams from './Teams';
const AboutUs = () => {
    return (
        <div>           
            <Herosection />
            <Aboutsec2 />
            <OurValues />
            <Teams />
           {/* <TrandingTechnologies /> */}
            {/* <ServicesAndProject /> */}
            <InfoSection/>
            <HiringSection />
            <ConnectMessage />  
        </div>
    );
};

export default AboutUs;

