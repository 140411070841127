import React from "react";
import styled from "styled-components";
import aboutbanner from "../../Component/Images/about3.png";
import { Container } from "../../Component/styledComponents/styledComponents";

const Root = styled.div`
  height: 100%;
  width: 100%;
  background-color: #F5F5F5;
`;
const Text = styled.div`
  text-align: center;
  font-weight: 800;
  font-size: 2rem;
  margin-top: 80px;
  padding-bottom: 60px;
  width: 100%;
`;

const ParagraphWrapper = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 0rem;
  font-family: "Roboto";
  @media (max-width: 425px) {
    font-size: 1.3rem;
  }
`;

const H2 = styled.h2`
  font-weight: bold;
  margin-bottom: 25px;

  @media (max-width: 560px) {
    width: 100%;
    font-size: 2rem;
  }
  @media (max-width: 425px) {
    width: 100%;
    font-size: 1.5rem;
  }
`;
const MainDivWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin: -2rem 0rem 0rem 1rem;
  }
`;
const Image = styled.img`
  width: 90%;
  object-fit: contain;
  padding-top: 0.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;
const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: right;
  justify-content: right;
`;
const LeftsideWrapper = styled.div`
  font-family: roboto;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: start;
  width: 50%;
  margin-top: 80px;
  display: flex;
  @media (max-width: 1024px) {
    margin-top: 3.8rem;
  }
  @media (max-width: 768px) {
    width: auto;

  }
`;
const ListSection = styled.div``;

const RightsideWrapper = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ListWrapper = styled.div`
  margin-bottom: 30px;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  
`;
const PWrapper = styled.div`
  opacity: 0;
  width: auto;
  visibility: hidden;
  display: none;
  font-size: 16px;
  /* margin-left: 134px; */
  color: #343434;
  margin-top: 10px;
  transition: visibility 2s linear, opacity 2s linear;
  ${ListWrapper}:hover & {
    opacity: 1;
    visibility: visible;
    display: flex;
  }
  @media (max-width: 768px) {
    opacity: 1;
    visibility: visible;
    display: flex;
    margin-left: 0px;
    /* width: 100%; */
    /* padding: 8px 10px 10px 10px; */
    padding: 0rem 0rem 0rem 0rem;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const Line = styled.div`
  width: 120px;
  height: 10px;
  background-color: #00a97c;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.2s linear, opacity 0.2s linear;
  ${ListWrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 768px) {
    opacity: 1;
    visibility: visible;
    width: 50px;
    height: 5px;
  }
`;
const Heading = styled.div`
  font-size: 36px;
  font-weight:500;
  color: #292929;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  :hover,
  :focus,
  :active,
  ${ListWrapper}:hover & {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    color: #00a97c;
  }
`;
const Main = styled.div`
background-image:url(${aboutbanner});
background-position:right;
/* background-size:45% 100%; */
background-repeat:no-repeat;
min-height:550px;
@media(max-width:767px){
  background-image:none;
}
`;
function OurValues() {
  return (
    <Root>
      <Text>
        <H2>The Secret to our success is here!</H2>
        <ParagraphWrapper>Core values that we reflect </ParagraphWrapper>
      </Text>
      <Main>
      <Container>
      <MainDivWrapper>
        <LeftsideWrapper>
          <ListSection>
            <ListWrapper>
              <Info>
                {/* <Line></Line> */}
                <Heading>Security</Heading>
              </Info>
              <PWrapper>
                We bring end-to-end tailored services with latest tools and
                intelligent technologies to add an extra layer of protection for
                businesses.
              </PWrapper>
            </ListWrapper>
            <ListWrapper>
              <Info>
                {/* <Line></Line> */}
                <Heading>Transparency</Heading>
              </Info>
              <PWrapper>
                We are committed to build an environment that doesn’t have
                boundaries but is based on accessibility, simplicity and
                fairness.
              </PWrapper>
            </ListWrapper>

            <ListWrapper>
              <Info>
                {/* <Line></Line> */}
                <Heading> Trustworthiness</Heading>
              </Info>
              <PWrapper>
                We practice high-level strategic business-driven approach in
                order to create a trustworthy platform for all.
              </PWrapper>
            </ListWrapper>

            <ListWrapper>
              <Info>
                {/* <Line></Line> */}
                <Heading>Immutability </Heading>
              </Info>
              <PWrapper>
                Having our expertise in the blockchain technology, we make sure
                the data collected is secure, corruption-proof and
                decentralized.
              </PWrapper>
            </ListWrapper>

            <ListWrapper>
              <Info>
                {/* <Line></Line> */}
                <Heading>Inclusivity</Heading>
              </Info>
              <PWrapper>
                We have built a safe and welcoming platform that provides
                everyone the opportunity to leverage custom development
                services.
              </PWrapper>
            </ListWrapper>
          </ListSection>
          
        </LeftsideWrapper>

        <RightsideWrapper>
          {/* <ImageWrapper>
            <Image src={aboutbanner} alt="valuesbanner" />
          </ImageWrapper> */}
        </RightsideWrapper>
      </MainDivWrapper>
      </Container>
      </Main>
    </Root>
  );
}

export default OurValues;
