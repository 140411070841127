import React from "react";
import styled from "styled-components";
import { Button } from "../../../Component/styledComponents/styledComponents";
import Swift from '../../../Component/Images/swift.png';
import Reacticon from "../../../Component/Images/react.png";
import Flutter from "../../../Component/Images/flutter.png";
import Ionic from "../../../Component/Images/ionic.png";
import Javascript from "../../../Component/Images/js.png";
import { useNavigate } from "react-router-dom";

const Card = styled.div`
background-color: #282828;
border-radius:10px;
padding:60px 90px;
@media(max-width:1100px){
  padding:40px 30px 40px 60px;
}
`;
const Heading = styled.div`
font-size:34px;
color:#fff;
font-weight:bold;
margin-bottom:20px;
`;
const Text = styled.div`
color:#9b9b9b;
font-size:18px;
`;

const IconSection = styled.div`
margin-top:40px;
display:flex;
align-items: center;
width:100%;
justify-content: space-between;
`;
const IconWrapper = styled.div`
text-align:center;
`;
const Icon = styled.img`
@media(max-width:1100px){
  width:40px;
}
`;
const Subtitle = styled.div`
color:#9b9b9b ;
margin-top:5px;
`;
const MobileAppDevelopment = () => {
  const navigation = useNavigate();
  return (
    <div>
      <Card>
        <Heading>
        Application Development 
        </Heading>
        <Text>
        We help develop scalable, flexible, and interoperable applications that are fully functional, robust and rich in features.
        </Text>
        <IconSection>
             <IconWrapper>
                <Icon src={Swift}/>
                <Subtitle>Swift</Subtitle>
             </IconWrapper>

             <IconWrapper>
                <Icon src={Reacticon}/>
                <Subtitle>React</Subtitle>
             </IconWrapper>

             <IconWrapper>
                <Icon src={Flutter}/>
                <Subtitle>Flutter</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={Ionic}/>
                <Subtitle>Ionic</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={Javascript}/>
                <Subtitle>Javascript</Subtitle>
             </IconWrapper>
        </IconSection>
        <Button
        border="#9b9b9b"
        color="#fff"
        top="12px"
        right="30px"
        left="30px"
        bottom="12px"
        radius="30px"
        mtop="30px"
        onClick={() => {navigation('/mobile_app_development')}}
        >
          EXPLORE
        </Button>
      </Card>
    </div>
  );
};

export default MobileAppDevelopment;
