import React from 'react';
import NFT from '../../../Component/Images/nft.png';
import { Button } from '../../../Component/styledComponents/styledComponents';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Card = styled.div`
background-color: #282828;
border-radius:10px;
padding:40px 90px;
`;
const Heading = styled.div`
font-size:24px;
color:#fff;
font-weight:bold;
margin-bottom:20px;
`;
const Text = styled.div`
color:#9b9b9b ;
font-size:18px;
`;
const IconSection = styled.div`
margin-top:40px;
display:flex;
align-items: center;
width:100%;
justify-content: space-between;
`;
const IconWrapper = styled.div`
text-align:center;
`;
const Icon = styled.img`
/* width:32px; */
`;
const Subtitle = styled.div`
color:#9b9b9b ;
margin-top:5px;
`;
function NonFungible(props) {
   const navigate = useNavigate();
    return (
        <div>
             <Card>
        <Heading>
        Non-fungible tokens 
        </Heading>
        <Text>
        Unlock the doors of the blockchain world through these futuristic keys called NFTs by leveraging our world-class NFT development services with built-in security and consistent support. 
        </Text>
        <IconSection>
             <IconWrapper>
                <Icon src={NFT} alt="NFT" />
                {/* <Subtitle>NFT</Subtitle> */}
             </IconWrapper>

             {/* <IconWrapper>
                <Icon src={UnityLogo}/>
                <Subtitle>Blender</Subtitle>
             </IconWrapper>

             <IconWrapper>
                <Icon src={UnityLogo}/>
                <Subtitle>After Effects</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={UnityLogo}/>
                <Subtitle>Unreal</Subtitle>
             </IconWrapper> */}
        </IconSection>
        <Button
        border="#9b9b9b"
        color="#fff"
        top="12px"
        right="30px"
        left="30px"
        bottom="12px"
        radius="30px"
        mtop="40px"
        onClick={() => navigate('/nfts')}
        >
          EXPLORE
        </Button>
      </Card>
        </div>
    );
}

export default NonFungible;