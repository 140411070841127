import React from 'react';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';

const Root = styled.div`
padding:80px 0;
background-color:#fff;
@media(max-width:767px){
    padding:30px 0;
}
`;
const Heading = styled.h3`
margin: 0;
font-size:21px;
font-size:50px;
font-weight: 500;
@media(max-width:767px){
    font-size:40px;
}
@media(max-width:400px){
    font-size:35px;
}
`;
const Text = styled.div`
color:#181819;
margin-top:20px;
line-height:28px;
@media(max-width:767px){
    line-height: 30px;
}
`;
const Wrapper = styled.div`
width:100%;
text-align:center;
`;
function WhyInfoSection(props) {
    return (
        <Root>
            <Container left="100px" right="100px">
                <Wrapper>
                <Heading>Why Choose Us? </Heading>
                <Text>As a leading NFT marketplace development service provider, we have expertise in endeavouring a robust NFT 
                    platform with high efficiency and accuracy. Our profound experience in this industry allows us to integrate 
                    multiple blockchains and deploy advanced security protocols. In order to enhance the grandeur of the NFT marketplace 
                    and ensure its on-time delivery, we adopt an iterative development process.
                </Text>
                </Wrapper>
            </Container>
        </Root>
    );
}

export default WhyInfoSection;