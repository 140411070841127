import React from "react";
import HeaderSection from "./HeaderSection";
import BrandsTengible from "./BrandsTengible";
// import ConnectSection from "../Home/ConnectMessage";
import WorkSection from "../Blockchian/LetsWorkTogether";
import TrustedTechnologies from "./TrustedTechnologies";
import RecentlyLaunchedProject from "./RecentlyLaunchedProject";
import ConnectMessage from "../../Component/ConnectMessage";

function WebDevelopment(props) {
  return (
    <div>
      <HeaderSection />
      <BrandsTengible />
      <RecentlyLaunchedProject />
      {/* <TrustedTechnologies />*/}
      <WorkSection />
      <ConnectMessage/> 
    </div>
  );
}

export default WebDevelopment;

