
import React from 'react';
import styled from 'styled-components';


const Root = styled.div`
background-color: #fff;
padding:100px 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: #282828;
@media(max-width:767px){
    padding:30px 20px;
}
`;
const H5 = styled.h5`
margin: 0;
font-weight:bold;
font-size:80px;
color:#03363d;
font-weight:bold;
@media(max-width:767px){
    font-size:30px;
}

`;
const Text = styled.div`
font-weight:500;
font-size: 30px;
margin-top:10px;
`;
const Div = styled.div`
font-size: 20px;
margin-top:15px;
@media(max-width:767px){
    font-size: 16px; 
}
`;
function LetsWorkTogether(props) {
    return (
        <Root>
            <H5>
                 Let's Work Together
            </H5>
            <Text>
                Meet us for a cup of coffee.
            </Text>
            {/* <Div>info@BastionExtech.com</Div>
            <Div>+91 9811716213</Div> */}
           
        </Root>
    );
}

export default LetsWorkTogether;