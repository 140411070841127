import React from "react";
import finalstage from "../../Component/Images/featured-games.jpg";
import whitearrow from "../../Component/Images/white-round-text-arrow.png";
import styled from "styled-components";
import { Container } from "../../Component/styledComponents/styledComponents";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  background-color: #000;
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  padding:50px;
  @media(max-width:767px){
    padding:30px 20px;
  }
`;

const TextWrapper = styled.h3`
  margin: 0;
  color: white;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom:20px;
  @media(max-width:767px){
    font-size: 2rem;
  }
`;

const ImageWrapper = styled.div`
 width:100%;
`;
const Image = styled.img`
 max-width:100%;
 border-radius: 2rem;
 object-fit:contain;
`;

const TextWrapper2 = styled.div`
  width:100%;
  color: #bbb3b3;
  font-family: "Roboto";
  word-spacing: 0.07rem;
  letter-spacing: 0.0056rem;
  font-size: 1.099rem;
  display: flex;
  justify-content:left;
  align-items: center;
  margin-top:20px;

`;

const IconWrapper = styled.div`
     height: 2.5rem;
     width: 2.5rem;
     min-width:2.5rem;
     min-height: 2.5rem;
     border-radius: 2rem;
     border: 1px solid #afafaf;
     margin-left:10px;
     cursor: pointer;
`;
const Icon = styled.img`
  margin: 0.6rem 0rem 0rem 0.4rem;
`;
const Card = styled.div`
width:100%;
display: flex;
align-items: center;
justify-content: center;
width:100%;
`;
const Wrapper = styled.div`
display: flex;
flex-direction: column;
`;

function FeaturedGame() {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
      <Wrapper>
      <TextWrapper>Featured Games</TextWrapper>
      <Card>
        <Image src={finalstage} alt="Metaspace Game"/>
      </Card>
      <TextWrapper2>
        Developing World-class fully fuctional mobile apps for Andriod and IOS
        platforms.
        <IconWrapper onClick={() => {navigate('/contact_us')}}>
        <Icon src={whitearrow} alt="arrow"/>
        </IconWrapper>
      </TextWrapper2>
      </Wrapper>
      </Container>
    </Root>
  );
}

export default FeaturedGame;
