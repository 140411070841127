import React from 'react';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';
import BgImage from '../../Component/Images/sec2-img.png';

const Root = styled.div`
/* background-image:url(${BgImage}); */
padding:80px 0;
background-repeat:no-repeat;
background-position:right;
background-size:contain;
background-color:#cfeee6;
position: relative;
@media(max-width:767px){
  padding:30px 0;
}
`;
const LeftSection = styled.div`
width:50%;
@media(max-width:767px){
  width:100%;
}
`;
const RightSection = styled.div`
width:50%;
@media(max-width:767px){
  display: none;
}
`;
const Heading = styled.div`
color:#131313;
font-size:40px;
font-weight:bold;
line-height: 56px;
@media(max-width:1216px){
  font-size:30px;
  line-height: 45px;
}
@media(max-width:910px){
  font-size:22px;
  line-height: 40px;
}
@media(max-width:500px){
  line-height: 30px;
}
`;
const Image = styled.img`
position: absolute;
top:30px;
right:100px;
width:500px;
@media(max-width:1200px){
  right:10px;
}
@media(max-width:1066px){
  width:400px;
  top:80px;
}
@media(max-width:872px){
  width:350px;
  top:100px;
}
@media(max-width:767px){
  display: none;
}
`;
const Para = styled.div`
margin-top:30px;
font-size:20px;
letter-spacing:0.6px;
line-height: 34px;
@media(max-width:872px){
  font-size:16px;
}
@media(max-width:500px){
  margin-top:10px;
  line-height: 28px;
}
`;
const H1 = styled.h1`
  margin: 0;
  font-size: 40px;
  display: inline;
`;
function InfoSection(props) {
    return (
        <Root>
            <Container>
              <LeftSection>
                <Heading><H1>NFT Marketplace Development</H1>:</Heading>
                <Heading>Empowering virtual assets </Heading>
                <Para>NFT marketplace strives to offer premium features, sophisticated functionalities and an improved user interface in
                      order to help businesses stand out as distinctive. The marketplace promises smooth NFT trading and the tokenization of
                      all kinds of assets. They are empowering the future of the NFT world by creating a standard revenue model for all.
                </Para>
              </LeftSection>
              <RightSection>

              </RightSection>
            </Container>
            <Image src={BgImage} alt="NFT Marketplace Development" />
        </Root>
    );
}

export default InfoSection;