import styled from "styled-components";

export const Root = styled.div`
display: flex;
align-items: center;
flex-direction: column;
padding:150px 0 0 0;
background-color:#fff;
@media(max-width:767px){
  padding:100px 0 0 0;
}
`;
export const Wrapper = styled.div`
 color:#737881;
 padding-bottom:80px;
 border-bottom:1px solid #f1f7f5;
`;

export const HeadingSection = styled.div`
display: flex;
color:#333333;
font-size:50px;
font-weight:300;
@media(max-width:500px){
  font-size: 40px;
}
`;

export const Heading = styled.div`
font-weight:500;
`;
export const Line = styled.div`
${(p) => p.color && `
  background-color:${p.color};
`};
height: 21px;
margin-top:-20px;
`;

export const HeadingWrapper = styled.div`
width:fit-content;
`;

export const TextTab = styled.div`
width:fit-content;
background-color: #f2f8f5;
color:#5c5d64;
margin-top:20px;
font-size: 16px;
font-weight: bold;
padding:5px 10px;
margin-bottom:30px;
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  margin-bottom:30px;
`;

export const List = styled.div`
display: flex;
align-items: flex-start;
`;

export const Icon = styled.div`
 width:20px;
 height: 20px;
 max-width: 20px;
 max-height:20px;
 min-width:20px;
 min-height: 20px;
 border-radius:50%;
 border:4px solid #00a97c;
 margin-right:10px;
 margin-top:3px;
`;

export const Image = styled.img`
margin-top:20px;
@media(max-width:500px){
  margin-top:0px;
}
`;
export const HeadText = styled.div`
color:#495057;
font-size:26px;
font-weight:500;
margin-bottom:30px;
`;

export const ButtonWrapper = styled.div`

`;