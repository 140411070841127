import React from 'react';
import styled from 'styled-components';
import BgImage from '../../Component/Images/payiza-mobile-back.jpg';
import { Container } from '../../Component/styledComponents/styledComponents';
import MobileImage from '../../Component/Images/payiza-mobile.jpg';

const Root = styled.div`
background-image:url(${BgImage});
height: 100%;
color: #fff;
background-size: cover;
padding:60px 0;
background-position:center;
background-repeat:no-repeat;

`;
const Heading = styled.h3`
margin: 0;
font-weight: 400;
font-size:39px;
@media(max-width:767px){
    font-size:30px;
}
`;
const MainText = styled.div`
font-size: 39px;
 background: linear-gradient(to right,#00a97c 0%,
    #a5e292 100%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
`;
const LeftWrapper = styled.div`
width:50%;
@media(max-width:767px){
    width: 100%; 
}
`;
const RightWrapper = styled.div`
width: 50%;
text-align: right;
@media(max-width:767px){
    width: 100%; 
    text-align: center;
}
`;
const Image = styled.img`
width:350px;
@media(max-width:767px){
    width: 260px; 
}
`;
const Wrapper = styled.div`
 display: flex;
 align-items: center;
 justify-content:space-between;
 @media(max-width:767px){
    flex-direction:column;
}
`;
const Para = styled.div`
 color: #9e9d9d;
 margin-top:20px;
 font-size:24px;
`;
function AppInfoSection(props) {
    return (
        <Root>
            <Container>
                <Wrapper>
                    <LeftWrapper>
                        <Heading>We’ve all heard of instant
                            groceries, now say hello to</Heading>
                        <MainText>metaspacelab Wallet.</MainText>
                        <Para>0% hassle, 100% paperless. Get your
                            metaspacelab Virtual A/C in less than 5 minutes.</Para>
                    </LeftWrapper>
                    <RightWrapper>
                        <Image src={MobileImage} alt="metaspacelab Wallet" />
                    </RightWrapper>
                </Wrapper>
            </Container>
        </Root>
    );
}

export default AppInfoSection;