import React from "react";
import styled from 'styled-components';
import { Button } from "../../../Component/styledComponents/styledComponents";
import Docker from '../../../Component/Images/docker.png';
import Python from "../../../Component/Images/python.png";
import Reactjs from "../../../Component/Images/react.png";
import Javascript from "../../../Component/Images/js.png";
import Nodejs from "../../../Component/Images/node.png";
import { useNavigate } from "react-router-dom";

const Card = styled.div`
background-color: #282828;
border-radius:10px;
padding:60px 90px;
@media(max-width:1100px){
  padding:40px 30px 40px 60px;
}
`;
const Heading = styled.div`
font-size:34px;
color:#fff;
font-weight:bold;
margin-bottom:20px;
`;
const Text = styled.div`
color:#9b9b9b;
font-size:18px;
`;

const IconSection = styled.div`
margin-top:40px;
display:flex;
align-items: center;
width:100%;
justify-content: space-between;
`;
const IconWrapper = styled.div`
text-align:center;
`;
const Icon = styled.img`
@media(max-width:1100px){
  width:40px;
}
`;
const Subtitle = styled.div`
color:#9b9b9b ;
margin-top:5px;
`;
const BlockchainDevelopment = () => {
  const navigation = useNavigate();
  return (
    <div>
      <Card>
        <Heading>
           Blockchain Development
        </Heading>
        <Text>
        We choose the best Blockchain platforms similar to the hyper ledger, Ethereum and more to build the world-class business solutions.
        </Text>
        <IconSection>
             <IconWrapper>
                <Icon src={Docker}/>
                <Subtitle>Docker</Subtitle>
             </IconWrapper>

             <IconWrapper>
                <Icon src={Python}/>
                <Subtitle>Python</Subtitle>
             </IconWrapper>

             <IconWrapper>
                <Icon src={Reactjs}/>
                <Subtitle>React js</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={Javascript}/>
                <Subtitle>Javascript</Subtitle>
             </IconWrapper>
             <IconWrapper>
                <Icon src={Nodejs}/>
                <Subtitle>Node js</Subtitle>
             </IconWrapper>
        </IconSection>
        <Button
        border="#9b9b9b"
        color="#fff"
        top="12px"
        right="30px"
        left="30px"
        bottom="12px"
        radius="30px"
        mtop="40px"
        onClick={() => (navigation('/blockchain_development'))}>
          EXPLORE
        </Button>
      </Card>
    </div>
  );
};

export default BlockchainDevelopment;
