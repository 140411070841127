import styled from "styled-components";
import backgroundimage from '../Images/bg.jpg';

export const TextWrapper = styled.text`
  font-size: 4.3rem;
  font-family: roboto;
  font-weight: 600;
  color: #00a97c;
  transform: translate(-50%, -50%);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
 @media(max-width:767px){
  font-size: 4rem;
 }
 @media(max-width:767px){
  font-size: 3rem;
 }
`;
export const TextWrappersmall = styled.div`
  font-size: 2.6rem;
  font-weight: 700;
  color: white;
  line-height: 2rem;
  margin-top:10px;
  @media(max-width:500px){
    font-size: 1.7rem;
    line-height: 1rem;
  }
`;

export const UL = styled.ul`
  list-style: none;
  display: flex;
  column-gap: 5rem;
  justify-content: center;
  align-items: center;
  padding-top: 3.7rem;
  flex-wrap:wrap;
  margin-left:0;
  padding-left: 0;
  @media(max-width:767px){
    column-gap: 2rem;
    padding-top: 2rem;
  }
  @media(max-width:466px){
    column-gap: 1rem;
  }
`;

export const ContainerWrapper = styled.div`
  text-align: center;
  line-height: 35px;
`;
export const Image = styled.img`
@media(max-width:767px){
  width:40px;
}
@media(max-width:654px){
  width:30px;
}
`;


export const ImageWrapper = styled.div`
 display: flex;
 flex-direction:column;
 align-items: center;
 justify-content: center;
`;

export const TextimageWrapper = styled.text`
  color: #727272;
  @media(max-width:430px){
    font-size:14px;
  }
`;

export const Root = styled.div`
 background-image:url(${backgroundimage});
 padding: 80px 0;  
 @media(max-width:767px){
  padding: 30px 20px 15px 20px; 
 }
`;
export const EyeImage = styled.img`

@media(max-width:767px){
  width:60px;
}
@media(max-width:654px){
  width:40px;
}
/* @media(max-width:654px){
  width:50px;
} */
`;

export const ImageContainer = styled.div`
height:65px;
@media(max-width:767px){
  height: 50px;
}
@media(max-width:520px){
  height: 40px;
}
`;