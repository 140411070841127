import { Container } from "@mui/system";
import React from "react";
import styled from "styled-components";
import image1 from "../../Component/Images/node.png";
import image2 from "../../Component/Images/react.png";
import image3 from "../../Component/Images/flutter.png";
import Iconic from "../../Component/Images/ionic.png";
import Swift from "../../Component/Images/swift.png"
import { ContainerWrapper, 
         Image, 
         ImageContainer, 
         ImageWrapper, 
         Root, 
         TextimageWrapper, 
         TextWrapper, 
         TextWrappersmall, 
         UL } from "../../Component/styledComponents/TechnologySectionStyleComponents";



function TrandingTechnologies() {
  return (
    <Root>
      <ContainerWrapper>
        <TextWrapper> TRENDING </TextWrapper>
        <TextWrappersmall>Technologies</TextWrappersmall>
      </ContainerWrapper>

      <UL>
        <ImageWrapper>
          <ImageContainer>
            <Image src={image3} alt="Flutter" />
          </ImageContainer>
          <TextimageWrapper> flutter  </TextimageWrapper>
        </ImageWrapper>


        <ImageWrapper>
          <ImageContainer>
            <Image src={image1} alt="Node js" />
          </ImageContainer>
          <TextimageWrapper> Node.js </TextimageWrapper>
        </ImageWrapper>
      
        <ImageWrapper>
          <ImageContainer>
            <Image src={image2} alt="React js" />
          </ImageContainer>
          <TextimageWrapper> React.js </TextimageWrapper>
        </ImageWrapper>

        <ImageWrapper>
          <ImageContainer>
            <Image src={Iconic} alt="Iconic" />
          </ImageContainer>
          <TextimageWrapper> Iconic </TextimageWrapper>
        </ImageWrapper>


        <ImageWrapper>
          <ImageContainer>
            <Image src={Swift} alt="Swift" />
          </ImageContainer>
          <TextimageWrapper> Swift  </TextimageWrapper>
        </ImageWrapper>
  
      </UL>
    </Root>
  );
}

export default TrandingTechnologies;
