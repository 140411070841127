import React from 'react';
import styled from 'styled-components';
import MapLocation from './MapLocation';
import Header from './Header';

const Root = styled.div``;

function ContactUs() {
    return (
        <Root>
            <Header />
            {/* <MapLocation /> */}
        </Root>
    );
}

export default ContactUs;