import React from 'react';
import styled, { keyframes } from 'styled-components';
import {Container} from '../../Component/styledComponents/styledComponents';
import Image1 from '../../Component/Images/Image1.png';
import Image2 from '../../Component/Images/Image2.png';
import Image3 from '../../Component/Images/Image3.png';
import WrappercurveImage from '../../Component/Images/gate.png';
import LineImage from '../../Component/Images/line.png';
import TrangleImage from '../../Component/Images/sec2-tri.png';

const Root = styled.div`
background-color:#fff;
padding:40px 0px 100px 0;
display: flex;
align-items: center;
justify-content: center;
position: relative;
/* border-bottom:1px solid #e8eaea; */
@media(max-width:767px){
  padding:40px 0px 30px 0;
}
`;

const Wrapper = styled.div`
width:70%;
margin: 0 auto;
position: relative;
z-index:2;
display: flex;
align-items: center;
justify-content: center;
@media(max-width:1135px){
  width:90%;
  max-width:90%;
}
`;
const Div = styled.div`
color:#25493f;
text-align: center;
margin-bottom:20px;
font-size: 60px;
display: flex;
align-items:baseline;
position: relative;
font-size:500;
justify-content: center;
@media(max-width:1004px){
  font-size: 50px;
}
@media(max-width:545px){
  font-size: 35px;
}
@media(max-width:432px){
  font-size: 27px;
}
`;

const LargeTextWrapper = styled.div`
color:#25493f;
text-align: center;
margin-bottom:20px;
display: flex;
align-items:baseline;
justify-content: center;
font-size: 50px;
font-size: 80px;
 @media(max-width:1004px){
  font-size: 65px;
}
@media(max-width:545px){
  font-size: 45px;
}
@media(max-width:432px){
  font-size: 30px;
}

`;
const Image = styled.img`
width:320px;
height: 60px;
${(p) => p.left && `margin-left:${p.left}`};
${(p) => p.right && `margin-right:${p.right}`};
@media(max-width:1004px){
width:220px;
height: 50px;
}
@media(max-width:824px){
  width:140px;
}
@media(max-width:545px){
  width:100px;
  height: 40px;
}

`;
const Span = styled.span`
font-weight:400;
${(p) => p.bold && `font-weight:${p.bold}` };
${(p) => p.color && `
  background-image: linear-gradient(to top, #00dea3, #037474), linear-gradient(to bottom, #242524, #242524);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`}
`;
const Line = styled.div`
  position:absolute;
  width:50%;
  right:0;
  top:0;
  height: 10px;
  background-color:#018c67;
`;

const BgImageWrapper = styled.img`
max-width:153px;;
margin-top:60px;
@media(max-width:767px){
  display: none;
}
`;

const LineImageBottom = styled.img`
position: absolute;
bottom: 0px;
max-width: 90%;
`;

const LineHeight = styled.div`
width:2px;
height:130px;
background-color:#018c67;
position: relative;
left:-18px;
bottom:40px;
min-width:2px;
@media(max-width:767px){
  height:70px;
  bottom:40px;
}

`;

const LeftWrapper = styled.div`
width:100%;
`;
const RightWrapper = styled.div`
width: 153px;
min-width:153px;
position: relative;
display: flex;
@media(max-width:767px){
  display: none;
}
`;
const RotateIcon = keyframes`
 from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const PositionImage = styled.img`
position: absolute;
bottom:0;
right:-10px;
width:90px;
animation: ${RotateIcon} infinite 20s linear;
`;
function ImagesSection(props) {
    return (
        <Root>
        <Line/>
        <Container>
           <Wrapper>
            <LeftWrapper>
                <Div>
                  <Span>Catering</Span><Image src={Image1} left="10px" right="10px"/><LineHeight/><Span>Millions</Span>
                </Div>
                <Div><Image src={Image3} right="10px" left="10px"/> <Span bold="bold">To Deliver </Span></Div>
                <LargeTextWrapper> <Image src={Image2} right="10px"/>  <Span bold="bold" color="color">Proficiency!</Span></LargeTextWrapper>
            </LeftWrapper>
            <RightWrapper>
               <BgImageWrapper src={WrappercurveImage}/>
               <PositionImage src={TrangleImage}/>
            </RightWrapper>
           </Wrapper>
           <LineImageBottom src={LineImage}/>
        </Container>
    </Root>
    );
}

export default ImagesSection;