import React from 'react';
import styled from 'styled-components';
import ConnectMessage from '../../Component/ConnectMessage';
import LetsWorkTogether from '../Blockchian/LetsWorkTogether';
import CardSection from './CardSection';
import EthereumHero from './EthereumHero';
import EthereumServices from './EthereumServices';
import ImagesSection from './ImagesSection';
import SollutionSection from './sollutionSection';

const Root = styled.div`

`;
function Ethereum(props) {
    return (
        <Root>
            <EthereumHero/>
            <ImagesSection/>
            <EthereumServices/>
            <SollutionSection/>
            <CardSection/>
            <LetsWorkTogether />
            <ConnectMessage />
        </Root>
    );
}

export default Ethereum;