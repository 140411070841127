import React from 'react';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';
import CryptoImage from '../../Component/Images/sec2a.jpg';
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
background-color: #cfeee6;
padding:80px 0;
@media(max-width:767px){
    padding:30px 0;
}
`;
const Wrapper = styled.div`
width:100%;
`;
const Heading = styled.div`
font-size: 38px;
font-weight:bold;
margin-bottom:30px;
@media(max-width:767px){
    font-size: 30px;
}
`;
const CardWrapper = styled.div`
width:100%;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap:20px;
@media(max-width:767px){
    grid-template-columns: 1fr 1fr;
}
@media(max-width:520px){
    grid-template-columns:1fr;
}
`;
const Card = styled.div`
background-color: #fafafa;
width:100%;
padding:20px;
border-radius:5px;
display: flex;
flex-direction: column;
align-items: center;
justify-content:space-between;
`;
const ButtonContainer = styled.div`
text-align: center;
margin-top:30px;
`;
const Para = styled.div`
font-size:24px;
font-weight:500;
margin-bottom:30px;
margin-top:60px;
`;

const Button = styled.button`
background:none;
border:1px solid #1b1b1b;
padding:15px 30px;
border-radius:30px;
cursor: pointer;
`;
const Image = styled.img`
width: fit-content;
height: 160px;
max-width: 100%;
`;

const HeadText = styled.div`
font-size:27px;
font-weight:500;
margin-bottom:20px;
text-align:left !important;
width:100%;
`;
const Text = styled.div`
color:#696969;
`;
const Info = styled.div`

`;
function InfoSection(props) {
    const navigate = useNavigate();
    return (
        <Root>
            <Container>
                <Wrapper>
                    <Heading>Explore endless possibilities</Heading>
                    <CardWrapper>
                        <Card>
                            <Image src={CryptoImage} />
                            <Info>
                            <HeadText>Dive into the world of
                                Crypto</HeadText>
                            <Text>Lorem ipsum dolor sit amet, consec tetur adipiscing elit. </Text>
                            </Info>
                        </Card>
                        <Card>
                        <Info>
                            <HeadText>Grow your business with
                                Metaspace Lab</HeadText>
                            <Text>Lorem ipsum dolor sit amet, consec tetur adipiscing elit.
                                , sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </Text>
                        </Info>      
                              
                                <Image src={CryptoImage} />
                        </Card>
                        <Card>
                            <Image src={CryptoImage} />
                            <Info>
                            <HeadText>Metaspace Lab Earn</HeadText>
                            <Text>Lorem ipsum dolor sit amet, consec tetur adipiscing elit. </Text>
                           </Info>

                        </Card>
                    </CardWrapper>
                    <ButtonContainer>
                        <Para>Contact us now to build your own portfolio for free!</Para>
                        <Button onClick={() => navigate('/contact_us')}>GET STARTED</Button>
                    </ButtonContainer>
                </Wrapper>
            </Container>
        </Root>
    );
}

export default InfoSection;