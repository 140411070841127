// import { Button, Tab, Tabs, Toolbar } from '@mui/material';
import React, { useEffect, useRef, useState } from "react";
import BrandLogo from "./Images/BrandLogo.png";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import * as Router from "../Routes";
import { Button, Container } from "./styledComponents/styledComponents";

const Main = styled.div`
  padding-bottom: 0px;
  width: 100%;
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const MobileViewAppBar = styled.div`
display: none;
  background-color: #f1f7f5;
  width: 90%;
  border-radius: 0px 50px 50px 0px;
  padding: 10px 20px;
  position: relative;
  /* display: flex; */
  align-items: center;
  margin-right: 80px;
  justify-content: space-between;
  ${(p) =>
    p.scrollAppear &&
    `
   background-color:#f1f7f5;
`};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  @media(max-width: 767px) {
    display:flex ;
  }
`;
const Logo = styled.img`
  width: 100px;
  @media(max-width: 767px) {
    width: 50px;
  }
`;
const SmallDeviceLinkView = styled.div`
  background-color: #f1f7f5;
  position: fixed;
  width: 300px;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 20;
  padding-top: 20px;
  padding-left: 20px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  @media (min-width: 767px) {
    display: none;
  }
`;

const Call = styled.span`
font-size: 17px;
line-height: 13px;
`;

const Link = styled.div`
  margin-top: 20px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
  display: flex;
`;

const Navigation = styled.div`
  background-color: rgb(241, 247, 245);
  border-radius: 0px 50px 50px 0px;
  width: 95%;
  justify-content: flex-start;
  margin-right: 80px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  ${(p) =>
    p.scrollAppear &&
    `
   background-color:#f1f7f5;
`};
  box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
  /* 0px 1px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  @media (max-width: 768px) {
    display: none !important;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const NavLink = styled.div`
  margin-left: 25px;
  font-size: 16px;
  position: relative;
  display: flex;
  cursor: pointer;
  @media (max-width: 900px) {
    margin-left: 15px;
  }
  @media (max-width: 845px) {
    margin-left: 10px;
    font-size: 14px;
  }
`;
const NavLink2 = styled.div`
  margin-left: 25px;
  font-size: 16px;
  position: relative;
  /* display: flex; */
  cursor: pointer;
  @media (max-width: 900px) {
    margin-left: 15px;
  }
  @media (max-width: 845px) {
    margin-left: 10px;
    font-size: 14px;
  }
`;


const DropDown = styled.div``;
const DropDownSection = styled.div`
  position: absolute;
  top: 30px;
  left: -20px;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
  /* padding-top:20px; */
  border-radius: 4px;
  @media (max-width: 767px) {
    position: relative;
    left: 0px;
    background: none;
    box-shadow: none;
    top: 0px;
    width: 100%;
    cursor: pointer;
  }
`;
const IconWrapper = styled.span`
  margin-left: 5px;
`;

const List = styled.div`
  font-size: 14px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 20px;

  :hover {
    box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
    @media (max-width: 767px) {
      box-shadow: none;
    }
  }
`;
const Image = styled.img`
  cursor: pointer;
width: 132px;

`;
const Navbar = ({ showDropDown, setShowDropDown }) => {
  const [showNavigation, setShowNavigation] = useState(false);
  const headerRef = useRef();
  const [scrollAppear, setScrollAppear] = useState(false);
  const navigation = useNavigate();

  const scrollActive = () => {
    if (window.scrollY > 60) {
      setScrollAppear(true);
    } else {
      setScrollAppear(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollActive);
  }, [headerRef]);

  return (
    <Main ref={headerRef}>
      <Navigation>
        <Image
          src={BrandLogo}
          style={{ paddingLeft: "5rem" }}
          onClick={() => {
            navigation("/");
          }}
        />
        <NavWrapper>
          <NavLink onClick={() => navigation("/")}>Home</NavLink>
          <NavLink onClick={() => navigation("/aboutus")}>About Us</NavLink>
          {/* <NavLink onClick={() => (navigation('/whyUs'))}>Why Us</NavLink> */}






          
          <NavLink>
            <DropDown
              onClick={(e) => {
                e.stopPropagation();
                setShowDropDown(!showDropDown);
              }}
            >
              Services <i className="fa-solid fa-angle-down"></i>
            </DropDown>
            {/* <IconWrapper onClick={() => {setShowDropDown(!showDropDown)}}><i class="fa-solid fa-angle-down"></i></IconWrapper> */}
            {showDropDown && (
              <>
                <DropDownSection>
                  <List
                    onClick={() => {
                      navigation("/metaverse_development");
                      setShowDropDown(!showDropDown);
                    }}
                  >
                    Metaverse Development
                  </List>
                  <List
                    onClick={() => {
                      navigation("/blockchain_development");
                      setShowDropDown(!showDropDown);
                    }}
                  >
                    Blockchain Development
                  </List>
                  <List
                    onClick={() => {
                      navigation("/game_development");
                      setShowDropDown(!showDropDown);
                    }}
                  >
                    Game Development
                  </List>
                  <List
                    onClick={() => {
                      navigation("/mobile_app_development");
                      setShowDropDown(!showDropDown);
                    }}
                  >
                    Mobile App Development
                  </List>
                  <List
                    onClick={() => {
                      navigation("/web_development");
                      setShowDropDown(!showDropDown);
                    }}
                  >
                    Web Development
                  </List>
                  {/* <List
                    onClick={() => {
                      navigation("/crypto_currency");
                      setShowDropDown(!showDropDown);
                    }}
                  >
                    Crypto Currency Development
                  </List> */}

                  <List
                    onClick={() => {
                      navigation("/nft_marketplace");
                      setShowDropDown(!showDropDown);
                    }}
                  >
                    Nft Marketplace Development
                  </List>
                </DropDownSection>
              </>
            )}
          </NavLink>


          {/* <NavLink onClick={() => navigation("/our_work")}>Our Work</NavLink> */}
          {/* <NavLink onClick={() => (navigation('/contact_us'))}>Contact Us</NavLink> */}

          <NavLink2>
            <Button
              bgcolor="#000"
              color="#fff"
              radius="30px"
              width="10rem"
              fontSize="18px"
              height="3rem"
              fontweight= "600"
              onClick={() => navigation("/contact_us")}
            >
            <Call> Contact Us <br /><Call style={{fontSize:"10px", fontWeight:"400"}}> for consultation Call </Call> </Call>
            </Button>
          </NavLink2>
        </NavWrapper>
      </Navigation>
      <MobileViewAppBar>
        <Logo
          src={BrandLogo}
          onClick={() => {
            navigation("/");
          }}
        />
        <MenuIcon
          onClick={() => {
            setShowNavigation(!showNavigation);
          }}
        />
      </MobileViewAppBar>
      {showNavigation && (
        <SmallDeviceLinkView>
          <CloseIcon
            onClick={() => {
              setShowNavigation(!showNavigation);
            }}
          />
          <Link
            onClick={() => {
              navigation("/");
              setShowNavigation(!showNavigation);
            }}
          >
            Home
          </Link>
          <Link
            onClick={() => {
              navigation("/aboutus");
              setShowNavigation(!showNavigation);
            }}
          >
            About Us
          </Link>
          <Link
            onClick={() => {
              setShowDropDown(!showDropDown);
            }}
          >
            <DropDown
              onClick={(e) => {
                e.stopPropagation();
                setShowDropDown(!showDropDown);
              }}
            >
              Services
            </DropDown>
            <IconWrapper
              onClick={(e) => {
                e.stopPropagation();
                setShowDropDown(!showDropDown);
              }}
            >
              <i className="fa-solid fa-angle-down"></i>
            </IconWrapper>
          </Link>
          {showDropDown && (
            <>
              <DropDownSection>
                <List
                  onClick={() => {
                    navigation("/blockchain_development");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  Blockchain Development
                </List>
                <List
                  onClick={() => {
                    navigation("/game_development");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  Game Development
                </List>
                <List
                  onClick={() => {
                    navigation("/mobile_app_development");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  MobileApp Development
                </List>
                <List
                  onClick={() => {
                    navigation("/web_development");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  Web Development
                </List>
                <List
                  onClick={() => {
                    navigation("/metaverse_development");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  Metaverse Development
                </List>
                {/* <List
                  onClick={() => {
                    navigation("/crypto_currency");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  Crypto Currency Development
                </List> */}
                <List
                  onClick={() => {
                    navigation("/nft_marketplace");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  Nft Marketplace Development
                </List>
                
              </DropDownSection>
            </>
          )}
          <Link
            onClick={() => {
              navigation("/our_work");
              setShowNavigation(!showNavigation);
            }}
          >
            Our Work
          </Link>
          <Link
            onClick={() => {
              navigation("/contact_us");
              setShowNavigation(!showNavigation);
            }}
          >
            Contact Us
          </Link>
        </SmallDeviceLinkView>
      )}
    </Main>
  );
};

export default Navbar;
