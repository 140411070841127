import React from 'react';
import Python from "../../Component/Images/python.png";
import postgresql from "../../Component/Images/postgresql.png";
import docker from "../../Component/Images/docker.png";
import react from "../../Component/Images/react.png";
import node from "../../Component/Images/node.png";
import { ContainerWrapper, 
         Image, 
         ImageContainer, 
         ImageWrapper, 
         Root, 
         TextimageWrapper, 
         TextWrapper, 
         TextWrappersmall, 
         UL } from '../../Component/styledComponents/TechnologySectionStyleComponents';


const TechnologySection = () => {
   return (
      <Root>
         <ContainerWrapper>
            <TextWrapper> TECHNOLOGY </TextWrapper>
            <TextWrappersmall>Stack We work On</TextWrappersmall>
         </ContainerWrapper>
         <UL>
            <ImageWrapper>
               <ImageContainer>
                  <Image src={docker} alt="Docker" />
               </ImageContainer>
               <TextimageWrapper> docker </TextimageWrapper>
            </ImageWrapper>
            <ImageWrapper>
               <ImageContainer>
                  <Image src={Python} alt="Python" />
               </ImageContainer>
               <TextimageWrapper> Python </TextimageWrapper>
            </ImageWrapper>
            <ImageWrapper>
               <ImageContainer>
                  <Image src={postgresql} alt="Postgresql" />
               </ImageContainer>
               <TextimageWrapper>postgresql</TextimageWrapper>
            </ImageWrapper>
            <ImageWrapper>
               <ImageContainer>
                  <Image src={react} alt="React js" />
               </ImageContainer>
               <TextimageWrapper>React js</TextimageWrapper>
            </ImageWrapper>
            <ImageWrapper>
               <ImageContainer>
                  <Image src={node} alt="Node js" />
               </ImageContainer>
               <TextimageWrapper>Node js</TextimageWrapper>
            </ImageWrapper>
         </UL>
      </Root>
   );
};

export default TechnologySection;