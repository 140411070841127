import React from "react";
import styled, { keyframes } from "styled-components";
import BgImage from "../../Component/Images/bg.jpg";
import CardImage from "../../Component/Images/chartIcon.jpg";
import Image1 from "../../Component/Images/be-part-icon1.png";
import Image2 from "../../Component/Images/be-part-icon2.png";
import Image3 from "../../Component/Images/be-part-icon3.png";
import Image4 from "../../Component/Images/be-part-icon4.png";
import {
  Button,
  Container,
  Spacing,
} from "../../Component/styledComponents/styledComponents";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  background-image: url(${BgImage});
  padding: 60px 0;
  color: #fff;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const LeftSection = styled.div`
  width: 40%;
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
const RightSection = styled.div`
  width: 60%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const LargeHading = styled.div`
  font-size: 100px;
  @media (max-width: 905px) {
    font-size: 80px;
  }
  @media (max-width: 767px) {
    font-size: 50px;
  }
`;
const Text = styled.div`
  font-size: 36px;
  color: #fff;
`;
const Para = styled.div`
  font-size: 14px;
  margin-top: 10px;
  line-height: 24px;
`;
const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 130px;
  @media (max-width: 1000px) {
    padding-left: 0px;
  }
  /* padding-left:100px; */
`;
const Div = styled.div`
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
`;
const Card = styled.div`
  width: 100%;
  padding: 20px;
  min-width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: -2.6px 1.5px 32px 0 rgba(0, 0, 0, 0.5);
  color: #000;
  ${(p) =>
    p.rotation &&
    `
transform: rotate(5deg);
`};
`;

const InfoText = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Image = styled.img`
  margin-top: 10px;
  transition-property: transform;
  transition-duration: 5s;
  :hover {
    ${(p) => p.left && `
       transform: rotate(-20deg);
    `};
    ${(p) => p.right && `
       transform: rotate(20deg);
    `};
  }
`;
function SollutionSection(props) {
    const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Wrapper>
          <LeftSection>
            <LargeHading>Be part </LargeHading>
            <Text>of the revolution!</Text>
            <Para>
              Take hold of the scalable and top-tier metaverse solutions that
              will get your business started and running.
            </Para>
            <Button
              bgcolor="#fff"
              mtop="20px"
              color="#000"
              top="15px"
              bottom="15px"
              left="30px"
              right="30px"
              radius="30px"
              onClick={() =>{navigate('/contact_us')}}
            >
              LEARN MORE
            </Button>
          </LeftSection>
          <RightSection>
            <CardWrapper>
              <Div>
                <Card>
                  <Image src={Image1} alt="Digital Payment Solutions" left="left"/>
                  <InfoText>
                    Digital Payment
                    <br /> Solutions
                  </InfoText>
                </Card>
                <Spacing top="20px"></Spacing>
                <Card>
                  <Image src={Image2} alt="Digital Payment Solutions" left="left"/>
                  <InfoText>
                    Digital Payment
                    <br /> Solutions
                  </InfoText>
                </Card>
              </Div>
              <Div>
                <Spacing top="20px"></Spacing>
                <Card rotation="rotation">
                  <Image src={Image3} alt="Metaverse NFT Marketplace" right="right"/>
                  <InfoText>
                    Metaverse NFT <br />
                    Marketplace
                  </InfoText>
                </Card>
                <Spacing top="20px"></Spacing>
                <Card>
                  <Image src={Image4} alt="Helthcare Metaverse" right="right"/>
                  <InfoText>
                    Helthcare
                    <br /> Metaverse
                  </InfoText>
                </Card>
              </Div>
            </CardWrapper>
          </RightSection>
        </Wrapper>
      </Container>
    </Root>
  );
}

export default SollutionSection;
