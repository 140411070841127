import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';
import EthereumImage from '../../Component/Images/etherium-icon.png';
import EthereumBnnerImage from '../../Component/Images/etherium-hand.png';
import MoveMentImage from '../../Component/Images/etherium-traiangle.png';
import TrangleImage from '../../Component/Images/banner-tri.png';
import SecondTrangleImage from '../../Component/Images/cardImage.png';

const Root = styled.div`
 background-image: linear-gradient(to top, #052a29, #010409);
 padding:170px 0 100px 0;
 display: flex;
 min-height:547px;
 align-items: center;
 justify-content: center;
 @media(max-width:767px){
    min-height: 100%;
    padding:100px 0 70px 0;
}
`;
const LeftSection = styled.div`
width:50%;
@media(max-width:767px){
 width:100%;
}
`;
const RightSection = styled.div`
width:50%;
position: relative;
display: flex;
justify-content: right;
align-items: baseline;
@media(max-width:767px){
  display: none;
}
`;

const Icon = styled.img`
  @media(max-width:1167px){
    width: fit-content;
    height:75px;
  }
  @media(max-width:1167px){
    height:60px;
  }
  @media(max-width:552px){
    height: 40px;
  }
`;

const Heading = styled.h1`
  margin: 0;
  background-image: linear-gradient(to top, #21e8b3, #037474), linear-gradient(to bottom, #fff, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 90px;
  font-weight:bold;
  /* display: flex; */
  align-items: baseline;
  @media(max-width:1167px){
    font-size:75px;
  }
  @media(max-width:1167px){
    font-size:60px;
  }
  @media(max-width:552px){
    font-size: 40px;
  }
`;

const Image = styled.img`
width: 366px;
height: 287px;
position: absolute;
bottom: -30px;
right:70px;
@media(max-width:903px){
  width: 300px;
  height: 250px;
}
`;
const animate = keyframes`
0%{
  transform: translateY(0);
 }
 50%{
  transform: translateY(-10px);
 }
 100%{
  transform: translateY(0);
 }
`;
const TopImage = styled.img`
position: absolute;
top:30px;
z-index: 2;
right:150px;
width: 207px;
height: 197px;
animation: ${animate} 7s infinite linear;
@media(max-width:1167px){
  top:-30px;
  }
@media(max-width:903px){
  right:100px;
  top:-20px;
}
`;
const Para = styled.div`
color:#fff;
margin-top:30px;
`;
const Button = styled.button`
background-image: linear-gradient(to top, #00dea3, #037474);
color:#fff;
padding:12px 30px;
border:none;
border-radius:30px;
font-size:16px;
font-weight:500;
margin-top:30px;
`;
const PositionImage = styled.img`
position: absolute;
${(p) => p.top && `
 top:${p.top};
`};
${(p) => p.bottom && `
  bottom:${p.bottom};
`};
${(p) => p.left && `
  left:${p.left};
`};
${(p) => p.right && `
  right:${p.right};
  @media(max-width:1294px){
    right:0px !important;
  }
`};
${(p) => p.rotate && `
 transform: rotate(-90deg);
`};
width:90px;

`;
function EthereumHero(props) {
    return (
        <Root>
            <Container>
                <LeftSection>
                    <Heading>Ether<Icon src={EthereumImage} alt="Ethereum Development"/>um <br/>
                    Development</Heading>  
                    <Para>The forefront leader of the reliable Ethereum development services to make operations more viable.  </Para>
                    <Button>Start a Project <i class="fa-solid fa-arrows-repeat"></i></Button>
                </LeftSection>
                <RightSection>
                  <TopImage src={MoveMentImage} alt="Ethereum Development"/>
                  <Image src={EthereumBnnerImage} alt="Ethereum Development"/>
                  <PositionImage src={TrangleImage} alt="Ethereum Development" bottom="-40px" left="20px"/>
                  <PositionImage src={SecondTrangleImage} alt="Ethereum Development" bottom="-20px" right="-50px" rotate="rotate"/>
                </RightSection>
            </Container>
        </Root>
    );
}

export default EthereumHero;