import React from 'react';
import backgroundimg from "../../Component/Images/demo-img.jpg";
import mapbanner from "../../Component/Images/map-bg.jpg";
import styled from 'styled-components';
import Image1 from '../../Component/Images/demo-img.jpg';
import Image2 from '../../Component/Images/space-gen.jpg';
import Image3 from '../../Component/Images/art-gen.jpg';
import Image4 from '../../Component/Images/ecommerce-gen.jpg';
import Image5 from '../../Component/Images/event-gen.jpg';
import Image6 from '../../Component/Images/fashion-gen.jpg';
import GridCard from '../../Component/GridCard';
import { GridItem, GridWrapper, Heading, Wrapper } from '../../Component/styledComponents/GridColumnstyledcomponents';


const Root = styled.div`
  /* background-image: url(${mapbanner}); */
  /* background-color: #0a0a0a; */
  background-color: #fff;
  padding:80px 0;
  width: 100%;
  @media(max-width:767px){
    padding: 30px 0;
  }
`;
// const TextWrapper = styled.div`
//  color: #fff;
//  font-size: 60px;
//  font-weight: 900;
//  font-family: 'Roboto';
//  text-align:center;
//   @media(max-width:767px){
//       font-size: 30px;
//       padding-top: 2rem;
//       padding-bottom: 2rem;
//       text-align: center;
// }
// `;

const H3 = styled.h3`
 margin: 0;
 /* color: #fff; */
 color: #131313;
 font-size: 60px;
 font-weight: 900;
 font-family: 'Roboto';
 text-align:center;
  @media(max-width:767px){
      font-size: 30px;
      padding-top: 2rem;
      padding-bottom: 2rem;
      text-align: center;
}
`;

function MetaverseUseCases(props) {
    return (
    <Root>
      <Wrapper>
       <H3>Discover the diversity<br/>
        of Metaverse use cases</H3>
      <GridWrapper>
        <GridItem>
          <GridCard image={Image1} text="Art galleries"/>
          <GridCard image={Image2} text="Fashion"/>
          <GridCard image={Image3} text="Virtual land"/>
          <GridCard image={Image4} text="E-commerce"/>
          <GridCard image={Image5} text="Events"/>
          <GridCard image={Image6} text="Retail"/>
        </GridItem>
      </GridWrapper>
      </Wrapper>
    </Root>
    );
}

export default MetaverseUseCases;