import React from 'react';
import styled from 'styled-components';
import { Container } from '../../Component/styledComponents/styledComponents';
import CardBgImage from '../../Component/Images/bg.jpg';
import AndroidIcon from '../../Component/Images/android.png';
import AppleIcon from '../../Component/Images/apple.png';
import BannerImage from '../../Component/Images/mobiles.png';
import ArrowImage from '../../Component/Images/arrow-right.png';
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
background-color:#0d0d0d;
padding:70px 0;
`;
const Card = styled.div`
background-image:url(${CardBgImage});
border:1px solid;
border-color:rgba(48, 184, 148, 0.21);
border-radius:20px;
width:100%;
padding: 40px 0 40px 40px;
display: flex;
max-height:400px;
@media(max-width:424px){
    padding: 20px;
}
`;
const LeftSection = styled.div`
width:50%;
@media(max-width:767px){
    width:100%;
    padding-right:20px;
}
`;
const RightSection = styled.div`
width:50%;
text-align:right;
@media(max-width:767px){
    display: none;
}
`;
const Heading = styled.h2`
margin: 0;
font-weight: 400;
border-bottom:0.4px solid rgba(48, 184, 148, 0.21);
color:#fff;
font-size:40px;
padding-bottom:20px;
margin-bottom:20px;
@media(max-width:600px){
    font-size:30px;
}
@media(max-width:424px){
    font-size:24px;
}
`;
const Para = styled.div`
color: #9b9b9b;
`;
const IconSection = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top:20px;
`;
const IconWrapper = styled.div`
display: flex;
width:50%;
align-items: center;
justify-content:right;
color: #fff;
${(p) => p.border && `
border-right:1px solid rgba(48, 184, 148, 0.21); 
justify-content: left;
`};
`;
const Icon = styled.img`
@media(max-width:476px){
    width:40px;
}
@media(max-width:424px){
    width:30px;
}
@media(max-width:380px){
    width:24px;
}
`;
const Text = styled.div`
margin-left:10px;
font-size:16px;
@media(max-width:424px){
    font-size:14px;
}
`;
const ArrowIconWrapper  = styled.div`
width:40px;
height: 40px;
border-radius:50%;
border:1px solid #fff;
display: flex;
align-items: center;
justify-content: center;
margin-top:30px;
cursor: pointer;
`;
const Image = styled.img`
object-fit: contain;
width:fit-content;
height: 410px;
object-fit:fill;
margin-top:-50px;
max-width: 100%;
`;
const ArowIcon = styled.img`
width:20px;
`;
function AwardSection(props) {
    const navigate = useNavigate();
    return (
        <Root>
            <Container>
                <Card>
                    <LeftSection>
                        <Heading>
                            Award Wining & affordable
                            App Development
                        </Heading>
                        <Para>
                            Developing world-class fully functional mobile apps for Android and iOS platforms.
                        </Para>
                        <IconSection>
                            <IconWrapper border="border">
                                <Icon src={AndroidIcon} />
                                <Text>iOS App<br/>
                                    Development</Text>
                            </IconWrapper>
                            <IconWrapper>
                                <Icon src={AppleIcon} />
                                <Text>Android App<br/>
                                    Development</Text>
                            </IconWrapper>
                        </IconSection>
                        <ArrowIconWrapper onClick={() => navigate('/contact_us')}>
                             <ArowIcon src={ArrowImage}/>
                        </ArrowIconWrapper>
                    </LeftSection>
                    <RightSection>
                        <Image src={BannerImage} alt="App Development" />
                    </RightSection>
                </Card>
            </Container>
        </Root>
    );
}

export default AwardSection;