import { Container } from "@mui/system";
import React from "react";
import styled from "styled-components";
import image1 from "../../Component/Images/node.png";
import image2 from "../../Component/Images/react.png";
import image3 from "../../Component/Images/flutter.png";
import phpimg from "../../Component/Images/php.png";
import Html from "../../Component/Images/html.png";
import js from "../../Component/Images/js.png";
import sql from "../../Component/Images/mysql.png"

const TextWrapper = styled.text`
  font-size: 4.3rem;
  font-family: roboto;
  font-weight: 600;
  color: #00a97c;
  transform: translate(-50%, -50%);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;

`;
const TextWrappersmall = styled.text`
  font-size: 2.6rem;
    font-weight: 700;
    color: white;
    line-height: 3rem;
`;

const UL = styled.ul`
  list-style: none;
  display: flex;
  column-gap: 5rem;
  justify-content: center;
  padding-top: 3.7rem;
`;

const ContainerWrapper = styled.div`
  text-align: center;
  line-height: 35px;
  margin-top: 3rem;
`;
const Image = styled.img`

`;


const ImageWrapper = styled.div`
`;

const TextimageWrapper = styled.text`
  color: #727272;
`;

const Root = styled.div`
 background-color: black;
 height: 30rem; 
 padding-top: 3rem; 
 margin-top: -4px; 
`;

function TrustedTechnologies() {
  return (
    <Root >
      <ContainerWrapper>
        <TextWrapper> TRUSTED </TextWrapper>
        <br />
        <TextWrappersmall>Technologies</TextWrappersmall>
      </ContainerWrapper>

      <UL>
        <ImageWrapper>
          <Image src={image3}/> <br />
          <TextimageWrapper> flutter </TextimageWrapper>
        </ImageWrapper>

        <ImageWrapper>
          <Image src={image1}/> <br />
          <TextimageWrapper> Node.js</TextimageWrapper>
        </ImageWrapper>

        <ImageWrapper>
          <Image src={image2}/> <br />
          <TextimageWrapper> React.js</TextimageWrapper>
        </ImageWrapper>
        <ImageWrapper>
          <Image src={phpimg} /> <br />
          <TextimageWrapper> Php </TextimageWrapper>
        </ImageWrapper>

        <ImageWrapper>
          <Image src={Html}/> <br />
          <TextimageWrapper> Html</TextimageWrapper>
        </ImageWrapper>

        <ImageWrapper>
          <Image src={js}/> <br />
          <TextimageWrapper> Js</TextimageWrapper>
        </ImageWrapper>

        <ImageWrapper>
          <Image src={sql}/> <br />
          <TextimageWrapper> mysql</TextimageWrapper>
        </ImageWrapper>
      </UL>
    </Root>
  );
}

export default TrustedTechnologies;
