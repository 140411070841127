import React from "react";
import styled from "styled-components";
import MapImageBg from "../../Component/Images/map-bg.jpg";
import MapImage from "../../Component/Images/map.png";
import CountUp from "react-countup";
import { Container } from "../../Component/styledComponents/styledComponents";
import DubaiIcon from "../../Component/Images/dubai-icon.png";
import TajImage from "../../Component/Images/contactUsIcon1.png";
import contactUsIcon2 from "../../Component/Images/contactUsIcon2.png";

const Root = styled.div`
  background-image: url(${MapImageBg});
  width: 100%;
  background-position: 100% 100%;
  background-position: left;
  padding-top: 100px;
  @media (max-width: 662px) {
    padding-bottom: 30px;
  }
`;
const Main = styled.div`
  background-image: url(${MapImage});
  min-height: 40rem;
  background-repeat: no-repeat;
  background-position: left;
`;
const List = styled.div`
  display: flex;
`;
const ImageWrapper = styled.div`
  width: 100%;
`;

const TextWrapper = styled.text`
  color: #c5c7c5;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Ul = styled.ul`
  display: flex;
  column-gap: 8rem;
  justify-content: center;
  padding: 0rem 1rem 0rem 1rem;
  margin-top: -3rem;

  @media (max-width: 1024px) {
    column-gap: 5rem;
  }
  @media (max-width: 813px) {
    column-gap: 3rem;
  }
  @media (max-width: 520px) {
    column-gap: 2rem;
  }
  @media (max-width: 425px) {
    margin-left: -1rem;
  }
  @media (max-width: 390px) {
    margin-left: -1.2rem;
  }
  @media (max-width: 375px) {
    margin-left: -1.2rem;
  }
`;

const Li = styled.li`
  display: flex;
  font-size: 5rem;
  color: #00a97c;
  font-weight: 600;
  font-family: "Roboto";
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;

  @media (max-width: 1024px) {
    font-size: 4rem;
  }
  @media (max-width: 813px) {
    font-size: 3rem;
  }
  @media (max-width: 520px) {
    font-size: 2.4rem;
  }
  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;

const Text = styled.text`
  display: flex;

  @media (max-width: 520px) {
    font-size: 0.8rem;
  }
  @media (max-width: 425px) {
    font-size: 0.7rem;
  }
`;
const Bold = styled.span`
  font-weight: bold;
`;
const CardSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: right;
  margin-top: 120px;
  @media (max-width: 662px) {
    margin-top: 0px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 60%;
  @media (max-width: 947px) {
    width: 80%;
  }
  @media (max-width: 662px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Card = styled.div`
  border: solid 1px rgba(48, 184, 148, 0.53);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  color: #fff;
  padding: 10px 10px;
  width: calc(33.33% - 8px);
  background-color: #181818;
  transition: all 2s ease;

  :hover {
    background-color: #fff;
    border: 1px solid #fff;
    color: #000;
  }
  @media (max-width: 662px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const Image = styled.img`
  margin-bottom: 10px;
`;
const Div = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
`;
function ServicesAndProject() {
  return (
    <Root>
      <Main>
        <Container>
          <ImageWrapper>
            <Ul>
              <TextWrapper>
                <List>
                  <Li id="number1">
                    <CountUp end={7} duration={10} />
                  </Li>
                  <Li>+</Li>
                </List>
                <Text>Years of Experience</Text>
              </TextWrapper>
              <TextWrapper>
                <List>
                  <Li id="number2">
                    <CountUp end={150} duration={10} />
                  </Li>
                  <Li>+</Li>
                </List>
                <Text>Project delivered</Text>
              </TextWrapper>
              <TextWrapper>
                <List>
                  <Li id="number3">
                    {" "}
                    <CountUp end={75} duration={10} />
                  </Li>
                  <Li>+</Li>
                </List>
                <Text>Team Members</Text>
              </TextWrapper>
              <TextWrapper>
                <List>
                  <Li id="number4">
                    <CountUp end={25} duration={10} />
                  </Li>
                  <Li>+</Li>
                </List>
                <Text>Countries Served</Text>
              </TextWrapper>
            </Ul>
            {/* <CardSection>
              <CardWrapper>
                <Card>
                  <Image src={TajImage} alt="DubaiIcon" />
                  <Div>
                    <Bold>India</Bold>
                  </Div>
                  <Div>Metaspace Labs Information Technology L.L.C <br />
                      UAE- Dubai - Al meydan road KML building - office 121</Div>
        
                </Card>
                <Card>
                  <Image src={DubaiIcon} alt="DubaiIcon" />
                  <Div>
                    <Bold>Dubai</Bold>
                  </Div>
                  <Div>
                    1113, Concord Tower, Media City, Palm Jumeirah, Dubai
                  </Div>
                 
                </Card>
                <Card>
                  <Image src={contactUsIcon2} alt="contactUsIcon2" />
                  <Div>
                    <Bold>Australia</Bold>
                  </Div>
                  <Div>
                    81-83 Campbell Street Surry Hills NSW 2010 Australia
                  </Div>
        
                </Card>
              </CardWrapper>
            </CardSection> */}
          </ImageWrapper>
        </Container>
      </Main>
    </Root>
  );
}

export default ServicesAndProject;
