import styled from "styled-components";

export const Root = styled.div`
  padding:70px 0;
  width: 100%;
  @media(max-width:767px){
    padding: 30px 0;
  }
`;
export const TextWrapper = styled.div`
width:100%;
 color: #000;
 font-size: 60px;
 font-weight: 900;
 font-family: 'Roboto';
 text-align:center;
  @media(max-width:767px){
      font-size: 30px;
      text-align: center;
}
 @media(max-width:500px){
      font-size: 25px;
      text-align: center;
}
`;
export const Heading = styled.div`

`;
export const GridWrapper = styled.div`
width:100%;
  margin: 4rem 0rem 0rem 0rem;
  /* @media(max-width:767px){
    margin:2rem 2rem;
  } */
`;
export const GridItem = styled.div`
width:100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  column-gap: 0px;
  row-gap: 0px;
  
  margin: -1rem 0rem 0rem 0rem;
  @media(max-width:767px){
    grid-template-columns: 1fr 1fr;
  }
  @media(max-width:400px){
    grid-template-columns: 1fr;
  }
`;

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
width:1160px;
margin:0 auto;
@media(max-width:1200px){
  width:100%;
  padding:0 20px;
}
`;