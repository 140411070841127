import React from "react";
import styled from "styled-components";
import { Container } from "../../Component/styledComponents/styledComponents";
import GreenArrowIcon from "../../Component/Images/green-arrow.png";
import BannerImage from "../../Component/Images/banner1.png";
import HeadingImage from "../../Component/Images/cardImage.png";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  background-color: #f1f7f5;
  padding-top: 190px;
  max-height: 570px;
  @media (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 30px;
    height: 100%;
  }
`;
const Image = styled.img`
  max-width: 100%;
`;
const ImageWrapper = styled.div`
  top: -140px;
  position: relative;
`;
const Div = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const ImageSection = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    display: none;
  }
`;

const HeadingWrapper = styled.div`
  line-height: 60px;
  position: relative;
`;
const Heading = styled.div`
  font-size: 30px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  /* margin-bottom:5px; */
`;
const BoldHeading = styled.div`
  font-weight: 900;
  font-size: 5rem;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 4rem;
    margin-bottom: 15px;
  }
  @media (max-width: 422px) {
    font-size: 3rem;
    margin-bottom: 10px;
  }
`;
const LargeText = styled.div`
  font-size: 50px;
  font-weight: normal;
  margin-bottom: 20px;
`;
const ParagraphWrapper = styled.div``;
const Para = styled.div`
  color: rgba(0, 0, 0, 0.6);
`;
const BoldPara = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const ArrowSection = styled.div`
  margin-top: 30px;
`;
const Icon = styled.img``;

const H1 = styled.h1`
  margin: 0;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  display: inline;
`;

const TringleImage = styled.img`
  position: absolute;
  top: 232px;
  left: 36%;
  z-index: 1;
  width: 132px;
  height: 135px;
  @media (max-width: 767px) {
    display: none;
  }
`;
const Span = styled.span``;
function BlockchainHeroSection() {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Div>
          <HeadingWrapper>
            <BoldHeading>Blockchain</BoldHeading>
            <BoldHeading>Platform</BoldHeading>
          </HeadingWrapper>
          <TringleImage src={HeadingImage} alt="Blockchain Development"/>
          <ParagraphWrapper>
            {/* <BoldPara>A blockchain development company</BoldPara> */}
            <Para>
              With our wide spectrum of capabilities and end-to-end{" "}
              <H1>blockchain development services.</H1>
            </Para>
          </ParagraphWrapper>
          <ArrowSection>
            <Icon
              src={GreenArrowIcon}
              onClick={() => {
                navigate("/contact_us");
              }}
            />
          </ArrowSection>
        </Div>
        <ImageSection>
          <ImageWrapper>
            <Image src={BannerImage} alt="Blockchain Development" />
          </ImageWrapper>
        </ImageSection>
      </Container>
    </Root>
  );
}

export default BlockchainHeroSection;
