import { Typography } from "@mui/material";
import { Box, Container, height } from "@mui/system";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import styled from "styled-components";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MoAkram from "../../Component/Images/MoAkram.png";
import Mehreen from "../../Component/Images/Mehreen.png";
import Ajayyadav from "../../Component/Images/Ajay.png";
import OMRAN from "../../Component/Images/omran.jpg";

const QuoteImage = styled.img`
  width: 40px !important;
  position: absolute;
  left: -20px;
  top: 20px;
`;
const Card = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 30px 20px;
  min-height: 450px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
`;
const Root = styled.div`
  background-color: #f8f8f8;
  padding: 30px 0 80px 0;
  @media (max-width: 767px) {
    padding: 10px 0 30px 0;
  }
`;

const Div = styled.div`
  color: rgb(128, 128, 128);
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  text-align: left;
  margin-top: 10px;
`;
const Carousel = styled(OwlCarousel)`
  width: 100%;
  display: flex;
  /* position:relative; */
  /* padding: 0 20px; */

  & .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: flex;
  }
  & .owl-prev {
    position: absolute;
    top: 40%;
    left: -44px !important;
    font-size: 20px !important;
    background-color: #138e85 !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 30px !important;

    z-index: 10px;
    color: white !important;
    @media (max-width: 767px) {
      left: 0 !important;
    }
  }
  & .owl-next {
    position: absolute;
    top: 40%;
    right: -44px !important;
    color: white !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 30px !important;
    background-color: #138e85 !important;
    font-size: 20px !important;
    z-index: 10px;
    @media (max-width: 767px) {
      right: 0 !important;
    }
  }
  @media (max-width: 767px) {
    padding: 0px 0px;
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Info = styled.div`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-align: left;
  margin-left: 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const Avatar = styled.img`
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
`;
const TextWrapper = styled.div`
  width: 289px;
  height: 55px;
  background-color: white;
  position: absolute;
  color: white;
  bottom: 0%;
  left: 10%;
  border-top: 3px solid #139e85;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 15px 0;
  z-index: 9999999;
`;
const BoldText = styled.text`
  color: #000;
  font-weight: 600;
  font-size: 20px;
  padding-top: 15px;
`;
const LightText = styled.text`
  color: #000;
  padding: 5px 0;
  font-weight: 400;
  font-size: 18px;
`;
const Image = styled.img`
  min-height: 410px;
  max-height: 410px;
`;

const CardWrapper = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  background-color: #f8f8f8;
  border-radius: 50%;
`;
function Clients() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    navText: [
      `<div class="arrow">
       <i class="fa-solid fa-chevron-left"></i>
       </div>`,
      `
    <div class="arrow">
    <i class="fa-solid fa-chevron-right"></i>
    </div>`,
    ],
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      500: {
        items: 2,
      },
      900: {
        items: 3,
      },
    },
  };
  return (
    <Root>
      <Container>
        <Box sx={{ height: "100%", textAlign: "center" }}>
          <Box sx={{ margin: "2rem 0rem 2rem 0rem" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                fontFamily: "Roboto , sans-serif",
              }}
            >
              {/* Customer Stories */}
              Company Leadership
            </Typography>
          </Box>

          <Carousel {...options}>
            <CardWrapper class="item">
              <Image src={MoAkram} />
              <TextWrapper>
                <BoldText>MO AKRAM </BoldText>
                <LightText> FOUNDER & CEO </LightText>
              </TextWrapper>
            </CardWrapper>

            <CardWrapper class="item">
              <Image src={OMRAN} />
              <TextWrapper>
                <BoldText>OMRAN ALRAEESI </BoldText>
                <LightText> ADVISOR </LightText>
              </TextWrapper>
            </CardWrapper>

            <CardWrapper class="item">
              <Image src={Mehreen} />
              <TextWrapper>
                <BoldText>MEHREEN </BoldText>
              </TextWrapper>
            </CardWrapper>

            <CardWrapper class="item">
              <Image src={MoAkram} />
              <TextWrapper>
                <BoldText>MO AKRAM </BoldText>
                <LightText> FOUNDER & CEO </LightText>
              </TextWrapper>
            </CardWrapper>

            <CardWrapper class="item">
              <Image src={OMRAN} />
              <TextWrapper>
                <BoldText>OMRAN ALRAEESI </BoldText>
                <LightText> ADVISOR </LightText>
              </TextWrapper>
            </CardWrapper>

            <CardWrapper class="item">
              <Image src={Mehreen} />
              <TextWrapper>
                <BoldText>MEHREEN </BoldText>
              </TextWrapper>
            </CardWrapper>
          </Carousel>
        </Box>
      </Container>
    </Root>
  );
}
export default Clients;
