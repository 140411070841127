import React from 'react';
import styled from 'styled-components';
import { Button, Container, Spacing } from '../../Component/styledComponents/styledComponents';
import LeftCardImage from '../../Component/Images/benefit1.png';
import RightCardImage from '../../Component/Images/benefit2.png';


const Root = styled.div`
background-color: #fff;
color:#fff;
padding: 80px 20px;
`;
const Heading = styled.div`
font-size: 34px;
font-weight:bolder;
@media(max-width:767px){
    font-size:24px;
}
`;
const Div = styled.div`
width:100%;
`;
const CardSection = styled.div`
display: flex;
width:100%;
margin-top:20px;
@media(max-width:767px){
    flex-direction:column;
}
`;

const CardWrapper = styled.div`
display: flex;
width:100%;
min-width:100%;
justify-content: space-between;
@media(max-width:767px){
    flex-direction: column;
}

`;
const Card = styled.div`
background-color: #171717;
border-radius:10px;
height: 100%;
padding:20px;
${(p) => p.width && `width:calc(${p.width} - 20px);height:100%;`};
${(p) => p.smwidth && `width:calc(${p.smwidth} - 10px);`};
@media(max-width:767px){
    ${(p) => p.width && `width:100%;min-width:100%;`};
    ${(p) => p.bottom && `margin-bottom:20px;`};
    ${(p) => p.smwidth && `width:100%;`}
}
`;
const LeftCardWrapper = styled.div`
width:40%;
position: relative;
@media(max-width:767px){
    width:100%;
}
`;
const RightCardWrapper = styled.div`
width:60%;
display: flex;
flex-direction:column;
justify-content: space-between;
@media(max-width:767px){
    width:100%;
}
`;
const CardfullWidth = styled.div`
width:100%;
background-color: #171717;
border-radius:10px;
padding:80px 40px;
margin-bottom:20px;
position: relative;
@media(max-width:767px){
   margin-top:20px;
   padding:40px 20px;
}
`;

const HeadingText = styled.div`
color:#727272;
`;

const ImageWrapper = styled.div`
width:100%;
height:200px;
margin-top:20px;
@media(max-width:767px){
    display: none;
}
`;
const Image = styled.img`
width:90%;
position: absolute;
left:0px;
@media(max-width:767px){
    top:10px;
    width:80%;
}
`;

const Para = styled.div`
font-size: 14px;
margin-top:30px;
margin-bottom:30px;
`;

const InfoText = styled.div`
color: #fff;
margin-top:20px;
width:60%;
@media(max-width:767px){
    width:100%;
}
`;

const ButtonContainer = styled.div`
display: flex;
`;

const LargeHeading = styled.h4`
margin: 0;
font-size: 50px;
font-weight:bolder;
color:#000;
@media(max-width:767px){
    font-size:30px;
}
`;

const BgImage = styled.img`
   position: absolute;
   width:80%;
   right: 0px;
   bottom:0px;
   width:280px;
   @media(max-width:767px){
    display: none;
}
`;

const Text = styled.div`
font-size:14px;
letter-spacing:0.3px;
`;
function MetaverseFeatureSection(props) {
    return (
        <Root>
            <Container>
                <Div>
                    <LargeHeading>Become the pioneers <br/>
                  of the Metaverse Boom!</LargeHeading>
                    <CardSection>
                        <LeftCardWrapper>
                            <Card width="100%">
                                <HeadingText>3D virtual communication </HeadingText>
                                <Spacing top="20px"></Spacing>
                                <ImageWrapper>
                                    <Image src={LeftCardImage} />
                                </ImageWrapper>
                                <Spacing top="20px"></Spacing>
                                <Heading>Innovative Communication</Heading>
                                <Para>The users in Metaverse undergo an innovative virtual communication experience by providing a 3D virtual communication experience. 
                         </Para>
                                
                            </Card>
                        </LeftCardWrapper>
                        <RightCardWrapper>
                            <CardWrapper>
                                <CardfullWidth>
                                    <BgImage src={RightCardImage} />
                                    <Heading>World without</Heading>
                                    <Heading>limitations</Heading>
                                    {/* <Spacing top="20px"></Spacing> */}
                                    <InfoText>
                                    Metaverse is a gigantic 3D virtual world growing enormously without any borders or limitations into distinctive industries. 
                                    </InfoText>
                                </CardfullWidth>
                            </CardWrapper>
                            <CardWrapper>
                                <Card smwidth="50%" bottom="bottom">
                                    <HeadingText>Real-time Experience</HeadingText>
                                    <Spacing top="30px"></Spacing>
                                    <Heading>Real-time </Heading>
                                    <Heading>Experience</Heading>
                                    <Spacing top="10px"></Spacing>
                                    <Text>
                                    The amalgamation of real and the virtual world together brings out the real-time experience where users visualize 3D products and environments irrespective of their locations.    
                                    </Text>
                                </Card>
                                <Card smwidth="50%">
                                    {/* <HeadingText>Decentralized Exchange</HeadingText> */}
                                    <Spacing top="70px"></Spacing>
                                    <Heading>High ROI</Heading>
                                    <Spacing top="10px"></Spacing>
                                     <Text>
                                      Metaverse platforms bring High Returns on Investment as all the industries will slowly and gradually adapt to Metaverse for their growth in the future.
                                    </Text>
                                </Card>
                            </CardWrapper>
                        </RightCardWrapper>
                    </CardSection>
                </Div>
            </Container>
        </Root>
    );
}

export default MetaverseFeatureSection;