import React from 'react';
import styled from 'styled-components';
import { Button, Container, Spacing } from '../../Component/styledComponents/styledComponents';
import LeftCardImage from '../../Component/Images/cardImage.png';
import RightCardImage from '../../Component/Images/cardbgImage.png';

const Root = styled.div`
background-color: #fff;
color:#fff;
padding: 80px 20px;
@media(max-width:767px){
    padding: 40px 0px;
}
`;
const Heading = styled.div`
font-size: 34px;
font-weight:bolder;
@media(max-width:767px){
    font-size:24px;
}
`;
const Div = styled.div`
width:100%;
`;
const CardSection = styled.div`
display: flex;
width:100%;
margin-top:20px;
@media(max-width:767px){
    flex-direction:column;
}
`;

const CardWrapper = styled.div`
display: flex;
width:100%;
min-width:100%;
justify-content: space-between;
@media(max-width:500px){
    flex-direction: column;
}
`;
const Card = styled.div`
background-color: #171717;
border-radius:10px;
height: 100%;
padding:20px;
${(p) => p.width && `width:calc(${p.width} - 20px);height:100%;`};
${(p) => p.smwidth && `width:calc(${p.smwidth} - 10px);`};
@media(max-width:767px){
    ${(p) => p.width && `width:100%;min-width:100%;`};
   
}
@media(max-width:500px){
    ${(p) => p.bottom && `margin-bottom:20px;`};
     ${(p) => p.smwidth && `width:100%;`}
}
`;
const LeftCardWrapper = styled.div`
width:40%;
position: relative;
@media(max-width:767px){
    width:100%;
}
`;
const RightCardWrapper = styled.div`
width:60%;
display: flex;
flex-direction:column;
justify-content: space-between;
@media(max-width:767px){
    width:100%;
}
`;
const CardfullWidth = styled.div`
width:100%;
background-color: #171717;
border-radius:10px;
padding:80px 40px;
margin-bottom:20px;
position: relative;
@media(max-width:767px){
   margin-top:20px;
   padding:40px 20px;
}
`;

const HeadingText = styled.div`
color:#727272;
`;

const ImageWrapper = styled.div`
width:100%;
height:200px;
margin-top:20px;
@media(max-width:767px){
    display: 150px;
    margin-top:0px;
}
`;
const Image = styled.img`
width:200px;
position: absolute;
left:-20px;
@media(max-width:767px){
    height: 150px;
}
`;

const Para = styled.div`
font-size: 14px;
margin-top:30px;
margin-bottom:30px;
`;

const InfoText = styled.div`
color: #fff;
margin-top:20px;
width:60%;
@media(max-width:767px){
    width:100%;
}
`;

const ButtonContainer = styled.div`
display: flex;
`;

const LargeHeading = styled.h3`
margin: 0;
font-size: 50px;
font-weight:bolder;
color:#000;
@media(max-width:767px){
    font-size:30px;
}
`;

const BgImage = styled.img`
   position: absolute;
   width:80%;
   right: -30px;
   bottom:0px;
   width:280px;
   @media(max-width:767px){
    display: none;
}
`;

const Text = styled.div`
font-size:14px;
letter-spacing:0.3px;
`;
function FeatureSection(props) {
    return (
        <Root>
            <Container>
                <Div>
                    <LargeHeading>Be part of the <br/>
                    Future Open Economy</LargeHeading>
                    <CardSection>
                        <LeftCardWrapper>
                            <Card width="100%">
                                <HeadingText>Decentralized Exchange</HeadingText>
                                <Spacing top="50px"></Spacing>
                                <ImageWrapper>
                                    <Image src={LeftCardImage} alt="Metaverse" />
                                </ImageWrapper>
                                <Spacing top="50px"></Spacing>
                                <Heading>True experts in Metaverse</Heading>
                                <Para>Implementing Blockchain technology for leading Blockchain companies.</Para>
                                <Spacing top="50px"></Spacing>
                                {/* <HeadingText>Contact Us</HeadingText> */}
                            </Card>
                        </LeftCardWrapper>
                        <RightCardWrapper>
                            <CardWrapper>
                                <CardfullWidth>
                                    <BgImage src={RightCardImage} alt="Metaverse in Education" />
                                    <Heading>Metaverse in</Heading>
                                    <Heading>Education</Heading>
                                    <InfoText>
                                       WE'RE CONSTANTLY IMPROVING OUR SKILLS AND UNDERSTANDING TO DELIVER CUTTING-EDGE ADVICE AND SERVICES FOR YOU.
                                    </InfoText>
                                </CardfullWidth>
                            </CardWrapper>
                            <CardWrapper>
                                <Card smwidth="50%" bottom="bottom">
                                    <HeadingText>Decentralized Exchange</HeadingText>
                                    <Spacing top="30px"></Spacing>
                                    <Heading>Supply</Heading>
                                    <Heading>Chain</Heading>
                                    <Heading>Management</Heading>
                                    <Spacing top="40px"></Spacing>
                                    {/* <HeadingText>Contact Us</HeadingText> */}
                                </Card>
                                <Card smwidth="50%">
                                    {/* <HeadingText>Decentralized Exchange</HeadingText> */}
                                    <Spacing top="70px"></Spacing>
                                    <Heading>Smart</Heading>
                                    <Heading>Contract</Heading>
                                    <Spacing top="50px"></Spacing>
                                    {/* <HeadingText>Contact Us</HeadingText> */}
                                </Card>
                            </CardWrapper>
                        </RightCardWrapper>
                    </CardSection>
                </Div>
            </Container>
        </Root>
    );
}

export default FeatureSection;