import { Button} from "@mui/material";
import React, { useState } from "react";
import circle from './Images/circle.png';
import hiboy from "./Images/hi-boy.png";
import hiback from "./Images/hi-back.png";
import styled, { keyframes } from "styled-components";
import { Container } from "./styledComponents/styledComponents";
import axios from "axios";
import SucessPopup from "./SucessPopup";
import EmailAlreadyExist from "./EmailAlreadyExist";

const Root = styled.div`
background-color:#0c0c0c;
padding-bottom:100px;
padding-top:100px;
@media(max-width:767px){
  padding:30px 0;
}
`;
const Input = styled.input`
border-radius:31px;
height:3rem;
width:100%;
border:1px solid #808080;
background:none;
color:#fff;
padding-left:1.5rem;
outline:none;
${(p) => p.right && `
 margin-right:20px;
`};
 @media(max-width:767px){
  margin-bottom: 20px;
  ${(p) => p.right && `
 margin-right:0px;
`};
}   
`;
const ImageWrapper = styled.div`
      margin-left: 36rem;
      margin-top: -9rem;
`;
const animate = keyframes`
0%{
  transform: translateX(0);
 }
 25%{
  transform: translateX(30px);
 }
 50%{
  transform: translateX(-30px);
 }
 100%{
  transform: translateX(0);
 }
`;

const Move = styled.img`
  z-index: 2;
  /* margin-left:-45rem; */
  margin-Top:10rem;
  position:absolute;
  animation-timing-function: ease-in;
  animation: ${animate} 5s infinite linear;
  right:3rem;
`;
const Form = styled.form`
width:100%;
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
`;
const UpperImage = styled.img`
          z-index: 3;
          /* margin-left: -31rem; */
          margin-top: 4rem;
          position: absolute;
          height:422px;
          right:0;
          filter: brightness(0.3);
`;
const CircleImage = styled.img`
          position:absolute;
          z-index: 1;
          height: 21rem;
          right:5rem;
          top:-1.9rem;
`;
const FormWrapper = styled.div`
width:100%;
display:flex;
justify-content: space-between;
align-items: center;
@media(min-width: 767px){
  margin-bottom:20px;
}
@media(max-width:767px){
  flex-direction:column;
}
`;
const Div = styled.div`
width:60%;
@media(max-width:767px){
  width:100%;
}
`;
const ImageSection = styled.div`
width:40%;
position:relative;
@media(max-width:767px){
  display: none;
}
`;

const ButtonWrapper = styled.div`

`;
const Heading = styled.h6`
margin: 0;
font-size:40px;
font-weight:900;
color:#fff;
margin-bottom:30px;
@media(max-width:767px){
  font-size:30px;
}
`;
// const ErrorMessage = styled.div`
// color:red;
// padding:5px 0 10px 0;
// `;
const Select = styled.select`
  width: 100%;
  background: #0c0c0c;
  border-radius: 31px;
  margin-right: 13px;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #808080;
  padding: 14px 10px;
  option {
    color: gray;
    border-radius: 10px !important;
    /* background: white; */
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
    border-radius: 10px;
 
  }
  @media(max-width: 767px){
      margin-bottom: 20px;
      margin-right: 0;
    }
`;
function ConnectMessage() {
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [companyname, setCompanyName] = useState('');
  const [profession, setProfession] = useState('');
  const [select, setSelect] = useState("");
  const [message,setMessage] = useState('');
  // const [error,setError] = useState(false);
  const [showPopup,setShowPopup] = useState(false);
  const [responseMessage,setResponseMessage] = useState('');
  const [sucessResponse,setSucessResponse] = useState();
  const [emailError, setEmailError] = useState();
  const [popup, setPopup] = useState(false);
  const handleSubmit=()=>{
    
    var myHeaders = new Headers();
  myHeaders.append("Cookie", "941ef25d4b=ee3398115f01e3105b66cec74cc99ba9; JSESSIONID=50D344F9BEC2B6E813C54A63175BFC74; _zcsr_tmp=3b499b7e-4889-49ab-ab57-62619474b0b5; crmcsr=3b499b7e-4889-49ab-ab57-62619474b0b5");

  var formdata = new FormData();
  formdata.append("xnQsjsdp", "8f58ed1bfd8097d5028e479a123d3f35728d9dacf02eef345d66dc9c83828b94");
  formdata.append("zc_gad", "");
  formdata.append("xmIwtLD", "8e1c6d9a8eb7ec84855cfca669c8e1e78029a89cc5f1d2c91da5e9dfd47ee358");
  formdata.append("actionType", "TGVhZHM=");
  formdata.append("returnURL", "https&#x3a;&#x2f;&#x2f;bastionex.net&#x2f;contact_us");
  formdata.append("Last Name", name);
  formdata.append("Email", email);
  formdata.append("Phone", phone);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };

  fetch("https://crm.zoho.in/crm/WebToLeadForm", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
    }
  const SubmitHandler = (e) =>{
    e.preventDefault();
    console.log(name, email, phone, message, companyname, select, profession)
    if(name === '' || email === '' || message === '' || phone === '' || companyname === '' || profession === '' || select === ''){
      //  setError(true);
       return;
    }
    axios({
      method: 'post',
      url: 'https://api.bastionex.net/postuserdata',
      data: {
        name: name,
        email: email,
        number: phone,
        message: message,
        companyName: companyname,
        designation: profession,
        companySize: select,
      }
    })
    .then(function(response){
      console.log(response.data);
      setShowPopup(true);
      setSucessResponse(response.data.success);
      setResponseMessage(response.data.message);
      // handleSubmit();
    })
    .catch((err)=>{
      console.log(err.response.data)
      setResponseMessage(err.response.data.message);
      setEmailError(err.response.data.message)
      if(err.response.data.error === "Email Already exist"){
        setPopup(true)
      }else{
        setShowPopup(true);
        
      }
     
    });
    // setSucessResponse();
    // setError(false);
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
    setCompanyName('');
    setProfession('');
    setSelect('');
  }
  return (
    <Root>
       {showPopup && <SucessPopup 
       showPopup={showPopup} 
       setShowPopup={setShowPopup} 
       responseMessage={responseMessage}
       sucessResponse={sucessResponse}
      />}
        {popup && (
        <EmailAlreadyExist
          responseMessage={responseMessage}
          sucessResponse={sucessResponse}
          showPopup={popup}
          // emailError={emailError}
          setShowPopup={setPopup}
        />
      )}
      <Container>
            <Div>
              <Heading>
              Contact Us for <span style={{color:"rgb(193 8 68)"}}> Consultation </span> Call.
              </Heading>
                <Form onSubmit={SubmitHandler}>
                  <FormWrapper>
                    <Input
                      type="text"
                      placeholder="Full Name"
                      right="20px"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <Input
                      type="email"
                      placeholder="Email"
                      right="20px"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <Input
                      type="number"
                      placeholder="Contact Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      min="10"
                      required
                    />
                  </FormWrapper>
                  <FormWrapper>
                    <Input
                      type="text"
                      placeholder="Company Name"
                      right="20px"
                      value={companyname}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                    />
                  <Select onChange={(e)=>{setSelect(e.target.value)}}
                   value= {select} required>
                    <option value="" hidden>
                     Company Size
                    </option>
                    <option value="10">0-10 Employees</option>
                    <option value="25">11-25 Employees</option>
                    <option value="50">26-50 Employees</option>
                    <option value="100">51-100 Employees</option>
                    <option value="150">100+ Employees</option>
                  </Select>
                    <Input
                      type="text"
                      placeholder="Designation"
                      value={profession}
                      onChange={(e) => setProfession(e.target.value)}
                      required
                    />
                  </FormWrapper>
                  <FormWrapper>
                    <Input
                      type="text"
                      placeholder="What are you looking for ?"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </FormWrapper>
                  {/* {error && <ErrorMessage>All Field are required.</ErrorMessage>} */}
                  <ButtonWrapper>
                  <Button style={{
                    backgroundColor: "#fff",
                    color: "#000", fontSize: "1.3rem",
                    borderRadius: "30px", width: "9rem",
                    height: "3rem",
                    textTransform: "capitalize",
                  }} type="submit">
                    Submit
                  </Button>
                  </ButtonWrapper>
                </Form>
            </Div>
            <ImageSection >
              <ImageWrapper >
                <CircleImage src={circle} alt="Contact Bastionex Infotech" />
                <Move src={hiboy} alt="Contact Bastionex Infotech" />
                <UpperImage src={hiback} alt="Contact Bastionex Infotech" />
              </ImageWrapper>
            </ImageSection>
      </Container>
    </Root>
  );
}
export default ConnectMessage;
